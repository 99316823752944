<template>
  <v-card class = "ms-title">
    <v-card-title class = "text-center">
      <div class = "py-2">
        <h3 class = "text-center"> {{labels.REGMSD}}</h3>
        <div class = "closeicon-right">
          <v-icon  @click.prevent = "closeMsDetailDialog" class = "text-right">
            mdi-close-circle-outline
          </v-icon>
        </div>
      </div>
    </v-card-title>
    <v-divider class = "my-4"></v-divider>
    <v-card-text>
      <div class = "px-10 msForm">
        <!-- -----------Microservice  Details------------>
        <v-col cols = "12" class = "px-0 py-6">
          <h2>{{ labels.MSD }}</h2>
        </v-col>
        <v-row
          class = "grey lighten-5 rounded-3"
            style = "border: 1px solid var(--v-secondary-lighten2) !important;
              border-radius : 5px;
          "
        >
          <v-col cols = "6">
            <label class = "my-4">{{labels.MSNAME}}</label>
            <div>{{ regmicroalldata.name }}</div>
          </v-col>
          <v-col cols = "6">
            <label class = "my-4">{{ labels.DEPTYPE }}</label>
            <div>{{ regmicroalldata.deployment_type }}</div>
          </v-col>
          <v-col cols = "6">
            <label class = "my-4">{{ labels.MSCODE }}</label>
            <div>{{ regmicroalldata.function_code }}</div>
          </v-col>
          <v-col cols = "12">
            <label class = "my-4">{{ labels.DESC }}</label>
            <div>{{ regmicroalldata.description }}</div>
          </v-col>
    

          <!------------------Resource Details ------------------->
          <v-col cols = "12">
            <h4 class = "my-4">{{ labels.RESD }}</h4>
          </v-col>
          <v-col cols = "4">
            <label class = "my-4">{{labels.CPU}}</label>
            <div>{{ regmicroalldata.resource_cpu }}</div>
          </v-col>
          <v-col cols = "4">
            <label class = "my-4">{{ labels.MEM }}</label>
            <div>{{ regmicroalldata.resource_memory }}</div>
          </v-col>
          <v-col cols = "4">
            <label class = "my-4">{{ labels.GPU }}</label>
            <div>{{ regmicroalldata.resource_gpu }}</div>
          </v-col>
          <v-col cols = "4">
            <label class = "my-4">Port</label>
            <div>{{ regmicroalldata.port }}</div>
          </v-col>
          <v-col cols = "8">
            <label class = "my-4" >{{ labels.EV }}</label>
            <div v-for = "item in regmicroalldata.env_variables" :key = "item.key">
              <span v-if="regmicroalldata.env_variables.length > 0 && !item.secret" >{{ item.key }}:{{ item.value }}</span>
              <span v-else-if="regmicroalldata.env_variables.length">{{ item.key }}:{{ item.maskvalue }}</span>
            </div>
            <div v-if ="regmicroalldata.env_variables.length == 0">-</div>
          </v-col>
        </v-row>

        <!-- API Details -->
        <v-col cols = "12" class = "px-0 py-6">
          <h2>{{labels.APID}}</h2>
        </v-col>
        <v-row
          class = "grey lighten-5 rounded-3"
          style = "border: 1px solid var(--v-secondary-lighten2) !important; border-radius: 5px;"
        >
          <v-col cols = "12" v-if ="regmicroalldata.microservice_type=='non data microservice'">
              <label class = "my-4">{{ labels.EP }}</label>
              <div v-for = "item in regmicroalldata.api_details[regmicroalldata.deploy_type]" :key="item.apiroute">{{ item.endpoint }}</div>
          </v-col>
          <v-col cols = "12" v-if ="regmicroalldata.microservice_type=='data microservice'">
            <label class = "my-4">{{ labels.EP }}</label>
            <div>{{ regmicroalldata.endpoint }}</div>
          </v-col>
        </v-row>
        <v-col cols = "12" class = "px-0 py-6">
          <h2>Documentation Details</h2>
        </v-col>
        <v-row
          class = "grey lighten-5 rounded-3"
          style = "border: 1px solid var(--v-secondary-lighten2) !important; border-radius: 5px;"
        >
        <v-col cols = "12">
            <label class = "my-4">Docs YAML File</label>
            <div>{{ regmicroalldata.docs_yaml }}</div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-if = "tabstatus == 'pending' || tabstatus == 'rejected'">
      <div class = "py-4">
      <v-btn class = "nocap" @click.prevent = "closeMsDetailDialog" text>
        <span>{{ labels.CANCEL }}</span>
      </v-btn>
      <v-btn class = "nocap primary" text @click = "editDialog()">
        <span>
          <v-icon>mdi-square-edit-outline</v-icon>
          {{ labels.EDIT }}
        </span>
      </v-btn>
      </div>
    </v-card-actions>  
    <v-card-actions v-if = "tabstatus == 'approved'">
      <div class = "py-4">
      <v-btn class = "nocap" @click.prevent = "closeMsDetailDialog" text>
        <span>{{ labels.CANCEL }}</span>
      </v-btn>
      <v-btn class = "nocap primary" text @click = "editDialog()">
        <span>
          <v-icon>mdi-square-edit-outline</v-icon>
          Upgrade
        </span>
      </v-btn>
      </div>
    </v-card-actions>    
  </v-card>
</template>

<script>
export default {
  name : "registerMsDetailPage",
  props : ['regmicroalldata', 'regmsdetaildialog', 'regmseditdialog', 'labels', 'tabstatus'],
  data: () => ({
  }),
  methods: {
    closeMsDetailDialog() {
      this.$emit('closeMsDetailDialog', false);
    },
    editDialog(){
      this.regmsdetaildialog = false;
      this.regmseditdialog = true;
      this.$emit('editDialog', false);
    },
  },
};
</script>

<style>

</style>
