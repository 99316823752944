<template>
  <div class="msForm">
    <v-row>
      <v-col cols="12">
        <label>Upload OpenAPI YAML</label>
        <v-radio-group v-model="regmicroalldata.docs" row class="mt-0">
          <v-radio label="Yes" value="yes"></v-radio>
          <v-radio label="No" value="no"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="regmicroalldata.docs == 'yes'">
        <label>{{ labels.DOCS }}</label>
        <div>
          <div class="d-flex flex-column" style="width: 135px">
            <div class="position-relative pt-3" v-if="!regmicroalldata.docs_yaml_file && !regmicroalldata.docs_yaml_url"
              @dragover.prevent="dragOver" @dragleave.prevent="dragLeave" @drop.prevent="drop($event, 'icon')">
              <div class="text-center d-flex-column justify-center fileBox">
                <v-icon class="secondary--text"> mdi-cloud-upload</v-icon>
                <div>Upload</div>
              </div>
              <input type="file" class="clicktofileUpload overflow-hidden w-px h-px opacity-0"
                @change="yamlFileChange($event.target.files, 'icon')" accept=".yaml" />
            </div>
          </div>
          <div v-if="regmicroalldata.docs_yaml_url" class="mt-0" style="position: relative">
            <button class="pos close-iconPos" type="button" @click="clearIcon" title="Remove file">
              <v-icon color="error">mdi-close-circle-outline </v-icon>
            </button>
          </div>
          <p class="shrink rounded fileimgWidth logoBoxSize mt-1" style="padding: 0px"
            v-if="regmicroalldata.docs_yaml_url" alt="YAML file" contain aspect-ratio="2">{{ regmicroalldata.docs_yaml_url
            }}
            <button class="pos close-iconPos" type="button" @click="clearIcon" title="Remove file">
              <v-icon color="error">mdi-close-circle-outline </v-icon>
            </button>
          </p>
        </div>
        <span v-if="regmicroalldata.docs_yaml_url == '' && !validForm" style="color:red;">openapi yaml required</span>
      </v-col>
    </v-row>
    <v-row v-if=" regmicroalldata.docs == 'no'">
      <label style="padding-left: 10px;">Api Routes</label>
      <v-col cols="12" v-if="regmicroalldata.microservice_type=='non data microservice'">
        <v-expansion-panels >
          <v-expansion-panel class="panelBorder pa-1" v-for="(items,index) in regmicroalldata.api_route[regmicroalldata.deploy_type]" :key="index">
            <v-expansion-panel-header>
              <h3 class="secondary--text text--darken-4">{{ items }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content >
              <v-row >
                <v-col cols="12" sm="4">
                  <label class="ml-1">Input Key</label>
                  <v-text-field outlined v-model.trim="docs_value.key" @blur="dupkeycheck(docs_value.key,items)" class="mt-0">
                  </v-text-field>
                  <span v-if="keycheck" style="color:red;">Key should be unique</span>
                </v-col>
                <v-col cols="12" sm="4">
                  <label>Data Type</label>
                  <v-select class="mt-0" outlined v-model.trim="docs_value.type" :items="datatypeitem" item-text="value"
                    item-value="value" placeholder="select datatype" @change="typechange(docs_value.type)"></v-select>
                </v-col>
                <v-col cols="12" sm="4" v-if="docs_value.type == 'boolean'">
                  <label class="ml-1">Input Example Value</label>
                  <v-select class="mt-0" outlined v-model.trim="docs_value.value" :items="booltype"
                    placeholder="select datatype"></v-select>
                </v-col>
                <v-col cols="12" sm="4" v-if="docs_value.type == 'string' || docs_value.type === 'base64'">
                  <label class="ml-1">Input Example Value</label>
                  <v-text-field outlined v-model.trim="docs_value.value" class="mt-0">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" v-if="docs_value.type == 'number'">
                  <label class="ml-1">Input Example Value</label>
                  <v-text-field outlined v-model.trim="docs_value.value" type="number"
                    oninput="this.value = this.value.replace(/[^0-9]/g,'');" class="mt-0">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <label class="ml-1">Description</label>
                  <v-textarea style="line-height: 0.25rem;" outlined v-model.trim="docs_value.description" :maxlength="150" :counter="150"
                    class="mt-0 lheight"></v-textarea>
                  <span v-if="docs_value.description.length" style="float: right; padding-top:10px;" >{{docs_value.description.length}}/150</span>
                </v-col>
                <v-col class="" cols="12" sm="3">
                  <label class="ml-1">Mandatory</label>
                  <v-radio-group v-model="docs_value.mandatory" row class="mt-0">
                    <v-radio label="Yes" value="yes"></v-radio>
                    <v-radio label="No" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="2" class="px-5 pb-5 ">
                  <div class="d-flex flex-column px-2">
                    <span class="ml-2">
                      <v-btn class="nocap primary white--text" style="height: 41px; margin-top: 40px;" :disabled="!docs_value.key ||
                        (!docs_value.value && docs_value.type == 'string') ||
                        !docs_value.type ||
                        !docs_value.description ||
                        !docs_value.mandatory ||
                        keycheck" @click="addinputdetails(items)">
                        <span>
                          <v-tooltip top max-width = "200">
                              <template v-slot:activator = "{ on, attrs }">
                              <v-icon dark v-bind = "attrs" v-on = "on">
                                  mdi-plus 
                              </v-icon>
                              </template>
                              <span>Add Input Details</span>
                          </v-tooltip>
                        </span>
                      </v-btn>
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-divider style="padding-bottom:10px;" v-if="regmicroalldata.input_details.length > 0"></v-divider>
              <div cols="12" class="grey lighten-3 my-4" v-if="getinputData(items)">
                <v-card>
                  <v-simple-table class="mstable">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" style="width: 20%">
                            <span class="font-weight-boald subtitle-1">Key</span>
                          </th>
                          <th class="text-left" style="width: 20%">
                            <span class="font-weight-boald subtitle-1">Value</span>
                          </th>
                          <th class="text-left" style="width: 30%">
                            <span class="font-weight-boald subtitle-1">Description</span>
                          </th>

                          <th class="text-left" style="width: 20%">
                            <span class="font-weight-boald subtitle-1">Mandatory</span>
                          </th>
                          <th class="text-left" style="width: 10%">
                            <span class="font-weight-boald subtitle-1">Delete</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item,index) in regmicroalldata.input_details" :key="item.name">
                          <td v-if="item.name==items" style="width: 20%">{{ item.key }}</td>
                          <td v-if="item.name==items" style="width: 20%">{{ item.value }}</td>
                          <td v-if="item.name==items" style="width: 30%">{{ item.description }}</td>
                          <td v-if="item.name==items" style="width: 20%">{{ item.mandatory}}</td>
                          <td v-if="item.name==items" style="width: 10%">
                            <v-icon color="error"
                              @click.prevent="removeinputdetails(regmicroalldata.input_details ,index)">mdi-trash-can
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-col cols="12" v-if="regmicroalldata.microservice_type=='data microservice' && regmicroalldata.docs=='no'">
        <v-row >
          <v-col cols="12" sm="4">
            <label class="ml-1">Input Key</label>
            <v-text-field outlined v-model.trim="docs_value.key" @blur="dupkeycheck(docs_value.key,items)" class="mt-0">
            </v-text-field>
            <span v-if="keycheck" style="color:red;">Key should be unique</span>
          </v-col>
          <v-col cols="12" sm="4">
            <label>Data Type</label>
            <v-select class="mt-0" outlined v-model.trim="docs_value.type" :items="datatypeitem" item-text="value"
              item-value="value" placeholder="select datatype" @change="typechange(docs_value.type)"></v-select>
          </v-col>
          <v-col cols="12" sm="4" v-if="docs_value.type == 'boolean'">
            <label class="ml-1">Input Example Value</label>
            <v-select class="mt-0" outlined v-model.trim="docs_value.value" :items="booltype"
              placeholder="select datatype"></v-select>
          </v-col>
          <v-col cols="12" sm="4" v-if="docs_value.type == 'string' || docs_value.type === 'base64'">
            <label class="ml-1">Input Example Value</label>
            <v-text-field outlined v-model.trim="docs_value.value" class="mt-0">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" v-if="docs_value.type == 'number'">
            <label class="ml-1">Input Example Value</label>
            <v-text-field outlined v-model.trim="docs_value.value" type="number"
              oninput="this.value = this.value.replace(/[^0-9]/g,'');" class="mt-0">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <label class="ml-1">Description</label>
            <v-textarea style="line-height: 0.25rem;" outlined v-model.trim="docs_value.description" :maxlength="150" :counter="150"
              class="mt-0 lheight"></v-textarea>
            <span v-if="docs_value.description.length" style="float: right; padding-top:10px;" >{{docs_value.description.length}}/150</span>
          </v-col>
          <v-col class="" cols="12" sm="3">
            <label class="ml-1">Mandatory</label>
            <v-radio-group v-model="docs_value.mandatory" row class="mt-0">
              <v-radio label="Yes" value="yes"></v-radio>
              <v-radio label="No" value="no"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="2" class="px-5 pb-5 ">
            <div class="d-flex flex-column px-2">
              <span class="ml-2">
                <v-btn class="nocap primary white--text" style="height: 41px; margin-top: 40px;" :disabled="!docs_value.key ||
                  (!docs_value.value && docs_value.type == 'string') ||
                  !docs_value.type ||
                  !docs_value.description ||
                  !docs_value.mandatory ||
                  keycheck" @click="addinputdetails(items)">
                  <span>
                    <v-tooltip top max-width = "200">
                        <template v-slot:activator = "{ on, attrs }">
                        <v-icon dark v-bind = "attrs" v-on = "on">
                            mdi-plus 
                        </v-icon>
                        </template>
                        <span>Add Input Details</span>
                    </v-tooltip>
                  </span>
                </v-btn>
              </span>
            </div>
          </v-col>
        </v-row>
        <v-divider style="padding-bottom:10px;" v-if="regmicroalldata.input_details.length > 0"></v-divider>
        <div cols="12" class="grey lighten-3 my-4" v-if="getinputData(items)">
          <v-card>
            <v-simple-table class="mstable">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="width: 20%">
                      <span class="font-weight-boald subtitle-1">Key</span>
                    </th>
                    <th class="text-left" style="width: 20%">
                      <span class="font-weight-boald subtitle-1">Value</span>
                    </th>
                    <th class="text-left" style="width: 30%">
                      <span class="font-weight-boald subtitle-1">Description</span>
                    </th>

                    <th class="text-left" style="width: 20%">
                      <span class="font-weight-boald subtitle-1">Mandatory</span>
                    </th>
                    <th class="text-left" style="width: 10%">
                      <span class="font-weight-boald subtitle-1">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in regmicroalldata.input_details" :key="item.name">
                    <td v-if="item.name==items" style="width: 20%">{{ item.key }}</td>
                    <td v-if="item.name==items" style="width: 20%">{{ item.value }}</td>
                    <td v-if="item.name==items" style="width: 30%">{{ item.description }}</td>
                    <td v-if="item.name==items" style="width: 20%">{{ item.mandatory}}</td>
                    <td v-if="item.name==items" style="width: 10%">
                      <v-icon color="error"
                        @click.prevent="removeinputdetails(regmicroalldata.input_details ,index)">mdi-trash-can
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </div>
      </v-col>
  </div>
</template>

<script>
// import Vue from "vue";
export default {
  name: "regDocsDetails",
  props: ['regmicroalldata', 'validForm', 'labels', 'datatypeitem'],
  data: () => ({
    apiKey: "",
    name: "",
    booltype: "",
    keycheck: false,
    docs_value: {
      key: "",
      value: "",
      type: "string",
      description: "",
      mandatory: "yes",
    },
    itemcheck:false,
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
    this.booltype = [true, false];
  },
  methods: {
    clearIcon() {
      this.regmicroalldata.docs_yaml_file = "";
      this.regmicroalldata.docs_yaml = "";
      this.regmicroalldata.docs_yaml_url = "";
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    yamlFileChange(files, input) {
      if (input === "icon") {
        this.regmicroalldata.docs_yaml_url = files[0].name;
        this.regmicroalldata.docs_yaml_file = files[0];
      }
    },
    drop(event, input) {
      event.preventDefault();
      this.yamlFileChange(event.dataTransfer.files, input)
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    typechange() {
      if (this.docs_value.type == "boolean") {
        this.docs_value.value = true;
      } else if (this.docs_value.type == "number") {
        this.docs_value.value = 0;
      } else {
        this.docs_value.value = "";
      }
    },
    dupkeycheck(key,items) {
      this.keycheck = false;
      if (this.regmicroalldata.input_details.length > 0) {
        for (let i = 0; i < this.regmicroalldata.input_details.length; i++) {
          if (this.regmicroalldata.input_details[i].key == key && this.regmicroalldata.input_details[i].name == items) {
            this.keycheck = true;
          }
        }
      }
    },
    addinputdetails(items) {
      if (this.docs_value.type == "number") {
        this.docs_value.value = Number(this.docs_value.value);
      }
      this.docs_value.name = items
      this.regmicroalldata.input_details.push(this.docs_value)
      this.docs_value = { key: "", value: "", type: "string", description: "", mandatory: "yes" };
      this.docs_value.name = ""
    },
    removeinputdetails(array,index) {
      array.splice(index,1)
    },
    getinputData(items){
      let isData=false
      if (this.regmicroalldata.input_details.length > 0){
        this.regmicroalldata.input_details.filter((arr) => {
          if(arr.name==items){
            isData=true
          }
        });
      }
      return isData
    },
  },
};
</script>

<style lang = "scss" scoped>
.v-input__slot {
  min-height: 40px !important;
}

.fileimgWidth {
  max-width: max-content;
  position: relative;
  margin-bottom: 0;
  width: max-content;
}

.msForm {
  padding: 0px 40px;
}
.icon_pos{
  padding : 10px;
}
.text_size{
  font-size:20px;
}

.close-iconPos {
  position: absolute;
  right: -30px;
  top: -2px;
}

.pos_fix {
  position: relative;
  left: 350px;
  bottom: 0;
}

.back_ground {
  background: #e4e4e4;
  font-weight: 700;
  padding: 10px 0px 29px 7px;
  border-radius: 2px;
}

.mstable .v-data-table__wrapper::-webkit-scrollbar {

  height: 7px;
}

.mstable .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #CBAC81;
  border-radius: 4px;
  // box-shadow: inset 0 0 6px rgb(0 0 0 / 50%)
}
.table {
    background-color: #CBAC81;
    margin: auto;
    width:90%;
    max-width:100%;
    border-collapse: separate;
    display: block;
    overflow-x: scroll;
  }
  thead {
    position: sticky;
    top: 1px;
    z-index: 5;
  }
  th {
    background-color: #f1f1f1;
  }
  td,
  th {
    min-width: 100px;
    max-width: 100px;
    word-wrap: break-word;
  }
.v-divider {
    border-width: thin 0px 0px 0 !important;
  }
  .apn{
    padding: 0;
  }

/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .msForm {
    padding: 0;
  }
}
</style>
