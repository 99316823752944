import { reactive } from '@vue/composition-api'

import * as loaderImage from '../../src/assets/loader.gif'

// Teporary data storage while modifying data
const stage = reactive({
  // stageName need to be registered here
  orgFunctions: [],
  // For DASettings.vue's form inputs
  daSettings: {
    accountType: '',
    startDate: '',
    endDate: '',
    minCommissionValue: '',
    commissionPercentage: '',
    feePaid: '',
    paymentDate: '',
    sdkHosting: '',
    sdkEndpoint: ''
  }
})

const dataService = () => {
  return {
    stage
  }
}

// -ABOVE- dataService() //
// ===================== //
const loader = reactive({
  display: false,
  // display: true, // Testing
  image: loaderImage
})

const uiService = () => {
  const Loader = {
    start: () => {
      loader.display = true
    },
    stop: () => {
      loader.display = false
    }
  }

  return {
    loader,
    Loader
  }
}
// -ABOVE- uiService() //
// ===================== //

export { dataService, uiService }