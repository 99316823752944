<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>{{ labels.EDIT_TITLE }}</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text class="px-6">
      <v-form>
        <addFunInfo :newItems="newItems" :labels="labels"/>
      </v-form>  
    </v-card-text>
    <v-divider></v-divider>
    <div class="px-md-10">
      <v-alert
        class="mt-2 text-left"
        text
        :type="response_status"
        v-if="response_msg"
      >
        {{ response_msg }}
      </v-alert>
    </div>
    <v-card-actions v-model="closeDialog">
      <v-spacer></v-spacer>
      <div class="pl-10 py-5">       
        <v-btn class="nocap" @click="closeFunEdit" text>
          <span class="pl-2"> {{ labels.ADD_CANCEL }}</span>
        </v-btn>
        <v-btn class="nocap primary" text @click="save">
          <span class="pl-2"> {{ labels.ADD_CREATE }} </span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import addFunInfo from "./funPopUp/addFunInfo.vue";
import API_Util from "../services/util/API_Util.js";

export default {
  components: {
   addFunInfo,
  },
  name: "editFun",
  props: ['labels', 'newItems', 'response_msg', 'response_status'],

  data: () => ({
    closeDialog: false,
    response_msg: "",
    response_status: "",
    panel: [0],
    apiKey: "",
    owner: "",
    user: "",
  }),
  created(){
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.user = sessionObj.session.id;
    this.owner = localStorage.getItem('owner');
  },
  methods: {
    closeFunEdit() {
      this.$emit('closeFunEdit', false);
    },

    clearObjectData() {
      this.closeDialog = false;
      this.response_msg = "";
    },

  save () {
      if (this.newItems.id>0) {
        // this.newItems.description1 = this.newItems.funDesc
        this.newItems.updatedBy = this.newItems.creadedBy
        // this.newItems.modelExport = this.newItems.model_export_url
        this.newItems.deltTagIds = []
        this.newItems.deltConsistsIds = []
        this.newItems.ownerId = this.owner;
        this.newItems.updatedBy = this.user;
        this.newItems.type = "140";
        this.newItems.clas = "CLASS_INTERNAL";
        this.newItems.requestTypes = [];
        this.newItems.deleteRequestTypes = [];
      }
        this.$emit('saveFunEditedData', this.newItems);
    },
    getFunctionList(){
      const headers = { "Content-Type" : "application/json", "x-api-key" : this.apiKey};
      API_Util.URLS.axios.post(API_Util.URLS.functionList, {headers})
      .then(() => {
      })
    },

    onUpdate(text) {
      this.text = text;
    },
    onChangefile(fieldname, imageref) {
      this[fieldname] = [...this.$refs[fieldname].files];

      if (fieldname != "imagelist" && this[fieldname].length > 1) {
        this[fieldname].splice(0, this[fieldname].length - 1);
      }

      for (let i = 0; i < this[fieldname].length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs[`${imageref}` + parseInt(i)][0].src = reader.result;
        };
        reader.readAsDataURL(this[fieldname][i]);
      }
    },
  },
};
</script>

<style lang="scss">
.chevron-down-circle-outline {
  color: red;
}
.v-expansion-panel-header__icon .v-icon {
  color: red !important;
}

.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color:  var(--v-secondary-darken4) !important;
  border: 1px solid  var(--v-secondary-darken1) !important;
  border-radius: 100% !important;
}
</style>