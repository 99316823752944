<template>
  <div class="white ownerlist-cont pt-6 pb-10">
    <v-content class="px-10">
      <div class="pb-4">
        <div class="owner-label">
          <h2 class="mr-md">{{ labels.OWNER }}</h2>
        </div>
        <div class="text-right">
          <v-spacer></v-spacer>
          <v-btn text
            class="tertiary2--text nocap btn-space"
            @click.stop="addOwner = true"
          >
            <v-icon class="pr-1 add">mdi-account-multiple-plus</v-icon>
             {{ labels.OWNER_ADD }}
          </v-btn>
        </div>
      </div>

      <v-data-table
       :mobile-breakpoint="0"
        custom-group=""
        v-model="ownerTable"
        :headers="headers"
        :items="ownerList"
        item-key="name"
        sortable="false,"
        class="elevation-1 listingTable"
        @click:row="selectOwner($event)"
      >
         <template v-slot:item="{item}">
           <tr @click="selectOwner(item)"> 
    <td>
        {{item["ownerName"]}}
    </td>
     <td>{{item["name"]}}
    </td>
     <td>
         {{item["desc"]}}
    </td>
         <td>
         {{item["userName"]}}
    </td>
     <td class="nobr">
         {{item["dateCreated"]}}
    </td>
             <td>
         <span>Active</span>
    </td>
           </tr>
                 </template>
      </v-data-table>


      <v-row justify="center">
        <v-form ref="form" v-model="valid">
          <v-dialog scrollable v-model="addOwner" persistent max-width="800px">
            <v-card>
              <v-card-title class="px-10 pt-6">
                <h3 class="text-left pl-5">{{labels.OWNER_ADD}}</h3>
                <v-spacer></v-spacer>
                <v-icon @click="closeAddowner()">mdi-close-circle-outline </v-icon>
              </v-card-title>
              <v-divider class="my-4"></v-divider>
              <v-card-text style="height: 400px">
                <div class="addOwner-input">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <label>{{labels.OW_NAME}}</label>
                      <v-text-field
                        outlined
                        v-model.trim="ownerName"
                        ref="typeBox"
                        messages="none"
                      >
                        <template v-slot:message="{ message, key }">
                          <span v-if="!ownerName && !isValidForm" style="color:red;">{{labels.OW_NAMEVAL}}</span>
                          <span v-if="insertInfoMessage.length" style="color:red;">{{ insertInfoMessage }}</span>
                          <span v-if="false">{{message}} {{key}}</span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <label>{{labels.ORG_NA}}</label>
                      <v-text-field
                        outlined
                        v-model.trim="orgName"
                      ></v-text-field>
                      <span v-if="!orgName && !isValidForm" style="color:red;">{{labels.ORG_NAVAL}}</span>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <label>{{ labels.ORG_CLIID }}</label>
                      <v-text-field
                        outlined
                        v-model.trim="clientId"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <label>{{ labels.EMAIL }}</label>
                      <v-text-field
                        outlined
                        v-model.trim="email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <label>{{labels.AD_USR_NM}}</label>
                      <v-text-field
                        outlined
                        v-model.trim="adminName"
                      ></v-text-field>
                      <span v-if="!adminName && !isValidForm" style="color:red;">{{labels.AD_USR_NMVAL}}</span>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <label>{{labels.AD_PASS}}</label>
                      <v-text-field
                        outlined
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        @click:append="show1 = !show1"
                        hint="At least 8 characters"
                      ></v-text-field>
                      <span v-if="(!password || password.length < 8) && !isValidForm" style="color:red;">{{labels.AD_PASSVAL}}</span>
                    </v-col>
                    

                    <v-col cols="12" sm="6">
                      <label>Redis Cloud</label>
                      <v-select
                        outlined
                        v-model="redis_cloud"
                        :items="redis_provider"
                        item-text="value"
                        item-value="code"
                        placeholder="Select Category"
                      ></v-select>
                      <span
                       v-if="!redis_cloud && !isValidForm"
                        style="color: red"
                        >Redis Cloud instance is required</span
                      >
                    </v-col>
                    <v-col cols="12" sm="6">
                      <label>User Quota</label>
                      <v-text-field 
                        type="text"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        outlined
                        v-model.number="user_quata"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <label>{{labels.DESC}}</label>
                      <v-textarea
                        outlined
                        v-model.trim="dec"
                        name="input-7-1"
                        rows="3"
                      ></v-textarea>
                      <span v-if="!dec && !isValidForm" style="color:red;">{{labels.DESCVAL}}</span>
                    </v-col>
                    <v-col cols="12">
                      <label class="pl-1">{{labels.ASS_MICRO}}</label>
                    <!-- <div>select 1 or more microservice</div> -->
                    <v-select
                      solo
                      v-model="temAssignMsList"
                      append-icon="mdi-menu-down"
                      :items="assignMSListTemp"
                      item-text="function_name"
                      item-value="function_id"
                      :placeholder="temAssignMsList.length<1? 'Select microservices':temAssignMsList.length+' microservice selected'"
                      :persistent-placeholder="temAssignMsList.length>0? true:false"
                      :menu-props="{ bottom: true, offsetY: true }"
                      return-object
                      chips
                      multiple
                      background-color="#cbac81"
                      class="custom-placeholer-color"
                    >
                      <template v-slot:selection="{ index }">
                        <!-- <v-chip v-if="index === 0">
                          <span>{{ item.function_name }}</span>
                        </v-chip> -->
                        <span class="white--text" v-if="index === 0">{{temAssignMsList.length}} microservice selected</span>
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item style="padding: 0px; position: sticky; top: 0; width: 100%; z-index: 1">
                          <v-list-item-content style="padding: 0px;">
                            <v-text-field
                            clearable
                            append-icon="mdi mdi-magnify"
                            @click:clear="clearSearch()" 
                            v-model="searchTerm" 
                            placeholder="Input Keyword to search"
                            solo
                            @input="searchMicroservices"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item
                          v-on="on"
                          v-bind="attrs"
                          #default="{ active }"
                          two-line
                          style="padding-right: 0px !important; padding-left: 0px !important;"
                        >
                          <v-col cols="12" class="pa-0">
                            <v-row class="pl-4">
                              <v-list-item-action class="check-size">
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content style="width: 500px;">
                                <v-list-item-title color="teritary1">
                                  {{ item.function_name }}</v-list-item-title
                                >
                                <v-list-item-subtitle>{{
                                  item.function_description
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-row>
                            <v-divider></v-divider>
                          </v-col>
                        </v-list-item>
                      </template>
                    </v-select>
                    <div v-if="temAssignMsList.length">Selected microservices</div>
                    <div v-for="(fun, idx) in temAssignMsList" 
                        :key="fun.function_id" style="padding-bottom:10px;">
                      <v-chip label class="descChip grey lighten-4 py-4 px-4" @click.prevent="temAssignMsList.splice(idx,1)"> 
                        <v-list class="grey lighten-4">
                          <v-list-item
                            two-line
                            style="padding: 0px;"
                          >
                            <v-list-item-content style="width: 550px;">
                              <v-list-item-title>
                                {{ fun.function_name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ fun.function_description }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-icon size="20" style="padding-left:40px;">mdi-close</v-icon>
                      </v-chip>
                    </div>

                    </v-col>
                  </v-row>
                  <!-- <v-divider class="pb-4"></v-divider> -->
                  <!-- <v-row>
                    <v-col
                      cols="12"
                      v-if="insertInfoMessage && !insertInfoMessageSucess"
                    >
                     <div class="px-md-16">
                      <v-alert dense type="info" class="tertiary1">
                        {{ insertInfoMessage }}
                      </v-alert>
                     </div>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="insertInfoMessageSucess && !insertInfoMessage"
                    >
                    <div class="px-md-16">
                      <v-alert dense type="success">
                        {{ insertInfoMessageSucess }}
                      </v-alert>
                    </div>
                    </v-col>
                  </v-row> -->
                </div>
              </v-card-text>
              <v-divider class=""></v-divider>
              <v-card-actions class="px-10 py-6">
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary darken-1"
                  class="nocap mr-3"
                  text
                  @click="closeAddowner()"
                >
                {{labels.CAN}}
                </v-btn>
                <v-btn

                  color="tertiary2 darken-1 white--text"
                  class="nocap ml-3"
                  @click="ownerdetail()"
                  :disabled="!ownerName || !orgName || !email || !adminName || !password || !dec || !redis_cloud "
                >
                  {{labels.AD}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
      </v-row>
      <!-- Success pop up -->
      <v-row justify="center">
        <v-dialog
          class="msPop"
          style="box-shadow: none; background-color: transparent"
          v-model="successOwner"
          persistent
          max-width="388"
          content-class="vDialog"
        >
          <v-card
            class="d-flex flex-column pa-0 align-center justify-center"
            flat
            style="background-color: transparent"
          >
            <v-card-title
              class="text-h5 text-bold d-flex text-center pa-0"
              style="margin-bottom: -19px"
            >
              <v-img
                style="z-index: 1"
                alt="sentient logo"
                class="d-flex align-center justify-center"
                src="@/assets/tick-mark1.svg"
                contain
                max-width="192"
              ></v-img>
            </v-card-title>
            <v-card-text
              class="text-center white rounded-lg"
              style="height: 200px"
            >
              <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

              <v-card-title class="text-h5 text-bold d-flex justify-center">
                <div class="mt-4">Success</div>
              </v-card-title>
              Successfully Processed.
              <v-card-actions>
                <div class="text-center mt-4">
                  <v-btn class="nocap" color="primary" @click="successOwner = false">Done </v-btn>
                </div>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- End -->
    </v-content>
    <loaderimg v-if="loader"/>
  </div>
</template> 
<script>
import loaderimg from "./loaderimg.vue"
import API_Util from "../services/util/API_Util.js";
export default {
  components:{loaderimg},
  mounted() {
    // this.$refs.ownername.$el.focus();
  },
  name: "ownerlisting",
  data: () => ({
    data: {
      options: {},
      selected: "",
    },
    headers: [],
    alert: false,
    addOwner: false,
    singleSelect: false,
    ownerTable: [],
    assignMSListTemp: [],
    searchTerm: "",
    show1: false,
    successOwner: false,
    password: "",
    clientId: "",
    email: "",
    redis_cloud: "",
    user_quata:"",
    redis_provider: [],
    valid: false,
    ownerNameRule: [(v) => !!v || "Owner name is required"],
    orgNameRule: [(v) => !!v || "Organisation name is required"],
    adminNameRule: [(v) => !!v || "Admin user name is required"],
    descRule: [(v) => !!v || "Description is required"],
    ownerName: "",
    orgName: "",
    adminName: "",
    dec: "",
    ownerList: [],
    assignMSList: [],
    temAssignMsList: [],
    functionIds: [],
    insertInfo: "",
    insertInfoMessage: false,
    insertOrgMessage: false,
    insertUserMessage: false,
    insertInfoMessageSucess: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
    apiKey: "",
    user_id: "",
    owner: "",
    lang: "EN",
    labels: {},
    isValidForm: true,
    loader: false,
    ownerType: localStorage.getItem('ownerType'),
  }),
  watch: {
    ownerName(val, oldVal){
      if(oldVal !== val){
        this.insertInfoMessage = "";
      }
    }
  },
  methods: {
    ownerListOnLoad: function () {
      var empt = {};
      var data={type:'apis'}
      API_Util.URLS.axios
        .post(API_Util.URLS.listOrg, empt, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.ownerList = response.data.results;
          this.loader = false;
        });

      API_Util.URLS.axios
        .post(API_Util.URLS.listMicroservice ,data, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.assignMSList = response.data.results;
          this.assignMSListTemp = response.data.results;
          this.assignMSList.sort((a,b)=>a.function_name>b.function_name?1:-1);
          this.assignMSListTemp.sort((a,b)=>a.function_name>b.function_name?1:-1);
        });
    },
    searchMicroservices(){
      if(this.searchTerm == ""){
        this.assignMSListTemp = this.assignMSList;
      }
      else{
        this.assignMSListTemp = this.assignMSList.filter((el) =>
           el.function_name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
    },
    clearSearch(){
      this.assignMSListTemp = this.assignMSList;
    },
    selectOwner(value) {
      localStorage.setItem("owner", value.ownerId);
      localStorage.setItem('ownerName', value.ownerName);
      localStorage.setItem('ownerCode', value.ownerCode);
      localStorage.setItem('ownerType', value.ownerType);
      localStorage.setItem('ownerDesc', value.desc);
      localStorage.setItem('redisCloud', value.redisCloud);
      this.$emit('ownerchange',value.ownerType);
      // this.$router.push({name:'ownersettings',params:{ownerId:value.ownerId}}, () => { window.location.reload()});
      this.$router.push({name:'ownersettings'}, () => { window.location.reload()});
    },
    // addTem: function () {
    //   var selectedValue = this.assignMSList.find(
    //     (res) => res.function_id == this.msList
    //   );
    //   this.temAssignMsList.push(selectedValue);
    //   this.functionIds.push(this.msList);
    //   var selectedIndex = this.assignMSList.indexOf(selectedValue);
    //   if (selectedIndex > -1) {
    //     this.assignMSList.splice(selectedIndex, 1);
    //   }
    //   this.msList = "";
    // },
    ownerdetail: function () {
      // this.$refs.form.validate();
      if (this.ownerName == "") {
        this.isValidForm = false;
      } else if (this.orgName == "") {
        this.isValidForm = false;
        } else if (this.adminName == "") {
          this.isValidForm = false;
        } else if (this.password == "" ) {
          this.isValidForm = false;
        } else if (this.dec == "") {
          this.isValidForm = false;
        }else if (this.redis_cloud == "") {
          this.isValidForm = false;
        }else if (this.email == "") {
          this.isValidForm = false;
        }
        else {
          this.isValidForm = true;
          this.temAssignMsList.forEach(obj => {
            this.functionIds.push(obj.function_id);
          })
      var data = {
        org_id: 0,
        ownerName: this.ownerName,
        adminUserName: this.adminName,
        adminPassword: this.password,
        orgName: this.orgName,
        clientId: this.clientId,
        email: this.email,
        desc: this.dec,
        dateUpdated: "",
        upDatedBy: this.user_id,
        functionIds: this.functionIds,
        redis_cloud: this.redis_cloud,
        user_quata:this.user_quata
           
      };
      this.loader = true;
      API_Util.URLS.axios
        .post(API_Util.URLS.addOwner, data, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.loader = false;
          var insertInfo = response.data;
          if (insertInfo.status == "Failure") {
            this.insertInfoMessage = "Owner name already exists";
            this.$refs.typeBox.focus();
          }
          else {
            // this.insertInfoMessageSucess = insertInfo.message;
            this.successOwner = true;
            this.ownerListOnLoad();
            setTimeout(() => {
              this.refreshData();
            }, 300);
          }
        });
      }
    },
    getFlexi() {
      API_Util.URLS.axios
        .post(
          API_Util.URLS.getlblbyscr,
          {
            owner_id: this.owner,
            scrId: "OWN_LIST",
            lang: this.lang,
          },
          { headers: { "x-api-key": this.apiKey } }
        )
        .then((response) => {
          this.labels = response.data.labels;

          this.headers = [
                          {
                            text: this.labels.OWN_NAME,
                            align: "start",
                            value: "ownerName",
                          },
                          { text: this.labels.OWN_ORG, value: "name" },
                          { text: this.labels.DESC, value: "desc" },
                          { text: this.labels.CREATBY, value: "userName" },
                          { text: this.labels.DT_CREAT, value: "dateCreated", class:"nobr"},
                          { text: this.labels.STATUS, value: "status"},
                        ];
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    closeAddowner: function () {
      this.refreshData();
    },
    refreshData: function () {
      this.ownerName = "";
      this.adminName = "";
      this.password = "";
      this.orgName = "";
      this.clientId = "";
      this.email = "";
      this.redis_cloud = "";
      this.user_quata = "";
      this.dec = "";
      this.functionIds = [];
      this.temAssignMsList = [];
      this.msList = "";
      this.addOwner = false;
      this.isValidForm = true;
    },

    async getProvider() {
      var data = {
        owner_id: this.owner,
        lang: "EN",
        code: "CLOUD_PROVIDER",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.redis_provider = response;
    },
  },
  created: function () {
    if (localStorage.getItem('visit') == 0) {
      localStorage.setItem('visit', 1);
      location.reload();      
    }
    this.loader = true;
    var sessionObj = this.$session.get("user_session");
    if (!sessionObj || !sessionObj['session']) {  // Redirect to login page
        this.$router.push("/login");
    }
    this.apiKey = sessionObj.session.userApiKey;
    this.user_id = sessionObj.session.id;
    this.owner = sessionObj.session.owner;
    this.ownerType = localStorage.getItem('ownerType');
    if (this.ownerType != "PM"){
      this.$router.push({name:'ownersettings'}, () => { window.location.reload()});
    } 
    this.getFlexi();
    this.ownerListOnLoad();
    this.getProvider();
  },
};
</script>
<style lang="scss">
.mr-md {
  margin-bottom: -30px;
}
.add.v-icon.v-icon {
  font-size: 18px;
}
.chipBox {
  max-height: 250px;
  overflow: auto;
}
// .sideNav{
//   display: none;
// }

.descChip{
  height: 64px !important;
}

.dialog-size{
  width: 650px;
}

.custom-placeholer-color input::placeholder {
  color: white!important;
  opacity: 1;
}

.v-menu__content {
  // position: fixed;
  //max-height: 240px !important;
}
.btn-space {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media only screen and (max-width: 550px){
  .btn-space{
    padding-top: 50px !important;
    padding-right: 55px !important;
  }
}

@media only screen and (max-width: 700px){
  .dialog-size{
    width: 300px;
  }
  .v-menu_content{
    left: 40px;
  }
}

.descChip.v-size--default{
    height: 64px !important;
    width: 100% !important;
  }

.check-size{
  margin-right: 10px !important;
  margin-left: 0px;
}
.owner-label {
  text-align: center;
}
.addOwner-input {
  padding: 40px;
}
// .v-text-field.v-text-field--enclosed .v-text-field__details {
//     margin-top: -10px !important;
// }

/* ---------------------------------- mobile view ---------------------------------- */
@media only screen and (min-width: 350px) and (max-width: 767px) {
  .ownerlist-cont,.ownerlist-cont .v-main {
    padding: 0 10px !important;
  }
  .owner-label {
    text-align: left;
  }
  .owner-label h2 {
    position: relative;
    top: 4px;
  }
  .btn-space,.addOwner-input {
    padding:  0 !important;
  }
}
</style>