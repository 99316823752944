<template>
  <!-- Reactive UI Component for add / remove tag elements -->

  <div>
    <!-- -------------------------------- -->
    <!-- Drop down list for tag selection -->
    <!-- -------------------------------- -->
    <!-- {{ stage }}
    {{ model }} -->
    <v-row>
      <div class="my-4">
        <v-menu offset-x :close-on-content-click="false" class="drop-size">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :outlined="outlined"
              dark
              :color="color"
              v-bind="attrs"
              v-on="on"
              class="nocap"
            >
              {{ title }}
              <v-icon style="margin: 0"> mdi-menu-right </v-icon>
            </v-btn>
          </template>

          <v-list style="max-height: 60vh;" class="drop-size">
            <!-- <v-list-item>
              <v-list-item-content>
                <v-text-field
                  v-model="searchText"
                  placeholder="Search"
                  @input="searchData"
                  append-icon="mdi-magnify"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item v-for="item in data" :key="item.function_id" v-show="!checkExisting(item.function_name,stage[stageName])">
              <div @click="editing.value = true">
                <v-checkbox
                  :color="color"
                  v-model="stage[stageName]"
                  :value="item"
                  
                >
                  <template v-slot:label>
                    <span class="black--text">
                      {{ item.function_name }}
                    </span>
                  </template>
                </v-checkbox>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-row>
    <!-- -ABOVE- Drop down list for tag selection -->
    <!-- ======================================== -->

    <v-divider class="ma-2"></v-divider>

    <!-- -------------------------------------------- -->
    <!-- Display activated / selected element as tags -->
    <!-- -------------------------------------------- -->
    <h3 class="my-2">{{ tagsTitle }}</h3>
    <v-row>
      <v-chip
        class="mr-2 mb-2"
        :color="color"
        dark
        close
        v-for="item in stage[stageName]"
        :key="item.function_id"
        @click:close="removeArrayItem(stage[stageName], item)"
      >
        {{ item.function_name }}
      </v-chip>
    </v-row>
    <!-- -ABOVE- Display activated / selected element as tags -->
    <!-- ==================================================== -->

    <v-row justify="center" style="height: 48px" class="my-4">
      <v-alert
        :value="alert.show"
        dense
        transition="fade-transition"
        :type="alert.type"
        >{{ alert.message }}</v-alert
      >
    </v-row>

    <!-- ----------------------- -->
    <!-- -START- Control buttons -->

    <v-row justify="center">
      <v-btn class="nocap mx-6" :disabled="!editing.value" text @click="initStage()">
        Cancel
      </v-btn>
      <v-btn
        class="nocap mx-6"
        :disabled="!editing.value"
        color="var(--v-tertiary2-base)"
        :dark="editing.value"
        @click="submit()"
      >
        <v-icon>{{ onSubmitBtnIcon }}</v-icon>
        {{ onSubmitBtnText }}</v-btn
      >
    </v-row>
    <!-- -ABOVE- Control buttons -->
    <!-- ======================= -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { dataService, uiService } from "../../services/globalService";

export default defineComponent({
  props: {
    // Button, Checkbox and Tags color
    color: { type: String, default: "#424242" },
    // Button style
    outlined: { type: Boolean, default: false },
    // Input data for checkbox selection, Array of items
    data: {},
    // Value to be modified (but not modify on this model directly)
    model: {},
    // Identify and modify values been registered in dataService.js stage element
    // stageName has to be registered in dataService.js in order to use
    stageName: {},
    // Title of selection menu button
    title: { type: String, default: "Please select" },
    divider: { type: Boolean, default: false },
    // Import an object from service file that contains the onSubmit function as a function
    serviceObject: {},
    // onSubmit event as string
    onSubmitFunction: {},
    // Text for onSubmit button
    onSubmitBtnText: { type: String, default: "Save" },
    onSubmitBtnIcon: { type: String, default: "mdi-content-save-edit-outline" },
    // Small title on tags area
    tagsTitle: { type: String, default: "" },
    // Alert message on success state
    successMessage: { type: String, default: "Changes saved!" },
    // Alert mssage on error state
    errorMessage: { type: String, default: "Error !" },
  },

  setup(props) {
    const searchItems = ref('');
    // Stage is the variable stores all data that been modified in THIS component
    const { stage } = dataService(),
      { Loader } = uiService(),
      editing = ref(false),
      // Data for <v-alert> element
      alert = ref({
        show: false,
        // show: true, // Testing
        type: "success", // success, info, warn, error
        message: props.successMessage,
      });
    // In order to avoid the reacticity
    // Element been assigned to stage one by one with a loop
    // Instead of assign it as an object like stage[props.stageName] = props.model
    // What ever changed on stage[props.stageName] now will not affect props.model
    const initStage = () => {
      editing.value = false;
      stage[props.stageName] = [];
      props.model.forEach((item) => {
        stage[props.stageName].push(item);
      });
    };

    // ---------------- //
    // Helper functions //
    // ---------------- //
    // Function to remove an item from array
    const removeArrayItem = (array, item) => {
        editing.value = true;
        const index = array.indexOf(item);
        if (index !== -1) {
          array.splice(index, 1);
        }
      },
      // Function names exists in dropdown
       checkExisting = (name,dataarray) => {
      var isExist=false;
      if(dataarray!=undefined){
        dataarray.forEach((fun)=>{
          if(name==fun.function_name){
            isExist=true;
          }
        })
      }
      return isExist;
    },
      // Functions toggle alert and hide it after a while
      toggleAlert = (state) => {
        switch (state) {
          case "error":
            alert.value.type = "error";
            alert.value.message = props.errorMessage;
            break;
          default: // Default success case is arleady set
        }
        alert.value.show = true;
        setTimeout(() => {
          alert.value.show = false;
        }, 2000);
      };


    const dataCopy = props.data;
    const searchText = ref();
    
    const searchData = () => {

      if (searchText.value=="") {
        props.data = dataCopy;
      }else{
        props.data = dataCopy.filter((dt) => {
          return dt.function_name.toLowerCase().includes(searchText.value.toLowerCase());
        });
      }
    };

    const submit = () => {
      // Show loading
      Loader.value.start();
      // Call function in attached service object, and pass the stage data to it
      props.serviceObject[props.onSubmitFunction](stage[props.stageName])
        .then(() => {
          Loader.value.stop();
          editing.value = false;
          // Toggle alert notification
          toggleAlert("success");

          //   Init stage again to make the value not reactive
          initStage();
        })

        .catch((err) => {
          Loader.stop();
          // [TODOS]: Based on returned message, alert also can be info or warning
          toggleAlert("error");
          console.log(err);
        });
    };

    onMounted(() => {
      initStage();
    });

    return {
      removeArrayItem,
      stage,
      initStage,
      submit,
      editing,
      alert,
      searchItems,
      searchData,
      searchText,
      checkExisting
    };
  },
});
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 435px){
  .drop-size{
    min-width: 338px !important;
    top: 551px !important;
    left: 30px !important;
    transform-origin: left top !important;
    z-index: 8 !important;
  }
}
</style>