
<template>
    <div>
        <div>
            <v-breadcrumbs :items="items" v-if="ownerDrop">
                <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
            </v-breadcrumbs>
            <div class="owner-tlt">
                <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="ownerDrop" />
            </div>
            <v-content class="white maincontent">
                <div class="pb-4">
                    <div class="d-flex justify-center align-center">
                        <div>
                            <h2 class="text-center font-weigt-bold">Register Microservice</h2>
                        </div>
                        <div class="text-right">
                            <v-spacer></v-spacer>
                        </div>
                    </div>
                    <div class="d-flex  selected-border pb-3" :class="uregms == true ? '' : 'justify-end'">
                        <v-btn text class="tertiary2--text nocap pb-0 mt-4 mr-4 mb-0 pl-0  me-auto text-h6" v-if="uregms"
                        @click="microserviceType()">
                            <v-icon class="pr-1 add" style="font-size: 26px;">
                                mdi-view-dashboard-edit-outline
                            </v-icon>
                            {{ labels.REGMS }}
                        </v-btn>
                        <p class="pb-3 mt-4 mr-4 mb-0" :class="tabstatus == 'pending' ? 'active-chip' : ''"
                            @click="tabstatus = 'pending', searchText = '', selectedmicro = [], getheader('pending')">{{
                                labels.PENDING }} <v-chip color="c5c5c5" v-if="regmslisting.pending">{{
        regmslisting.pending.length }}</v-chip></p>
                        <p class="pb-3 mt-4 mr-4 mb-0" :class="tabstatus == 'approved' ? 'active-chip' : ''"
                            @click="tabstatus = 'approved', searchText = '', selectedmicro = [], getheader('approved')">{{
                                labels.APPROVED }}<v-chip color="#c5c5c5" v-if="regmslisting.approved"> {{
        regmslisting.approved.length }}</v-chip></p>
                        <p class="pb-3 mt-4 mr-4 mb-0" :class="tabstatus == 'rejected' ? 'active-chip' : ''"
                            @click="tabstatus = 'rejected', searchText = '', selectedmicro = [], getheader('rejected')">{{
                                labels.REJECTED }}<v-chip color="#c5c5c5" v-if="regmslisting.rejected"> {{
        regmslisting.rejected.length }}</v-chip></p>
                        <p class="pb-3 mt-4 mr-4 mb-0" :class="tabstatus == 'drafts' ? 'active-chip' : ''"
                            @click="tabstatus = 'drafts', searchText = '', selectedmicro = [], getheader('drafts')">
                            Drafts<v-chip color="#c5c5c5" v-if="regmslisting.rejected"> {{
                                regmslisting.drafts.length }}</v-chip></p>
                    </div>
                    <v-divider class="mt-1"></v-divider>
                    <div class="d-flex flex-md-row flex-sm-column justify-start align-md-end justify-md-space-between my-4">
                        <div style="width : 30%;">
                            <v-text-field class="search-bar sen-bg-white" v-model="searchText" clearable
                                @click:clear="resetSearch(tabstatus)" solo hide-details append-icon="mdi-magnify"
                                placeholder="Search Microservices" v-on:keyup="findText(tabstatus)">
                            </v-text-field>
                        </div>
                        <div>
                            <v-btn text @click.stop="approvems = true" v-if="tabstatus == 'pending' && uapprove"
                                :disabled="!selectedmicro.length" class="tertiary2--text nocap py-5">
                                <v-icon class="pr-1 add" style="font-size: 26px">
                                    mdi-check-circle
                                </v-icon>
                                {{ labels.APPROVE }}
                            </v-btn>
                            <v-btn text @click.stop="rejectms = true; rejection = ''"
                                v-if="tabstatus == 'pending' && ureject" :disabled="!selectedmicro.length"
                                class="warning--text nocap py-5">
                                <v-icon class="pr-1 add" style="font-size: 26px">
                                    mdi-close-circle
                                </v-icon>
                                {{ labels.REJECT }}
                            </v-btn>
                            <v-btn text v-if="udelete" @click.stop="deletems = true" :disabled="!selectedmicro.length"
                                class="error--text nocap py-5">
                                <v-icon class="pr-1 add" style="font-size: 26px">
                                    mdi-trash-can
                                </v-icon>
                                {{ labels.DEL }}
                            </v-btn>
                            <v-btn text class="tertiary2--text nocap py-5" v-if="tabstatus == 'approved'"
                                @click="getRegMicroserviceList()">
                                <v-icon class="pr-1 add">mdi-refresh</v-icon>
                                Refresh
                            </v-btn>
                        </div>
                    </div>

                    <!-----------  Register Microservice Table  ------------------->
                    <div>
                        <v-data-table :mobile-breakpoint="0" :single-select="tabstatus == 'pending' ? true : false"
                            v-model="selectedmicro" :headers="header" :search="search_label"
                            :items="regmslisting[tabstatus]" item-key="fun_id" item-value="fun_id" show-select
                            @click:row="openMsDetailPage" density="compact" class="elevation-1 listingTable break-all">
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>
                                        <v-checkbox v-model="selectedmicro" :value="item" @click="selected_micro(item)"
                                            style="margin: 0px; padding: 0px" hide-details multiple color="dark-grey" />
                                    </td>
                                    <td @click="openMsDetailPage(item, tabstatus)"
                                        :title="item.fun_model_status != 'Deployment Successful' && tabstatus == 'approved' ? 'Deployment is going on please wait' : ''">
                                        {{ item.fun_name }}</td>
                                    <td @click="openMsDetailPage(item, tabstatus)"
                                        :title="item.fun_model_status != 'Deployment Successful' && tabstatus == 'approved' ? 'Deployment is going on please wait' : ''">
                                        {{ item.fun_type }}</td>
                                    <td @click="openMsDetailPage(item, tabstatus)" v-if="tabstatus === 'pending'">
                                        {{ item.mstype }}</td>
                                    <td @click="openMsDetailPage(item, tabstatus)" v-if="tabstatus === 'pending'">{{
                                        item.fun_desc }}
                                    </td>
                                    <td @click="openMsDetailPage(item, tabstatus)" v-if="tabstatus === 'approved'"
                                        :title="item.fun_model_status != 'Deployment Successful' && tabstatus == 'approved' ? 'Deployment is going on please wait' : ''">
                                        {{ item.fun_ms_code
                                        }}</td>

                                    <td @click="openMsDetailPage(item, tabstatus)" v-if="tabstatus === 'approved'"
                                        :title="item.fun_model_status != 'Deployment Successful' && tabstatus == 'approved' ? 'Deployment is going on please wait' : ''">
                                        <span v-if="item.fun_model_status == 'Deployment Successful' && item.mstype == 'non data microservice'"><v-list-item
                                                class="pl-0" v-for="(item, index) in item.api_details[item.deploy_type]"
                                                :key="item">
                                                {{ index + 1 }}. {{ item.endpoint }}</v-list-item></span>
                                        <span v-else-if="item.fun_model_status == 'Deployment Successful' && item.mstype == 'data microservice'">
                                        {{item.endpoint}}</span>
                                        <span v-else>-</span>
                                    </td>
                                    <td @click="openMsDetailPage(item, tabstatus)" v-if="tabstatus === 'approved'"
                                        :title="item.fun_model_status != 'Deployment Successful' && tabstatus == 'approved' ? 'Deployment is going on please wait' : ''">
                                        {{ item.fun_model_status }}</td>
                                    <td @click="openMsDetailPage(item, tabstatus)" v-if="tabstatus === 'rejected'">{{
                                        item.fun_model_status }}</td>
                                    <td v-if="tabstatus === 'rejected'">
                                        <span v-if="!item.readmore">{{ item.fun_error_msg.slice(0, 100) }}....
                                            <a @click="activateReadMore(item,tabstatus)"><v-icon
                                                class="text-right">mdi-menu-down</v-icon>
                                            </a>
                                        </span>
                                        <span v-if="item.readmore">{{ item.fun_error_msg }}
                                            <a @click="activateReadLess(item,tabstatus)"><v-icon
                                                class="text-right">mdi-menu-up</v-icon>
                                            </a>
                                        </span>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                    <!------------------end-------------------->

                    <!------------Register Microservice Form ------------------->
                    <v-row justify="center">
                        <v-form>
                            <v-dialog class="stepBystep" scrollable v-model="regmicroservices" persistent
                                max-width="1000px">
                                <v-card class="ms-title">
                                    <v-card-title>
                                        <div>
                                            <h3 class="text-center">{{ labels.REGMS }}</h3>
                                            <div class="closeicon-right">
                                                <v-icon @click="clearObjectData" class="text-right">mdi-close-circle-outline
                                                </v-icon>
                                            </div>
                                        </div>
                                    </v-card-title>
                                    <v-card-text style="height: 400px" class="basicIfo-sec">
                                        <div>
                                            <v-stepper alt-labels v-model="currentStep" flat class="stepper"
                                                color="transparent">
                                                <v-stepper-header flat class="custom-white sticky">
                                                    <template v-for="(item, index) in stepperData">
                                                        <v-stepper-step class="custom" :key="item.id"
                                                            :class="(currentStep > index + 1) ? 'custom1 v-stepper__step--complete' : ''"
                                                            :step="index + 1">
                                                            <span class="caption"> {{ item.title }}</span>
                                                        </v-stepper-step>
                                                        <v-divider :key="item.id"></v-divider>
                                                    </template>
                                                </v-stepper-header>
                                                <v-stepper-items>
                                                    <v-stepper-content step="1">
                                                        <v-col cols="12" class="pb-0 px-6">
                                                            <h3 class="px-3">{{ labels.MSD }}</h3>
                                                        </v-col>
                                                        <regMsDetails :regmicroalldata="regmicroalldata"
                                                            :baseversionitem="baseversionitem" :validForm="validForm"
                                                            :addothersversion="addothersversion" :labels="labels"
                                                            :githubdetailscheck="githubdetailscheck"
                                                            :validfilename_msg="validfilename_msg"
                                                            :validname_msg="validname_msg" :validpath_msg="validpath_msg"
                                                            :datacheck="datacheck" :response_message="response_message"
                                                            :gitaccesscheck="gitaccesscheck" :github_access="github_access"
                                                            :datatypeitem="datatypeitem"
                                                            :micronamecheck="micronamecheck" :removedetails= "removedetails" />
                                                    </v-stepper-content>
                                                    <v-stepper-content step="2">
                                                        <v-col cols="12" class="px-10">
                                                            <h3 class="px-3">{{ labels.APID }}</h3>
                                                        </v-col>
                                                        <regApiDetails :regmicroalldata="regmicroalldata"
                                                            :validForm="validForm" :labels="labels"
                                                            :micronamecheck="micronamecheck"
                                                            :response_message="response_message"
                                                            :githubdetailscheck="githubdetailscheck"
                                                            :validfilename_msg="validfilename_msg" />
                                                    </v-stepper-content>
                                                    <v-stepper-content step="3">
                                                        <v-col cols="12" class="px-10">
                                                            <h3 class="px-3">{{ labels.DOCD }}</h3>
                                                        </v-col>
                                                        <regDocsDetails :regmicroalldata="regmicroalldata"
                                                            :validForm="validForm" :labels="labels"
                                                            :datatypeitem="datatypeitem" />
                                                    </v-stepper-content>
                                                </v-stepper-items>
                                            </v-stepper>
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="px-10 py-6 justify-center" style="display: flex !important;">
                                        <v-btn class="nocap" large text @click="clearObjectData" v-if="currentStep == 1">
                                            {{ labels.CANCEL }}
                                        </v-btn>
                                        <v-btn class="nocap mx-5" large text @click="backStep()" v-if="currentStep > 1">
                                            {{ labels.BACK }}
                                        </v-btn>
                                        <v-btn :disabled="regmicroalldata.api_details.length == 0 && currentStep == 2"
                                            class="nocap mx-5" large @click="nextStep()" v-if="currentStep < 3"
                                            color="primary">
                                            {{ labels.NEXT }}
                                        </v-btn>
                                        <v-btn class="nocap mx-5" large @click="save()" v-if="currentStep == 3"
                                            color="primary">
                                            {{ labels.SAVE }}
                                        </v-btn>
                                        <v-checkbox v-if="currentStep == 3" class="mt-2 ml-4 mr-4"
                                            v-model="regmicroalldata.auto_deploy" label="Autodeploy"
                                            value="yes"></v-checkbox>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-form>
                    </v-row>
                    <!----------------------------- end------------------------------>

                    <!--------------------- Success Register MS pop up  ---------------->

                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="successms"
                            persistent max-width="388px" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                                    style="margin-bottom: -19px">
                                    <v-img style="z-index: 1;height: 40px;" alt="sentient logo"
                                        class="d-flex align-center justify-center" src="@/assets/tick-mark1.svg"
                                        contain></v-img>
                                </v-card-title>
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-4">{{ labels.SUCCESS }}</div>
                                    </v-card-title>
                                    <p v-if="response_msg">{{ response_msg }}</p>
                                    <v-card-actions>
                                        <div class="text-center">
                                            <v-btn class="nocap" color="primary" @click="successPopupClose">{{ labels.DON }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!----------------------- End---------------------------------->

                    <!--------------------input item pop up-------------------------->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent"
                            v-model="isnotavailableinput" persistent max-width="350" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-2">Input details required</div>
                                    </v-card-title>
                                    <p>Route {{ isnotavailableinputitem }} requires input details</p>
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap" color="error" @click="successPopupClose">{{ labels.OK }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!----------------------- end --------------------------->

                    <!--------------------storage check-------------------------->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent"
                            v-model="invalidstorage" persistent max-width="350" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-2">Insufficient Storage</div>
                                    </v-card-title>
                                    <p>Storage is not enough</p>
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap" color="error" @click="successPopupClose">{{ labels.OK }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!----------------------- end --------------------------->

                    <!------------------------- File uploading pop up --------------------------->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="uploadms"
                            persistent max-width="388px" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-2">{{ file_name }} uploading...</div>
                                    </v-card-title>
                                    <v-progress-linear v-if="upload_count > 0" v-model="upload_count" color="primary"
                                        height="20px">
                                        <template v-slot:default="{ value }">
                                            <strong>{{ Math.ceil(value) }}%</strong>
                                        </template>
                                    </v-progress-linear>
                                    <v-img style="z-index: 1; height: 40px;" alt="sentient logo"
                                        class="mt-2 d-flex align-center justify-center" src="@/assets/loading.gif"
                                        contain></v-img>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!---------------------------- End --------------------------->

                    <!-------------------------------  Error pop up  -------------->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="errorms"
                            persistent max-width="388" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-2">{{ labels.INVALID }}</div>
                                    </v-card-title>
                                    <p v-if="response_msg">{{ response_msg }}</p>
                                    <v-card-actions>
                                        <div class="text-center mt-4" v-if="response_msg !='Please index and deploy'">
                                            <v-btn class="nocap" color="error" @click="successPopupClose">{{ labels.OK }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                    <v-card-actions>
                                        <div class="text-center mt-4" v-if="response_msg=='Please index and deploy'">
                                            <v-btn class="nocap" color="error" @click="successPopupClose">Ok
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!----------------------------- End ---------------------------->

                    <!---------------------------Githun access success----------------------------------- -->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent"
                            v-model="githubaccesssuccess" persistent max-width="388px" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                                    style="margin-bottom: -19px">
                                    <v-img style="z-index: 1;height: 40px;" alt="sentient logo"
                                        class="d-flex align-center justify-center" src="@/assets/tick-mark1.svg"
                                        contain></v-img>
                                </v-card-title>
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-4">{{ labels.SUCCESS }}</div>
                                    </v-card-title>
                                    <p v-if="response_msg">{{ response_msg }}</p>
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap" color="primary" @click="successPopupClose">{{ labels.DON }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!----------------------------- End ---------------------------->
                    <!---------------------------Githun access success----------------------------------- -->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent"
                            v-model="microtype" persistent max-width="388px" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <h2 class="mt-4">Microservice Type</h2>
                                    <div class = "closeicon-right">
                                    <v-icon  @click.prevent = "successPopupClose" class = "text-right">
                                        mdi-close-circle-outline
                                    </v-icon>
                                    </div>
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <v-radio-group row class="pl-10 mt-2" v-model="regmicroalldata.microservice_type">
                                            <v-radio row label = "Data Microservice" value = "data microservice"></v-radio>
                                            <v-radio row label = "Non Data Microservice" value = "non data microservice"></v-radio>
                                        </v-radio-group>
                                    </v-card-title>
                                    <v-card-actions>
                                        <div class="text-center mb-3">
                                            <v-btn class="nocap" color="primary" @click="regMicroserve">Register
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!----------------------------- End ---------------------------->

                    <!--------------------Guthub Access denied-------------------------->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent"
                            v-model="githubaccessfailure" persistent max-width="350" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-2">Github Access Denied</div>
                                    </v-card-title>
                                    <p v-if="response_msg">{{ response_msg }}</p>
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap" color="error" @click="successPopupClose">{{ labels.OK }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!----------------------- end ---------------------->

                    <!------------------- Version manual update popup start------------------>
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent"
                            v-model="otherversion" persistent max-width="400" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-text class="text-center white rounded-lg" style="height: 250px">
                                    <v-card-title class="text-h8 text-bold d-flex justify-center">
                                        <div class="mt-2">Please enter the version</div>
                                    </v-card-title>
                                    <v-col>
                                        <v-text-field v-model="addversion" outlined
                                            placeholder="Enter the version..."></v-text-field>
                                    </v-col>
                                    <v-card-actions>
                                        <div class="text-center mt-0">
                                            <v-btn v-if="!loaders" class="nocap mx-2" text @click="otherversion = false">{{
                                                labels.CANCEL }}</v-btn>
                                            <v-btn v-if="!loaders" class="nocap mx-2" color="primary"
                                                :disabled="addversion == ''" @click="updateversion()">save
                                            </v-btn>
                                            <v-progress-circular :width="3" :size="20" color="red" indeterminate
                                                class="ml-1" v-if="loaders"></v-progress-circular>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!----------------------------- end ------------------------>

                    <!--------------------- Microservice delete pop up ---------------------------->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="deletems"
                            persistent max-width="388" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                                    style="margin-bottom: -19px">
                                    <v-avatar color="error" size="40">
                                        <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                                    </v-avatar>
                                </v-card-title>
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-4">{{ labels.DELCON }}</div>
                                    </v-card-title>
                                    {{ labels.DEL_CON }}
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap mx-5" text @click="deletems = false">{{
                                                labels.CANCEL }}</v-btn>
                                            <v-btn class="nocap mx-5" color="error"
                                                @click="statusMicroservices('Delete', '')">{{ labels.DEL }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!------------------------- end ----------------------->

                    <!--------------------- Microservice Approve pop up ---------------------------->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="approvems"
                            persistent max-width="388" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                                    style="margin-bottom: -19px">
                                    <v-img style="z-index: 1;height: 40px;" alt="sentient logo"
                                        class="d-flex align-center justify-center" src="@/assets/tick-mark1.svg"
                                        contain></v-img>
                                </v-card-title>
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-4">{{ labels.APPCON }}</div>
                                    </v-card-title>
                                    {{ labels.APP_CON }}
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap mx-5" text @click="approvems = false">{{
                                                labels.CANCEL }}</v-btn>
                                            <v-btn class="nocap mx-5" color="primary"
                                                @click="statusMicroservices('Approve', ''), approvems = false">{{
                                                    labels.APPROVE }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!------------------------- end ------------------------------->

                    <!--------------------- Microservice reject pop up ---------------------------->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="rejectms"
                            persistent max-width="450" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                                    style="margin-bottom: -19px">
                                    <v-avatar color="warning" size="40">
                                        <v-icon class="white--text text-h5">mdi-close-circle</v-icon>
                                    </v-avatar>
                                </v-card-title>
                                <v-card-text class="text-center white rounded-lg" style="height: 300px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-4">{{ labels.REJCON }}</div>
                                    </v-card-title>
                                    {{ labels.REJ_CON }}
                                    <v-col>
                                        <v-textarea v-model="rejection" auto-grow outlined placeholder="Reject comments..."
                                            rows="3" row-height="10"></v-textarea>
                                    </v-col>
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap mx-2" text @click="rejectms = false">{{
                                                labels.CANCEL }}</v-btn>
                                            <v-btn class="nocap mx-2" color="warning" :disabled="rejection == ''"
                                                @click="statusMicroservices('Reject', rejection)">{{ labels.REJECT }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!---------------------- End -------------------------->

                    <!-- -------Register Microservice Detail Pages --------->
                    <v-dialog v-model="regmsdetaildialog" width="1000" scrollable>
                        <registerMsDetailPage :regmicroalldata="regmicroalldata" :labels="labels" :tabstatus="tabstatus"
                            :regmsdetaildialog="regmsdetaildialog" :regmseditdialog="regmseditdialog"
                            @closeMsDetailDialog="closeMsDetailDialog" @editDialog="closeDialog" />
                    </v-dialog>
                    <!----------------------- End ------------------------>

                    <!-- -------Register Microservice Edit Pages --------->
                    <v-dialog v-model="regmseditdialog" width="1000" scrollable>
                        <registerMsEditPage :regmicroalldata="regmicroalldata" :validForm="validForm"
                            :addothersversion="addothersversion" :datatypeitem="datatypeitem" :github_access="github_access"
                            :labels="labels" :datacheck="datacheck" :validname_msg="validname_msg"
                            :gitaccesscheck="gitaccesscheck" :githubdetailscheck="githubdetailscheck"
                            :validfilename_msg="validfilename_msg" :validpath_msg="validpath_msg" 
                            :baseversionitem="baseversionitem" :response_message="response_message" :removedetails="removedetails"
                            @closeRegMsEdit="closeEdit" @regMsEditSave="save()" />
                    </v-dialog>
                    <!-------------------------End ------------------------->
                </div>
            </v-content>
        </div>
        <loaderimg v-if="loader" />
    </div>
</template> 
<script>

import loaderimg from "./loaderimg.vue";
import AWS from 'aws-sdk';
import API_Util from "../services/util/API_Util.js";
import headline from "./headline.vue";
import registerMsDetailPage from "./registerMsDetailPage.vue";
import registerMsEditPage from "./registerMsEditPage.vue";
import regMsDetails from "./regMsPopUp/regMsDetails.vue";
import regApiDetails from "./regMsPopUp/regApiDetails.vue";
import regDocsDetails from "./regMsPopUp/regDocsDetails.vue";

export default {
    components: {
        loaderimg,
        headline,
        registerMsDetailPage,
        registerMsEditPage,
        regMsDetails,
        regApiDetails,
        regDocsDetails,
    },
    name: "registerMicroservice",
    data: () => ({
        response_message: "",
        mspop: false,
        search_label: "",
        owner: "",
        user: "",
        apiKey: "",
        uregms: "",
        uapprove: "",
        ureject: "",
        udelete: "",
        uaccess: "",
        hover: false,
        selectedmicro: [],
        regmslisting: {},
        regmsdetaildialog: false,
        regmseditdialog: false,
        dataregmseditdialog : false,
        regmicroservices: false,
        response_msg: "",
        response_status: "",
        loading: false,
        isnotavailableinput: false,
        isnotavailableinputitem: "",
        searchText: "",
        microtype:false,
        deletems: false,
        approvems: false,
        rejectms: false,
        successms: false,
        uploadms: false,
        errorms: false,
        githubaccesssuccess: false,
        githubaccessfailure: false,
        readMore: false,
        response: "",
        datacheck: {},
        version: "",
        rejection: "",
        validfilename_msg: false,
        validpath_msg: false,
        validname_msg: false,
        validForm: true,
        otherversion: false,
        addversion: "",
        datatypeitem: [],
        baseversionitem: [],
        invalidstorage: false,
        progress_msg: false,
        github_access :{},
        regmicroalldata: {
            id: 0,
            name: "",
            description: "",
            deployment_type: "new",
            resource_cpu: "",
            resource_memory: "",
            env_variables: [],
            enable_gpu: "no",
            port: 5000,
            endpoint: "",
            teamspace_code: "",
            microservice_code: "",
            version: "",
            docker_image_url: "",
            docker_image_file: "",
            docker_image_source: "",
            docker_image_status: "",
            microservice_type :"data microservice",
            docs_yaml_url: "",
            docs_yaml: "",
            update_resource_memory: "",
            update_resource_cpu: "",
            update_resource_gpu: "",
            auto_deploy: "no",
            major: "1",
            minor: "0",
            patch: "0",
            oldmajor: "1",
            oldminor: "0",
            oldpatch: "0",
            deploy_type: "",
            result_type:"",
            available_storage: "0",
            index_property:[],
            index_image_file:"",
            index_image_url:"",
            index_status:"",
            index_message:"",
            index_type:"new",
            model_available: "no",
            model_image_source: "",
            model_iamge_url: "",
            model_image_file: "",
            model_image_status: "",
            apidetails_desc: "",
            input_details: [],
            volumes_path:"",
            volumes_path_variable:"",
            docs: "yes",
            concat_version: "",
            newversion: "",
            api_route: { 
                modelimage: [], 
                githubrepo : [],
                api_ready :[] 
            },
            api_details: { 
                modelimage: [], 
                githubrepo: [],
                api_ready:[] 
            },
            github_repo_detail: {
                requirementspath: "requirements.txt",
                githuburl: "",
                baseversion: "python:3.9",
                filename :""
            },
            apidetails: { 
                filename: "",
                entrypoint: "", 
                apiroute: "/",
                result_type:"",
                description:"",
                endpoint :""
            },
        },
        items: [
            {
                text: "Owners",
                disabled: false,
                href: "/ownerlisting",
            },
            {
                text: "",
                disabled: false,
                href: "",
            },
            {
                text: "Deployments - Register Microservice",
                disabled: true,
                href: "",
            },
        ],
        stepperData: [
            {
                title: "Microservice Details",
                id: 0
            },
            {
                title: "API Details",
                id: 1
            },
            {
                title: "Documentation Details",
                id: 2
            },

        ],
        loader: false,
        loaders: false,
        ownerType: "",
        labels: {},
        language: "EN",
        ownerDrop: "",
        ownerDesc: "",
        currentStep: 1,
        isSuperAdmin: localStorage.getItem('owner') == 1,
        tabstatus: "pending",
        upload_count: 0,
        file_name: "",
    }),

    created() {
        var sessionObj = this.$session.get("user_session");
        this.uaccess = sessionObj.session.uAccess;
        this.uregms = this.uaccess.REGISTERMS;
        this.uapprove = this.uaccess.REGMSAPPROVE;
        this.ureject = this.uaccess.REGMSREJECT;
        this.udelete = this.uaccess.REGMSDELETE;
        this.autodeploy = this.uaccess.AUTODEPLOY;
        this.apiKey = sessionObj.session.userApiKey;
        this.ownerName = sessionObj.session.ownerName;
        this.owner_Id = sessionObj.session.owner;
        this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
        this.selectedOwner = localStorage.getItem("ownerName");
        this.owner = localStorage.getItem("owner");
        this.user = sessionObj.session.id;
        this.orgId = localStorage.getItem("orgId");
        this.ownerDesc = localStorage.getItem('ownerDesc');
        localStorage.setItem('orgId', this.owner);
        this.ownerType = sessionObj.session.ownerType;
        this.ownerCode = sessionObj.session.ownerCode;
        this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
        this.items[1].href = "/ownersettings";
        this.getownersettings();
        this.getFlexi();
        this.getheader("pending");
        this.getRegMicroserviceList();
        this.dataCheck();
        this.getpythonversion("new", "");
        this.getdatatype();
        this.teamspace_code();
    },
    methods: {
        makeSelectedEmpty() {
            this.selectedmicro = [];
        },
        successPopupClose() {
            this.successms = false;
            this.uploadms = false;
            this.errorms = false;
            this.githubaccesssuccess = false;
            this.githubaccessfailure = false;
            this.isnotavailableinput = false;
            this.invalidstorage = false;
            this.microtype=false;
        },
        changeOwner(obj) {
            this.loader = true;
            localStorage.setItem("owner", obj.ownerId);
            localStorage.setItem("ownerName", obj.ownerName);
            localStorage.setItem("orgId", obj.id);
            localStorage.setItem('ownerType', obj.ownerType);
            this.selectedOwner = obj.ownerName;
            this.ownerDesc = obj.desc;
            localStorage.setItem('ownerDesc', obj.desc);
            this.$emit('ownerchange', obj.ownerType);
            this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
            this.isSuperAdmin = localStorage.getItem('owner') == 1;
            this.getLangList();
            this.getFlexyLabelList();
        },
        clearObjectData() {
            this.regmicroservices = false;
            this.clearMicroAllData();
        },
        activateReadMore(item,tabstatus) {
            var index =this.regmslisting[tabstatus].indexOf(item)
            this.regmslisting[tabstatus][index]['readmore']= true;
        },
        activateReadLess(item,tabstatus) {
            var index=this.regmslisting[tabstatus].indexOf(item)
            this.regmslisting[tabstatus][index]['readmore']= false;
            this.readMore = false;
        },
        changeStepper() {
            this.stepperData = [
                {
                    title: "Microservice Details",
                    id: 0
                },
                {
                    title: "API Details",
                    id: 1
                },
                {
                    title: "Documentation Details",
                    id: 2
                },

            ]
        },
        clearMicroAllData() {
            this.regmicroalldata = {
                id: 0,
                name: "",
                description: "",
                deployment_type: "new",
                resource_cpu: 0.2,
                resource_memory: 0.5,
                env_variables: [],
                port: 5000,
                endpoint: "",
                version: "",
                docker_image_url: "",
                docker_image_file:"",
                docker_image_source: "",
                docker_image_status: "",
                docs_yaml: "",
                docs_yaml_url: "",
                enable_gpu: "no",
                update_resource_memory: "",
                update_resource_cpu: "",
                update_resource_gpu: "",
                major: "1",
                minor: "0",
                patch: "0",
                apidetails_desc: "",
                deploy_type: "modelimage",
                input_details: [],
                docs: "yes",
                auto_deploy: "no",
                oldmajor: "1",
                oldminor: "0",
                oldpatch: "0",
                addversion: "",
                model_available: "no",
                model_image_source: "",
                model_image_url: "",
                model_image_file: "",
                model_image_status: "",
                index_property:[],
                index_image_file:"",
                index_image_url:"",
                index_status:"",
                index_message:"",
                index_type:"new",
                volumes_path:"",
                result_type:"getresults",
                volumes_path_variable:"",
                teamspace_code: this.regmicroalldata.teamspace_code,
                microservice_code: this.regmicroalldata.name,
                available_storage: this.regmicroalldata.available_storage,
                microservice_type : this.regmicroalldata.microservice_type,
                api_route: { 
                    modelimage: [], 
                    githubrepo : [],
                    api_ready :[] 
                },
                api_details: { 
                    modelimage: [], 
                    githubrepo: [],
                    api_ready:[] 
                },
                github_repo_detail: {
                    requirementspath: "requirements.txt",
                    githuburl: "",
                    baseversion: "python:3.9",
                    filename:""
                },
                apidetails: { 
                    filename: "",
                    entrypoint: "", 
                    apiroute: "/",
                    result_type:"",
                    description:"",
                    endpoint :"",
                },
            };
            this.response_message = "";
            this.response_status = "";
            this.currentStep = 1;
            this.validForm = true;
            this.validfilename_msg = false;
            this.validpath_msg = false;
            this.validname_msg = false;
            this.loaders = false;
            this.githubaccesssuccess = false;
            this.githubaccessfailure = false;
        },
        regMicroserve() {
            this.microtype = false;
            this.regmicroservices = true;
            this.clearMicroAllData();
        },
        microserviceType(){
            this.microtype = true;
        },
        githubdetailscheck(data,type) { 
            if (type == "filename"){
                var patt = /^[\w]+\.py$/;
                if (data != "") {
                    var filenamecheck = patt.test(data);
                    if (!filenamecheck) {
                        this.validfilename_msg = true;
                    } else {
                        this.validfilename_msg = false;
                    }
                }
            }else if(type == "requirement"){
                patt = /^[\w-]+\.txt$/;
                if (data != "") {
                    var requirementcheck = patt.test(data);
                    if (!requirementcheck) {
                        this.validpath_msg = true;
                    } else {
                        this.validpath_msg = false;
                    }
                }
            }else if(type=="name"){
                patt = /^[A-Za-z0-9][A-Za-z0-9- ]{0,62}[A-Za-z0-9]$/;
                if (data != "") {
                    var namechecks = patt.test(data);
                    if (!namechecks) {
                        this.validname_msg = true;
                    } else {
                        this.validname_msg = false;
                    }
                }
            }
            

        },
        findText(tab) {
            var listdata = localStorage.getItem("temp_reg");
            if (this.searchText != '') {
                this.regmslisting[tab] = JSON.parse(listdata)[tab].filter(list => list.fun_name.toUpperCase().indexOf(this.searchText.toUpperCase()) != -1);
            } else {
                this.regmslisting[tab] = JSON.parse(listdata)[tab];
            }
        },
        resetSearch(tab) {
            this.searchText = '';
            var listdata = localStorage.getItem("temp_reg");
            this.regmslisting[tab] = JSON.parse(listdata)[tab];

        },
        teamspace_code() {
            if (this.owner_Id > 1) {
                this.regmicroalldata.teamspace_code = this.ownerCode;
            } else {
                this.regmicroalldata.teamspace_code = localStorage.getItem("ownerCode");
            }
        },
        selected_micro(item) {
            if (this.tabstatus == "pending") {
                this.selectedmicro = [];
                if (localStorage.getItem("current_id") != undefined) {
                    if (parseInt(localStorage.getItem("current_id")) != item.fun_id) {
                        this.selectedmicro.push(item);
                        localStorage.setItem("current_id", item.fun_id);
                    } else {
                        localStorage.removeItem("current_id");
                    }
                } else {
                    localStorage.setItem("current_id", item.fun_id);
                    this.selectedmicro.push(item);
                }
            }
        },
        closeEdit(status) {
            this.regmseditdialog = status;
        },
        closeMsDetailDialog(value) {
            this.regmsdetaildialog = value;
        },
        closeDialog(value) {
            this.regmsdetaildialog = value;
            this.regmseditdialog = true;
        },
        addothersversion(data) {
            this.otherversion = false;
            if (data == "others") {
                this.otherversion = true;
                this.addversion = "";
            }
        },
        removedetails(){
            this.regmicroalldata.github_repo_detail = { requirementspath: "requirements.txt", githuburl: "", baseversion: "python:3.9",filename:""};
            this.regmicroalldata.apidetails = { filename: "",entrypoint: "", apiroute: "/",result_type:"",description:"",endpoint :""};
        },
        async gitaccesscheck(dataitem) {
            this.githubaccesssuccess = false;
            this.githubaccessfailure = false;
            if (this.regmicroalldata.github_repo_detail.githuburl != "") {
                var data = { "githuburl": dataitem }
                await API_Util.URLS.axios
                    .post(API_Util.URLS.gitaccesscheck, data, {
                        headers: {
                            'Content-Type': "multipart/form-data",
                            "x-api-key": this.apiKey,
                        },
                    })
                    .then((response) => {
                        this.github_access = response.data;
                        if (this.github_access.status_code != 200) {
                            this.githubaccessfailure = true;
                            this.response_msg = this.github_access.content.Message;
                            // this.validForm = false;
                        } else {
                            this.githubaccesssuccess = true;
                            this.response_msg = this.github_access.content.Message;
                            this.validForm = true;
                        }
                    })
                    .catch((err) => console.log(err));
            }

        },
        async dockerUpload(item) {
            var file = item.docker_image_file;
            const s3 = new AWS.S3({
                accessKeyId: "AG26PV05G4CIPMTXJ9K1",
                secretAccessKey: "aRtwZwDq3YOBKAUkzwPhO2Kgzg63enQXfkQU6g7a",
                endpoint: "https://s3.ap-southeast-1.wasabisys.com",
                s3ForcePathStyle: true,
            });
            const bucketName = "docker-images-tar";
            const objectKey = this.regmicroalldata.teamspace_code+"/"+this.regmicroalldata.microservice_code+".tar";
            try {
                const initiateResponse = await s3.createMultipartUpload({
                    Bucket: bucketName,
                    Key: objectKey,
                }).promise();

                const uploadId = initiateResponse.UploadId;
                const chunkSize = 250 * 1024 * 1024;
                let start = 0;
                let partNumber = 1;

                while (start < file.size) {
                    const end = Math.min(start + chunkSize, file.size);
                    const chunk = file.slice(start, end);

                    const uploadPartResponse = await s3.uploadPart({
                        Bucket: bucketName,
                        Key: objectKey,
                        PartNumber: partNumber,
                        Body: chunk,
                        UploadId: uploadId,
                    }).promise();
                    console.log(`Uploaded part ${partNumber}: ${uploadPartResponse.ETag}`);
                    start = end;
                    partNumber++;
                }

                const listPartsResponse = await s3.listParts({
                    Bucket: bucketName,
                    Key: objectKey,
                    UploadId: uploadId,
                }).promise();

                const parts = listPartsResponse.Parts.map(part => ({
                    PartNumber: part.PartNumber,
                    ETag: part.ETag,
                }));

                const response = await s3.completeMultipartUpload({
                    Bucket: bucketName,
                    Key: objectKey,
                    UploadId: uploadId,
                    MultipartUpload: { Parts: parts },
                }).promise();
                console.log('Multipart upload completed:', response);
                if(item.model_available == "yes" && item.model_image_url !="" && item.model_image_source == ""){
                    this.modelUpload(item)
                }else{
                    var data = { "id": item.id, "auto_deploy": item.auto_deploy, "approval_status": "Pending", "docker_image_status":"Successfully Uploaded", "microservice_code": item.microservice_code, "docker_image_source":"Success"}
                    API_Util.URLS.axios
                        .post(API_Util.URLS.updatedetails, data, {
                            headers: { "x-api-key": this.apiKey },
                        })
                        .then((response) => {
                            var id = response.data.id
                            this.getRegMicroserviceList()
                            if(item.auto_deploy == "yes"){
                                this.autoDeploy(id)
                            }
                        })
                        .catch((err) => console.log(err));
                }
                
            } catch (error) {
                console.error('Error uploading file:', error);
                this.regmicroalldata.docker_image_source = "Error"
                data = { "id": item.id,"approval_status": "Rejected","microservice_code":item.microservice_code,"docker_image_source":""}
                    API_Util.URLS.axios
                        .post(API_Util.URLS.updatedetails, data, {
                            headers: { "x-api-key": this.apiKey },
                        })
                        .then(() => {
                        })
                        .catch((err) => console.log(err));
            }
        },
        async yamlUpload(file) {
            this.uploadms = true;
            this.upload_count = 0;
            this.file_name = "Docs Yaml"
            const onUploadProgress = (event) => {
                const percentage = Math.round((100 * event.loaded) / event.total);
                this.upload_count = percentage;
            };
            const fd = new FormData();
            fd.append("file", file);
            fd.append("type", "yaml");
            fd.append("owner_code", this.regmicroalldata.teamspace_code);
            fd.append("mscode", this.regmicroalldata.microservice_code);
            await API_Util.URLS.axios
                .post(API_Util.URLS.fileuploads, fd, {
                    headers: {
                        'Content-Type': "multipart/form-data",
                        "x-api-key": this.apiKey,
                    },
                    onUploadProgress,
                })
                .then((response) => {
                    this.regmicroalldata.docs_yaml = response.data.url;
                    this.registerData();
                })
                .catch((err) => console.log(err));
        },
        async modelUpload(item) {
            var file = item.model_image_file
            const s3 = new AWS.S3({
                accessKeyId: "AG26PV05G4CIPMTXJ9K1",
                secretAccessKey: "aRtwZwDq3YOBKAUkzwPhO2Kgzg63enQXfkQU6g7a",
                endpoint: "https://s3.ap-southeast-1.wasabisys.com",
                s3ForcePathStyle: true,
            });
            const bucketName = "register-microservice-models";
            const objectKey = this.regmicroalldata.teamspace_code+"/"+this.regmicroalldata.microservice_code+".zip";
            try {
                const initiateResponse = await s3.createMultipartUpload({
                    Bucket: bucketName,
                    Key: objectKey,
                }).promise();

                const uploadId = initiateResponse.UploadId;
                const chunkSize = 250 * 1024 * 1024;
                let start = 0;
                let partNumber = 1;
                while (start < file.size) {
                    const end = Math.min(start + chunkSize, file.size);
                    const chunk = file.slice(start, end);

                    const uploadPartResponse = await s3.uploadPart({
                        Bucket: bucketName,
                        Key: objectKey,
                        PartNumber: partNumber,
                        Body: chunk,
                        UploadId: uploadId,
                    }).promise();
                    console.log(`Uploaded part ${partNumber}: ${uploadPartResponse.ETag}`);
                    start = end;
                    partNumber++;
                }

                const listPartsResponse = await s3.listParts({
                    Bucket: bucketName,
                    Key: objectKey,
                    UploadId: uploadId,
                }).promise();

                const parts = listPartsResponse.Parts.map(part => ({
                    PartNumber: part.PartNumber,
                    ETag: part.ETag,
                }));

                const response = await s3.completeMultipartUpload({
                    Bucket: bucketName,
                    Key: objectKey,
                    UploadId: uploadId,
                    MultipartUpload: { Parts: parts },
                }).promise();

                console.log('Multipart upload completed:', response);
                if(item.deploy_type == "modelimage" && item.docker_image_url != "" && item.docker_image_source == ""){
                    var data = { "id": item.id, "auto_deploy": item.auto_deploy, "approval_status": "Pending" ,"microservice_code":item.microservice_code, "model_image_status":"Successfully Uploaded","model_image_source":"Success","docker_image_status":"Successfully Uploaded","docker_image_source":"Success"}
                }else{
                    data = { "id": item.id, "auto_deploy": item.auto_deploy, "approval_status": "Pending" ,"microservice_code":item.microservice_code, "model_image_status":"Successfully Uploaded","model_image_source":"Success"}
                }
                API_Util.URLS.axios
                    .post(API_Util.URLS.updatedetails, data, {
                        headers: { "x-api-key": this.apiKey },
                    })
                    .then((response) => {
                        var id = response.data.id
                        this.getRegMicroserviceList()
                        if(item.auto_deploy == "yes"){
                            this.autoDeploy(id)
                        }
                    })
                    .catch((err) => console.log(err));
            } catch (error) {
                console.error('Error uploading file:', error);
                data = { "id": item.id,"approval_status": "Rejected","microservice_code":item.microservice_code,"mode_image_source":""}
                    API_Util.URLS.axios
                        .post(API_Util.URLS.updatedetails, data, {
                            headers: { "x-api-key": this.apiKey },
                        })
                        .then(() => {
                        })
                        .catch((err) => console.log(err));
            }
        },
        getownersettings() {
            var data = { "owner_id": localStorage.getItem("owner") }
            API_Util.URLS.axios
                .post(API_Util.URLS.listOwnerSettings, data, {
                    headers: { "x-api-key": this.apiKey },
                })
                .then((response) => {
                    this.regmicroalldata.available_storage = response.data.avail_storage
                })
                .catch((err) => console.log(err));
        },
        async openMsDetailPage(item, tabstatus) {
            if (item.fun_model_status != "Deployment Successful" && tabstatus == "approved") {
                this.progress_msg = true;
            } else {
                var data = { id: item.fun_id }
                let response = await API_Util.URLS.axios
                    .post(API_Util.URLS.viewdetails, data,
                        {
                            headers: { "x-api-key": this.apiKey }
                        }
                    )
                    .then((response) => response.data.details)
                    .catch((err) => console.log(err));
                this.regmicroalldata = response;
                this.regmicroalldata.oldmajor = this.regmicroalldata.major;
                this.regmicroalldata.oldminor = this.regmicroalldata.minor;
                this.regmicroalldata.oldpatch = this.regmicroalldata.patch;
                this.regmicroalldata.apidetails = { filename: "", entrypoint: "", apiroute: "/", result_type:"", description:"", endpoint :""}
                this.regmicroalldata.concat_version = Number(this.regmicroalldata.major + this.regmicroalldata.minor + this.regmicroalldata.patch)
                this.regmicroalldata = Object.fromEntries(
                    Object.entries(this.regmicroalldata).map(([key, value]) => [key.toLowerCase(), value])
                );
                this.regmsdetaildialog = true;
            }
        },
        async getRegMicroserviceList() {
            var data = { owner_id: localStorage.getItem("owner") }
            let response = await API_Util.URLS.axios
                .post(API_Util.URLS.getregmicrolist, data,
                    {
                        headers: { "x-api-key": this.apiKey }
                    }
                )
                .then((response) => response.data.results)
                .catch((err) => console.log(err));
            if (response.status == "Success") {
                this.regmslisting = response.listdata;
            } else {
                this.regmslisting['pending'] = [];
                this.regmslisting['approved'] = [];
                this.regmslisting['rejected'] = [];
                this.regmslisting['drafts'] = [];
            }
            localStorage.setItem("temp_reg", JSON.stringify(response.listdata));
        },
        async refreshlist() {
            this.getRegMicroserviceList()
        },
        async dataCheck() {
            var data = { owner_id: localStorage.getItem("owner") }
            let response = await API_Util.URLS.axios
                .post(API_Util.URLS.datacheck, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "x-api-key": this.apiKey,
                    },
                })
                .then((response) => response)
                .catch((err) => console.log(err));
            this.datacheck = response.data;
        },
        getheader(tabstatus) {
            this.header = [];
            if (tabstatus == "pending" || this.tabstatus == "pending") {
                this.header.push({ text: "Name", align: "start", sortable: true, value: "fun_name", width: "20%", fixed: true })
                this.header.push({ text: "Type", value: "fun_type", width: "10%", fixed: true })
                this.header.push({ text: "MS Type", value: "mstype", width: "20%", fixed: true })
                this.header.push({ text: "Description", value: "fun_desc", width: "50%", fixed: true })
            } else if (tabstatus == "approved") {
                this.header.push({ text: "Name", align: "start", sortable: true, value: "fun_name", width: "20%", fixed: true })
                this.header.push({ text: "Type", value: "fun_type", width: "10%", fixed: true })
                this.header.push({ text: "Function Code", value: "fun_ms_code", width: "20%", fixed: true })
                this.header.push({ text: "End Point", value: "api_details", width: "30%", fixed: true })
                this.header.push({ text: "Model Status", value: "fun_model_status", width: "20%", fixed: true })
            } else if (tabstatus == "rejected") {
                this.header.push({ text: "Name", align: "start", sortable: true, value: "fun_name", width: "20%", fixed: true })
                this.header.push({ text: "Type", value: "fun_type", width: "10%", fixed: true })
                this.header.push({ text: "Status", value: "fun_model_status", width: "30%", fixed: true })
                this.header.push({ text: "Error Details", value: "fun_error_msg", width: "30%", fixed: true })
            } else if (tabstatus == "drafts") {
                this.header.push({ text: "Name", align: "start", sortable: true, value: "fun_name", width: "50%", fixed: true })
                this.header.push({ text: "Type", value: "fun_type", width: "50%", fixed: true })
                // this.header.push({ text: "File Status", value: "model_image_status", width: "60%", fixed: true })
            }
            localStorage.removeItem("current_id");
        },
        getFlexi() {
            API_Util.URLS.axios
                .post(
                    API_Util.URLS.getlblbyscr,
                    {
                        owner_id: localStorage.getItem("owner"),
                        scrId: "REG_MS",
                        lang: this.language,
                    },
                    { headers: { "x-api-key": this.apiKey } }
                )
                .then((response) => {
                    this.labels = response.data.labels;
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        async micronamecheck(type) {
            if (type == "name"){
                var data = { owner: this.owner, msName: this.regmicroalldata.name, id: this.regmicroalldata.id, type: type }
            }else{
                data = { owner: this.owner, funCode: this.regmicroalldata.teamspace_code + this.regmicroalldata.microservice_code, id: this.regmicroalldata.id, type:type, urlCode: this.regmicroalldata.microservice_code.toLowerCase()}
            }
            
            await API_Util.URLS.axios
                .post(API_Util.URLS.checkmsdup, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "x-api-key": this.apiKey,
                    },
                })
                .then((response) => {
                    if (response.data.message == "NAME OR CODE EXIST") {
                        this.validForm = false;
                        this.response_message = "Invalid Input - Microservice "+ type +" already exists";
                        this.response_status = "Invalid";
                    } else {
                        this.validForm = true;
                        this.response_message = "";
                        this.response_status = "";
                    }
                })
                .catch((err) => console.log(err));

        },
        async nextStep() {
            if (this.currentStep == 1) {
                if (this.regmicroalldata.name == "" || this.validname_msg) {
                    this.validForm = false;
                } else if (this.regmicroalldata.description == "") {
                    this.validForm = false;
                } else if (this.regmicroalldata.microservice_type =='non data microservice' && this.regmicroalldata.deploy_type == "modelimage" && this.regmicroalldata.docker_image_url == "") {
                    this.validForm = false;
                } else if (this.regmicroalldata.microservice_type =='non data microservice' && this.regmicroalldata.deploy_type == "githubrepo" && ((this.regmicroalldata.github_repo_detail.githuburl == "" || this.github_access.status_code != 200)||this.regmicroalldata.github_repo_detail.baseversion == "" || (this.regmicroalldata.github_repo_detail.requirementspath == "" || this.validpath_msg))) {
                    this.validForm = false;
                } else if (this.regmicroalldata.microservice_type =='non data microservice' && this.regmicroalldata.deploy_type == "api_ready" && ((this.regmicroalldata.github_repo_detail.githuburl == "" || this.github_access.status_code != 200)||this.regmicroalldata.github_repo_detail.baseversion == "" || (this.regmicroalldata.github_repo_detail.filename == "" || this.validfilename_msg) || (this.regmicroalldata.github_repo_detail.requirementspath == "" || this.validpath_msg))) {
                    this.validForm = false;
                } else if (this.regmicroalldata.resource_cpu == "" || this.regmicroalldata.resource_cpu < 0.2) {
                    this.validForm = false;
                } else if (this.regmicroalldata.resource_memory == "" || this.regmicroalldata.resource_memory < 0.5) {
                    this.validForm = false;
                } else if (this.regmicroalldata.enable_gpu == "") {
                    this.validForm = false;
                } else if (this.datacheck.memory < this.regmicroalldata.resource_memory || this.datacheck.cpu < this.regmicroalldata.resource_cpu) {
                    this.validForm = false;
                } else if (this.regmicroalldata.microservice_type == 'non data microservice' && this.regmicroalldata.port == "") {
                    this.validForm = false;
                } else if (this.regmicroalldata.microservice_type == 'non data microservice' && this.regmicroalldata.model_available == "yes" && (this.regmicroalldata.model_image_url == "" || this.regmicroalldata.volumes_path == "")) {
                    this.validForm = false;
                } else {
                    this.regmicroalldata.microservice_code = (this.regmicroalldata.name.replace(/[- ]/g, '')).toLowerCase()
                    if (this.regmicroalldata.microservice_type == 'non data microservice' && this.regmicroalldata.model_available == "yes" && this.regmicroalldata.model_image_url != "") {
                        var file_size = this.regmicroalldata.model_image_file.size
                        var gb_size = Number((file_size / (1024 ** 3)) * 2)
                        if (this.regmicroalldata.available_storage <= gb_size) {
                            this.invalidstorage = true;
                        }
                        if (this.invalidstorage) {
                            this.validForm = false
                        } else {
                            this.validForm = true;
                        }
                    } else {
                        this.validForm = true;
                    }

                }
            }
            if (this.currentStep == 2) {
                if (this.regmicroalldata.microservice_type == 'data microservice'){
                    if(this.regmicroalldata.endpoint==""){
                        this.validForm = false;
                    }else{
                        this.validForm = true;
                    }
                }else{
                    if (this.regmicroalldata.api_details[this.regmicroalldata.deploy_type].length == 0) {
                        this.validForm = false;
                    } else if (this.response_message !=""){
                        this.validForm = false;
                    }else{
                        this.validForm = true;
                    }
                }
            }
            if (this.currentStep < 3 && this.validForm) {
                this.currentStep = this.currentStep + 1;
            }
        },
        backStep() {
            if (this.currentStep > 1) {
                this.currentStep = this.currentStep - 1;
                this.validForm = true;
            }
        },
        async autoDeploy(id) {
            this.successms = false;
            var data = { "id": id, "owner_id": localStorage.getItem("owner") }
            let response = await API_Util.URLS.axios
                .post(API_Util.URLS.deploy, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "x-api-key": this.apiKey,
                    },
                })
                .then((response) => response)
                .catch((err) => console.log(err));
            if (response.data.status_code != 200) {
                this.response_msg = response.data.message;
                this.errorms = true;
            } else {
                this.response_msg = response.data.message;
                this.successms = true;
            }
        },
        async getpythonversion(type, value) {
            var data = {
                owner_id: localStorage.getItem("owner"),
                lang: "EN",
                code: "PYTHON_VERSION",
            };
            let response = await API_Util.URLS.axios
                .post(API_Util.URLS.flexyListValue, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "x-api-key": this.apiKey,
                    },
                })
                .then((response) => response.data.result)
                .catch((err) => console.log(err));
            this.baseversionitem = response;
            this.baseversionitem.sort((firstItem, secondItem) => firstItem.order - secondItem.order);
            if (type == "update") {
                this.regmicroalldata.github_repo_detail.baseversion = value;
                this.otherversion = false;
                this.loaders = false
            }
        },
        async getdatatype() {
            var data = {
                owner_id: localStorage.getItem("owner"),
                lang: "EN",
                code: "INPUT_DATA_TYPE",
            };
            let response = await API_Util.URLS.axios
                .post(API_Util.URLS.flexyListValue, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "x-api-key": this.apiKey,
                    },
                })
                .then((response) => response.data.result)
                .catch((err) => console.log(err));
            this.datatypeitem = response;
            this.datatypeitem.sort((firstItem, secondItem) => firstItem.order - secondItem.order);

        },
        async updateversion() {
            var data = {
                ownerId: localStorage.getItem("owner"),
                parentcode: "PYTHON_VERSION",
                id: 0,
                code: this.addversion.toUpperCase(),
                value: this.addversion,
                orderBy: this.baseversionitem.length,
                languages: [],
                dateUpdated: "",
                updatedBy: localStorage.getItem("owner"),
                attributes: [],
                extra_attributes: [],
                delete_ids: 0,
                lstId: 0,
                effectAllOwners: true
            };
            let response = await API_Util.URLS.axios
                .post(API_Util.URLS.flexyValueAdd, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "x-api-key": this.apiKey,
                    },
                })
                .then((response) => response.data)
                .catch((err) => console.log(err));
            this.updateversionresponse = response;
            if (this.updateversionresponse.status == "success") {
                this.loaders = true
                setTimeout(() => {
                    this.getpythonversion("update", data.value);
                }, 1000);
            }
        },
        async registerData() {
            this.regmicroalldata.owner_id = localStorage.getItem('owner')
            if (this.regmicroalldata.deploy_type == "modelimage" && this.regmicroalldata.docker_image_source == "" && this.regmicroalldata.docker_image_url !="") {
                this.dockerUpload(this.regmicroalldata);
            } else if(this.regmicroalldata.model_available == "yes" && this.regmicroalldata.model_image_source == "" && this.regmicroalldata.model_image_url !=""){
                this.modelUpload(this.regmicroalldata)
            }
            if (this.regmicroalldata.id == 0) {
                var url = API_Util.URLS.register;
                var msg = "Created"
            } else {
                url = API_Util.URLS.updatedetails;
                msg = "Updated"
            }
            let response = await API_Util.URLS.axios
                .post(url, this.regmicroalldata, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "x-api-key": this.apiKey,
                    },
                })
                .then((response) => response)
                .catch((err) => console.log(err));
            if (response.data.status_code != 200) {
                this.uploadms = false;
                this.response_msg = response.data.message;
                this.errorms = true;
            } else {
                this.uploadms = false;
                this.response_message = "";
                this.response_status = "";
                
                if ((this.regmicroalldata.deploy_type == "modelimage" && this.regmicroalldata.docker_image_source == "" && this.regmicroalldata.docker_image_url !="") || (this.regmicroalldata.model_available == "yes" && this.regmicroalldata.model_image_source == "" && this.regmicroalldata.model_image_url !="")) {
                    this.response_msg = "Successfully "+ msg +" and file is uploading once completed you will get notification form your email";
                }else{
                    this.response_msg = "Successfully "+ msg;
                }
                this.successms = true;
                if (this.regmicroalldata.auto_deploy == "yes") {
                    setTimeout(() => {
                        this.successms = false;
                        this.autoDeploy(response.data.id);
                    }, 5000);
                }
                setTimeout(() => {
                    this.getRegMicroserviceList();
                    this.regmicroservices = false;
                    if (this.regmicroalldata.id > 0) {
                        this.regmseditdialog = false;
                    }
                    this.clearMicroAllData();
                    this.changeStepper();
                }, 1000);
            }
        },
        async dataMsSave(){
            this.regmicroalldata.owner_id = localStorage.getItem('owner')
            if(this.regmicroalldata.name==""){
                this.validForm = false;
            }else if(this.regmicroalldata.description==""){
                this.validForm = false;
            } else if (this.regmicroalldata.resource_cpu == "" || this.regmicroalldata.resource_cpu < 0.2) {
                this.validForm = false;
            } else if (this.regmicroalldata.resource_memory == "" || this.regmicroalldata.resource_memory < 0.5) {
                this.validForm = false;
            } else if (this.regmicroalldata.enable_gpu == "") {
                this.validForm = false;
            } else if (this.datacheck.memory < this.regmicroalldata.resource_memory || this.datacheck.cpu < this.regmicroalldata.resource_cpu) {
                this.validForm = false;
            }else if(this.regmicroalldata.teamspace_code==""){
                this.validForm = false;
            }else if(this.regmicroalldata.microservice_code == ""){
                this.validForm = false;
            }else{
                this.validForm = true;
                this.regmicroalldata.version = "v" + this.regmicroalldata.major + "." + this.regmicroalldata.minor + "." + this.regmicroalldata.patch;
                let response = await API_Util.URLS.axios
                    .post(API_Util.URLS.register, this.regmicroalldata, {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "x-api-key": this.apiKey,
                        },
                    })
                .then((response) => response)
                .catch((err) => console.log(err));
                if (response.data.status_code != 200) {
                    this.response_msg = response.data.message;
                    this.errorms = true;
                }else{
                    this.response_msg = response.data.message;
                    this.successms = true;
                }
            setTimeout(() => {
                this.getRegMicroserviceList();
                this.dataregmicroservices = false;
                this.clearMicroAllData();
            }, 1000);
            }
        },
        save() {
            if (this.regmicroalldata.docs == "no") {
                if(this.regmicroalldata.microservice_type == "non data microservice"){
                    var inputdatas = []
                    this.regmicroalldata.input_details.forEach((item) => {
                        if (!inputdatas.includes(item.name)) {
                            inputdatas.push(item.name)
                        }
                    });

                    this.regmicroalldata.api_route[this.regmicroalldata.deploy_type].forEach((item) => {
                        if (!inputdatas.includes(item)) {
                            this.isnotavailableinput = true
                            this.isnotavailableinputitem = item
                        }
                    });
                    inputdatas = []
                }else{
                    if(this.regmicroalldata.input_details.length == 0){
                        this.isnotavailableinput = true
                    }else{
                        this.isnotavailableinput = false;
                    }
                }
                
            }
            if (this.isnotavailableinput && this.regmicroalldata.docs == "no") {
                this.validForm = false;
            } else if (this.regmicroalldata.docs == "yes" && this.regmicroalldata.docs_yaml_url == "") {
                this.validForm = false;
            } else {
                this.validForm = true;
                this.regmicroalldata.version = "v" + this.regmicroalldata.major + "." + this.regmicroalldata.minor + "." + this.regmicroalldata.patch
                if (this.regmicroalldata.docs == "yes" && this.regmicroalldata.docs_yaml =="") {
                    this.yamlUpload(this.regmicroalldata.docs_yaml_file)
                } else {
                    this.registerData();
                }
            }
        },
        statusMicroservices(approval_status, rejection) {
            const headers = {
                headers: { "x-api-key": this.apiKey },
            }
            var self = this;
            this.selectedmicro.forEach(async function (arrayItem) {
                if(approval_status == "Approve" && arrayItem.mstype=="data microservice" && arrayItem.index_status!="Success" ){
                    self.response_msg = "Please index and deploy"
                    self.errorms = true;
                }else{
                    if (approval_status == "Approve") {
                    var url = API_Util.URLS.deploy;
                    var data = { "id": arrayItem.fun_id, "approval_status": approval_status, "owner_id": localStorage.getItem("owner"), "owner_name": localStorage.getItem("ownerName") }
                    } else {
                        url = API_Util.URLS.updatedetails;
                        data = { "id": arrayItem.fun_id, "approval_status": approval_status, "approval_status_old": arrayItem.fun_status, "owner_id": localStorage.getItem("owner"), "error_msg": rejection, "status": "manual" }
                    }
                    self.selectedmicro = [];
                    let response = await API_Util.URLS.axios
                        .post(url, data,
                            headers
                        )
                        .then((response) => response)
                        .catch((err) => console.log(err));
                    if (approval_status == "Approve") {
                        self.response_msg = response.data.message;
                        if (response.data.status_code != 200) {
                            self.errorms = true;
                        } else {
                            self.successms = true;
                        }
                    }

                    data = {}
                }
            });
            setTimeout(() => {
                this.getRegMicroserviceList();
                this.deletems = false;
                // this.approvems = false;
                this.rejectms = false;
            }, 500);
        
        },
        allOwners() {
            this.$router.push("/ownerlisting");
        },
    }
};
</script>
<style lang="scss" scoped>
::v-deep .v-divider:last-child {
    display: none;
}

::v-deep .v-stepper--alt-labels .v-stepper__header {
    margin: auto;
    width: 89%;
}

.selected-border {
    cursor: pointer;
}

.active-chip {
    border-bottom: 4px solid #545454;
    font-weight: bold;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
    margin: 35px -75px 0 !important;
}

::v-deep .selected-border p .v-chip.v-size--default {
    height: 32px;
    width: 32px;
    margin-left: 4px;
    padding: 0px 7px 0 7px;
    justify-content: center;
}

::v-deep .v-radio .v-label {
    color: #000 !important;
}

::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 20px;
}

::v-deep .v-radio .v-input--selection-controls__input .v-icon {
    font-size: 20px;
}

table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
}

td {
    width: 50px;
}

.break_word {
    word-wrap: break-word;
}

.break-all {
    word-break: break-all;
}

.basicInfo-tlt {
    padding: 12px 10px 0;
}

.custom .v-stepper__step--complete>span .v-stepper__step__step {
    background-color: var(--v-primary-base) !important;
}
</style>
  
