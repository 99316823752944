<template>
  <div class="container">
    <v-card :elevation="hover ? 24 : 6" class="px-4">
      <div class="px-10 py-4">
        <div class="py-6">
          <h2 class="text-left">
            {{
              type == "create"
                ? "Create / Clone Microservice"
                : "Edit Microservices"
            }}
          </h2>
          <v-divider class="my-4" v-if="!allSelected"></v-divider>
        </div>
        <div>
          <v-row>
            <v-col cols="12" md="12" v-if="type == 'create'">
              <div
                class="d-flex justify-space-between justify-center align-center"
              >
                <div style="width: 100%">
                  <label>Functions </label>
                  <v-select
                    outlined
                    v-model="select"
                    :items="itemsField"
                    placeholder="Select Function for cloning"
                  ></v-select>
                </div>
                <div>
                  <span class="pl-2"
                    ><v-btn class="py-6 px-4 mt-2 nocap sen-success white--text"
                      ><v-icon>mdi-autorenew </v-icon>
                      <span class="pl-2">Reset</span></v-btn
                    ></span
                  >
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Title</label>
              <v-text-field outlined readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Microservice Code</label>
              <v-text-field outlined readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <label>Description 1</label>
              <v-textarea outlined name="input-7-1" rows="3"></v-textarea>
            </v-col>
            <v-col cols="12">
              <label>Description 2</label>
              <v-textarea outlined name="input-7-1" rows="3"></v-textarea>
            </v-col>
            <v-col cols="6">
              <label>Type</label>
              <v-select
                outlined
                v-model="select"
                :items="itemsField"
                placeholder="Select Microservices"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <label>Class</label>
              <v-select
                outlined
                v-model="select"
                :items="itemsField"
                placeholder="Select Microservices"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <label>Microservice Type</label>
              <v-select
                outlined
                v-model="select"
                :items="msType"
                placeholder="Microservice Type"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <label>Url Code</label>
              <v-text-field outlined readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Keywords</label>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Data Collection Methodology</label>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Consists of</label>
              <v-select
                outlined
                v-model="select"
                :items="msType"
                placeholder="Microservice Type"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Microservice Producer</label>
              <v-select
                outlined
                v-model="select"
                :items="msType"
                placeholder="Microservice Type"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Connection Type</label>
              <v-select
                outlined
                v-model="select"
                :items="msType"
                placeholder="Microservice Type"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Deployment Type</label>
              <v-select
                outlined
                v-model="select"
                :items="msType"
                placeholder="Microservice Type"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <label>API endpoints</label>
              <v-textarea outlined name="input-7-1" rows="1"></v-textarea>
            </v-col>

            <v-col cols="12" sm="6">
              <label>API Residence</label>
              <v-select
                outlined
                v-model="select"
                :items="msType"
                placeholder="Microservice Type"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Source</label>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Source Link</label>
              <v-text-field outlined></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <label>Doc URL</label>
              <v-text-field outlined></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <label>Term URL</label>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Icon</label>
              <!-- <v-file-input label="File input" outlined dense></v-file-input> -->
              <div v-if="iconlist.length == 0">
                <input
                  type="file"
                  name="fields[iconFile][]"
                  id="iconFile"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChangefile('iconlist', 'iconImage')"
                  ref="iconlist"
                  accept=".jpg,.jpeg,.png,.PNG"
                />

                <label
                  style="border-radius: 5px"
                  for="iconFile"
                  class="
                    blue-grey
                    d-block
                    lighten-5
                    text-center
                    cursor-pointer
                    py-6
                  "
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop($event, 'iconlist', 'iconImage')"
                >
                  <div>
                    <v-icon style="font-size: 40px">mdi-cloud-upload </v-icon>
                  </div>
                  <div>
                    <span class="underline">click here</span> to upload their
                    files
                  </div>
                </label>
              </div>

              <div
                class="image-container ma-2"
                style="width: 110px !important; height: 110px !important"
                v-for="(data, fileKey) in iconlist"
                :key="fileKey"
              >
                <v-img
                  class="preview"
                  v-bind:ref="'iconImage' + parseInt(fileKey)"
                  width="110px"
                  height="110px"
                />
                <span class="remove-icon">
                  <v-icon @click="iconlist.splice(fileKey, 1)">
                    mdi-close-circle-outline
                  </v-icon>
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Logo</label>

              <div v-if="logolist.length == 0">
                <input
                  type="file"
                  name="fields[logoFile][]"
                  id="logoFile"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChangefile('logolist', 'logoImage')"
                  ref="logolist"
                  accept=".pdf,.jpg,.jpeg,.png,.PNG"
                />

                <label
                  for="logoFile"
                  style="border-radius: 5px"
                  class="
                    blue-grey
                    d-block
                    lighten-5
                    text-center
                    cursor-pointer
                    py-6
                  "
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop($event, 'logolist', 'logoImage')"
                >
                  <div>
                    <v-icon style="font-size: 40px">mdi-cloud-upload </v-icon>
                  </div>
                  <div>
                    <span class="underline">click here</span> to upload their
                    files
                  </div>
                </label>
              </div>
              <div
                class="image-container ma-2"
                style="width: 110px !important; height: 110px !important"
                v-for="(file, fileKey) in logolist"
                :key="fileKey"
              >
                <v-img
                  class="preview"
                  v-bind:ref="'logoImage' + parseInt(fileKey)"
                />
                <span class="remove-icon">
                  <v-icon @click="logolist.splice(fileKey, 1)">
                    mdi-close-circle-outline
                  </v-icon>
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <label>Add Image</label>
              <div style="width: 100% !important">
                <div v-if="imagelist.length == 0">
                  <input
                    type="file"
                    multiple
                    name="fields[imageFile][]"
                    id="imageFile"
                    class="w-px h-px opacity-0 overflow-hidden absolute"
                    @change="onChangefile('imagelist', 'addImage')"
                    ref="imagelist"
                    accept=".pdf,.jpg,.jpeg,.png"
                  />

                  <label
                    for="imageFile"
                    style="border-radius: 5px"
                    class="
                      blue-grey
                      d-block
                      lighten-5
                      text-center
                      cursor-pointer
                      py-6
                    "
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="drop($event, 'imagelist', 'addImage')"
                  >
                    <div>
                      <v-icon style="font-size: 40px">mdi-cloud-upload </v-icon>
                    </div>
                    <div>
                      <span class="underline">click here</span> to upload their
                      files
                    </div>
                  </label>
                </div>
                <div
                  class="image-container d-inline-block ma-2"
                  style="width: 110px !important; height: 110px !important"
                  v-for="(file, fileKey) in imagelist"
                  :key="fileKey"
                >
                  <v-img
                    class="preview"
                    v-bind:ref="'addImage' + parseInt(fileKey)"
                  />
                  <span class="remove-icon">
                    <v-icon @click="imagelist.splice(fileKey, 1)">
                      mdi-close-circle-outline
                    </v-icon>
                  </span>
                </div>
              </div>
            </v-col>
            <!-- EXPANSION PANEL -->
            <v-col cols="12">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="grey darken-2 white--text py-0"
                    >Add the data fields of the data schema
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="mt-2">
                      <v-col cols="12" sm="6">
                        <label>Name of the field</label>
                        <v-text-field outlined readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <label>Type</label>
                        <v-text-field outlined readonly></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <label>Size</label>
                        <v-text-field outlined readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-checkbox
                          v-model="selected"
                          value="Jacob"
                          label="is Null"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12">
                        <label>Description 1</label>
                        <v-textarea
                          outlined
                          name="input-7-1"
                          rows="3"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <div class="text-center pt-6 pb-10">
                      <span class="px-2">
                        <v-btn class="nocap" outlined
                          ><v-icon>mdi-autorenew</v-icon
                          ><span class="pl-2">Reset</span>
                        </v-btn></span
                      >
                      <span class="px-2"
                        ><v-btn class="nocap sen-success white--text"
                          ><v-icon>mdi-plus </v-icon
                          ><span class="pl-2"> Add</span>
                        </v-btn></span
                      >
                    </div>
                    <v-card>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Name</span
                                >
                              </th>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1">
                                  Type</span
                                >
                              </th>
                              <th class="text-left">
                                <span class="font-weight-boald">Is Null</span>
                              </th>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Size</span
                                >
                              </th>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Description</span
                                >
                              </th>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Delete</span
                                >
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>Name</td>
                              <td>Name</td>
                              <td>Name</td>
                              <td>Name NameNameNameName</td>
                              <td><v-icon color="error">mdi-trash-can </v-icon></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <!-- EXPANSION PANEL END -->
            <!-- ADD TAG -->
            <!-- EXPANSION PANEL -->
            <v-col cols="12">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header class="grey darken-2 white--text"
                    >Add Tag
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="mt-2 text-fields-row">
                      <v-col cols="12" sm="6">
                        <label>Type</label>
                        <v-text-field outlined></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <label>Size</label>
                        <v-text-field outlined></v-text-field>
                      </v-col>
                    </v-row>
                    <div class="text-center pt-6 pb-10">
                      <span class="px-2">
                        <v-btn class="nocap" outlined
                          ><v-icon>mdi-autorenew </v-icon>
                          <span class="pl-2">Reset</span>
                        </v-btn></span
                      >
                      <span class="px-2"
                        ><v-btn class="nocap sen-success white--text">
                          <v-icon>mdi-plus </v-icon>
                          <span class="pl-2"> Add</span>
                        </v-btn></span
                      >
                    </div>
                    <v-card>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Type</span
                                >
                              </th>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1">
                                  Size</span
                                >
                              </th>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Delete</span
                                >
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>Name NameNameNameName</td>
                              <td><v-icon color="error">mdi-trash-can </v-icon></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <!-- EXPANSION PANEL END ADD FEATURE -->

            <v-col cols="12">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header class="grey darken-2 white--text"
                    >Add Feature</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row class="mt-2 text-fields-row">
                      <v-col cols="12" sm="12">
                        <label>Text</label>
                        <v-text-field outlined></v-text-field>
                      </v-col>
                    </v-row>
                    <div class="text-center pt-6 pb-10">
                      <span class="px-2">
                        <v-btn class="nocap" outlined
                          ><v-icon>mdi-autorenew </v-icon>
                          <span class="pl-2">Reset</span>
                        </v-btn></span
                      >
                      <span class="px-2"
                        ><v-btn class="nocap sen-success white--text">
                          <v-icon>mdi-plus </v-icon>
                          <span class="pl-2"> Add</span>
                        </v-btn></span
                      >
                    </div>
                    <v-card>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Name</span
                                >
                              </th>

                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Delete</span
                                >
                              </th>
                            </tr>
                          </thead>
                          <tbody v-for="(textField, i) in textFields" :key="i">
                            <tr>
                              <td>Name</td>

                              <td><v-icon color="error">mdi-trash-can </v-icon></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>

            <!-- Pricing Plan -->
            <v-col cols="12">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header class="grey darken-2 white--text"
                    >Add Pricing Plan</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row class="mt-2 text-fields-row">
                      <v-col cols="12" sm="4">
                        <label>Plan </label>
                        <v-text-field outlined></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <label>Feature </label>
                        <v-text-field outlined></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <label>Description </label>
                        <v-text-field outlined></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <label>Pricing </label>
                        <v-text-field outlined></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <label>Number of free call</label>
                        <v-text-field outlined></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <label>Free limit (Billing)</label>
                        <v-text-field outlined></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <label>Cost per Unit (Billing) </label>
                        <v-text-field outlined></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <label>Endpoint Typel</label>
                        <v-text-field outlined></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <label>Cost per API call</label>
                        <v-select
                          outlined
                          v-model="select"
                          :items="msType"
                          placeholder="Microservice Type"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <!-- ------------INTERNAL EXPANSION TIERS---------------- -->
                        <v-expansion-panels>
                          <v-expansion-panel class="elevation-0">
                            <v-expansion-panel-header
                              class="grey darken-2 white--text py-0"
                              >Tiers</v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <v-row class="mt-2 text-fields-row">
                                <v-col cols="12" sm="4">
                                  <label>Lower limit</label>
                                  <v-text-field outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <label>Upper limit</label>
                                  <v-text-field outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <label>Cost per API call</label>
                                  <v-select
                                    outlined
                                    v-model="select"
                                    :items="msType"
                                    placeholder="Microservice Type"
                                  ></v-select>
                                </v-col>
                              </v-row>

                              <div class="text-center pt-6 pb-10">
                                <span class="px-2">
                                  <v-btn class="nocap" outlined
                                    ><v-icon>mdi-autorenew </v-icon>
                                    <span class="pl-2">Reset</span>
                                  </v-btn></span
                                >
                                <span class="px-2"
                                  ><v-btn class="nocap sen-success white--text">
                                    <v-icon>mdi-plus </v-icon>
                                    <span class="pl-2"> Add</span>
                                  </v-btn></span
                                >
                              </div>
                              <v-card :elevation="hover ? 24 : 2">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th class="text-left">
                                          <span
                                            class="font-weight-boald subtitle-1"
                                            >Lower limit</span
                                          >
                                        </th>
                                        <th class="text-left">
                                          <span
                                            class="font-weight-boald subtitle-1"
                                            >Upper limit</span
                                          >
                                        </th>

                                        <th class="text-left">
                                          <span
                                            class="font-weight-boald subtitle-1"
                                            >Upper limit</span
                                          >
                                        </th>

                                        <th class="text-left">
                                          <span
                                            class="font-weight-boald subtitle-1"
                                            >Delete</span
                                          >
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Name</td>
                                        <td>Name</td>
                                        <td>Name</td>

                                        <td><v-icon color="error">mdi-trash-can </v-icon></td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <!-- ---------------------------- -->
                      </v-col>
                    </v-row>

                    <div class="text-center pt-6 pb-10">
                      <span class="px-2"
                        ><v-btn class="nocap sen-success white--text px-10">
                          <v-icon>mdi-plus </v-icon
                          ><span class="pl-2">Add Plan</span>
                        </v-btn></span
                      >
                    </div>
                    <div>
                      <v-card>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Plan</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Feature</span
                                  >
                                </th>

                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Description</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Pricing</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Number of free call</span
                                  >
                                </th>

                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Free limit (Billing)</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Delete</span
                                  >
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Name</td>
                                <td>Name</td>
                                <td>Name</td>
                                <td>Name</td>
                                <td>Name</td>
                                <td>Name</td>
                                <td><v-icon color="error">mdi-trash-can </v-icon></td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <!-- -------END------- -->

            <v-col cols="12">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header class="grey darken-2 white--text"
                    >Validation Settings</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row class="mt-2 text-fields-row">
                      <v-col cols="12" sm="12">
                        <label>Mandatory Fields</label>
                        <v-combobox
                          outlined
                          multiple
                          v-model="select"
                          append-icon
                          chips
                          deletable-chips
                          class="tag-input"
                          :search-input.sync="search"
                          @keyup.tab="updateTags"
                          @paste="updateTags"
                        >
                        </v-combobox>
                        <v-expansion-panels>
                          <v-expansion-panel>
                            <v-expansion-panel-header
                              class="grey darken-2 white--text py-0"
                              >Limitations
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row class="mt-2">
                                <v-col cols="12" sm="6">
                                  <label>Type</label>
                                  <v-select
                                    outlined
                                    v-model="select"
                                    :items="msType"
                                    placeholder="Microservice Type"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <label>Lower Limit</label>
                                  <v-text-field
                                    outlined
                                    readonly
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <label>Upper Limit</label>
                                  <v-text-field
                                    outlined
                                    readonly
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <label>Field</label>
                                  <v-text-field
                                    outlined
                                    readonly
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>
                    <div class="text-center pt-6 pb-10">
                      <span class="px-2">
                        <v-btn class="nocap" outlined
                          ><v-icon>mdi-autorenew </v-icon>
                          <span class="pl-2">Reset</span>
                        </v-btn></span
                      >
                      <span class="px-2"
                        ><v-btn class="nocap sen-success white--text">
                          <v-icon>mdi-plus </v-icon>
                          <span class="pl-2"> Add</span>
                        </v-btn></span
                      >
                    </div>
                    <v-card>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Type</span
                                >
                              </th>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Lower Limit</span
                                >
                              </th>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Upper Limit</span
                                >
                              </th>
                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Field</span
                                >
                              </th>

                              <th class="text-left">
                                <span class="font-weight-boald subtitle-1"
                                  >Delete</span
                                >
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                class="grey lighten-4 text-center grey--text"
                                colspan="5"
                              >
                                No data found
                              </td>
                              <!-- <td>Name</td>
                              <td><v-icon>mdi-trash-can </v-icon></td> -->
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>
        <div class="text-center pt-6 pb-10">
          <span class="px-2">
            <v-btn class="nocap" text large> Cancel </v-btn></span
          >
          <span class="px-2"
            ><v-btn class="nocap sen-success white--text" large>
              Create
            </v-btn></span
          >
        </div>

        <v-alert text type="error"> Failed to create Microservices </v-alert>
      </div>
    </v-card>
  </div>
</template> 
<script>
export default {
  name: "popup",
  delimiters: ["${", "}"],
  data: () => ({
    select: [],
    items: [],
    search: "", //sync search
    iconlist: [],
    logolist: [],
    imagelist: [],
    textFields: [],
    type: "create",
    itemsField: [
      {
        text: "Nature",
        icon: "mdi-pencil-outline ",
      },
      {
        text: "Nightlife",
        icon: "mdi-pencil-outline ",
      },
    ],
    msType: ["Atomistic", "Pipeline"],
  }),
  created() {
    this.type = this.$route.params.type;
  },
  methods: {
    updateTags() {
      this.$nextTick(() => {
        this.select.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    },
    add() {
      this.textFields.push({});
    },

    removeNew(index) {
      this.textFields.splice(index, 1);
    },
    onChangefile(fieldname, imageref) {
      this[fieldname] = [...this.$refs[fieldname].files];

      if (fieldname != "imagelist" && this[fieldname].length > 1) {
        this[fieldname].splice(0, this[fieldname].length - 1);
      }

      for (let i = 0; i < this[fieldname].length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs[`${imageref}` + parseInt(i)][0].src = reader.result;
        };
        reader.readAsDataURL(this[fieldname][i]);
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event, fieldname, refname) {
      event.preventDefault();
      this.$refs[fieldname].files = event.dataTransfer.files;
      this.onChangefile(fieldname, refname); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
};
</script>
<style lang="scss">
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 46px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: rgb(255 255 255) !important;
}
.container {
  max-width: 1224px;
}
.text-fields-row {
  display: flex;
}
.w-px {
  width: 1px;
}
.h-px {
  height: 1px;
}
.opacity-0 {
  opacity: 0;
}
[v-cloak] {
  display: none !important;
}
.image-container {
  position: relative;
  width: 100px !important;
}
.remove-icon {
  position: absolute;
  top: -20px;
  right: -8px;
}

.v-input__slot {
  margin-bottom: 0px !important;
}
.absolute {
  position: absolute;
}
.v-image__image,
.v-image__placeholder {
  width: 100px !important;
  height: 100px !important;
}
.v-responsive {
  position: relative;
  overflow: inherit;
  flex: 1 0 auto;
  max-width: 100%;
  display: block;
}
</style>
