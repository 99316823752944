<template>
  <v-card class = "ms-title">
    <v-card-title class = "text-center">
      <div class = "py-2">
        <h3 class = "text-center"> Index Properties</h3>
        <div class = "closeicon-right">
          <v-icon  @click.prevent = "closeMsIndexDialog" class = "text-right">
            mdi-close-circle-outline
          </v-icon>
        </div>
      </div>
    </v-card-title>
    <v-divider class = "my-4"></v-divider>
    <v-card-text>
      <v-row>
      <v-col cols = "12">
        <label class = "model-img">Index Image Upload</label>
        <v-row>
          <v-col cols = "12" sm = "12" class="pl-0">
            <div class = "" >
              <div class = "d-flex flex-column" style = "width: 135px">
                <div class = "position-relative pt-3"
                  v-if = "!regmicroalldata.index_image_url" @dragover.prevent = "dragOver"
                  @dragleave.prevent = "dragLeave" @drop.prevent = "drop($event, 'icon')">
                  <div class = "text-center d-flex-column justify-center fileBox">
                    <v-icon class = "secondary--text"> mdi-cloud-upload</v-icon>
                    <div>Upload</div>
                  </div>
                  <input type = "file" class = "clicktofileUpload overflow-hidden w-px h-px opacity-0"
                    @change="indexImageFileChange($event.target.files, 'icon')" accept=".zip" />
                </div>
              </div>
              <p class = "shrink rounded  fileimgWidth logoBoxSize mt-1" v-if = "regmicroalldata.index_image_url" alt = "Model file"
                contain aspect-ratio = "2">{{ regmicroalldata.index_image_url }}
                <button class = "pos mclose-iconPos" type = "button" @click = "clearIcon" title = "Remove file">
                  <v-icon color = "error">mdi-close-circle-outline </v-icon>
                </button>
              </p>
              <span v-if = "!regmicroalldata.index_image_url && !validForm" style = "color:red;">Index File is required</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols = "12">
        <v-row>
          <v-col cols = "12" class = "pl-0 pb-0">
            <label >Index Properties</label>
          </v-col>
          <v-col cols = "12" sm = "5" class = "px-5 pb-5 secondary lighten-3" v-if="regmicroalldata.index_type== 'new'">
            Column name
            <v-text-field outlined  v-model = "indexproperty.column_name"></v-text-field>
          </v-col>
          <v-col cols = "12" sm = "5" class = "px-5 pb-5 secondary lighten-3" v-if="regmicroalldata.index_type== 'new'">
            Data Type
            <v-select
              outlined
              v-model.trim = "indexproperty.data_type"
              :items="datatypeitem" 
              item-text="value"
              item-value = "value"
              placeholder = "select type"
            ></v-select> 
          </v-col>
          <v-col cols = "12" xs = "12" sm = "2" md = "2" lg = "2" class = "px-5 pb-5 secondary lighten-3" v-if="regmicroalldata.index_type== 'new'">
            <div class = "d-flex flex-column px-2">
              <span class = "ml-2">
                <v-btn class = "nocap primary white--text" style = "height: 41px; margin-top:21px;"
                  :disabled = "!indexproperty.column_name || !indexproperty.data_type" @click.prevent = "addindexData">
                  <span>
                    <v-tooltip top max-width = "200">
                      <template v-slot:activator = "{ on, attrs }">
                        <v-icon dark v-bind = "attrs" v-on = "on">
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>Add Index data Properties</span>
                    </v-tooltip>
                  </span>
                </v-btn>
              </span>
            </div>
          </v-col>
          <v-col cols = "12" class = "px-0 lighten-3 my-4" v-if = "regmicroalldata.index_property.length">
            <v-card :elevation="hover ? 24 : 2" style = "width: 100%">
              <v-simple-table >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class = "text-left">
                        <span class = "font-weight-boald subtitle-1">Column Name</span>
                      </th>
                      <th class = "text-left">
                        <span class = "font-weight-boald subtitle-1">Data Type</span>
                      </th>
                      <th class = "text-left">
                        <span class = "font-weight-boald subtitle-1">Delete</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for = "(req, index) in regmicroalldata.index_property" :key = "req.key">
                      <td>{{ req.column_name }}</td>
                      <td>{{ req.data_type }}</td>
                      <td>
                        <v-icon color = "error" @click = "removeindexData(index)">mdi-trash-can
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
        <span v-if = "!regmicroalldata.index_property.length && !validForm" style = "color:red;">Index Properties required</span>
      </v-col>
    </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <div class = "justify-center" style = "display: flex !important;">
        <v-btn class = "nocap" @click = "closemsindex()" text>
          <span>Cancel</span>
        </v-btn>
        <v-btn class = "nocap primary" text @click = "indexSave()">
          <span>Save</span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import API_Util from "../services/util/API_Util.js";
// import * as XLSX from 'xlsx'
export default {
  name : "msindex",
  props : ['regmicroalldata', 'validForm', 'labels'],
  data: () => ({
    apiKey : "",
    owner:"",
    indexproperty :{},
    datatypeitem:[],
    hover:false,
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
    this.getdatatype();
  },
  methods: {
    addindexData() {
      this.regmicroalldata.index_property.push(this.indexproperty);
      this.indexproperty = {};
    },
    removeindexData(index) {
      this.regmicroalldata.index_property.splice(index, 1);
    },
    closeMsIndexDialog() {
      this.$emit('closeMsIndexDialog', false);
    },
    closemsindex() {
      this.$emit('closemsindex', false);
    },
    indexSave() {
      this.$emit('indexSave', false);
    },
    clearIcon() {
      this.regmicroalldata.index_image_file = "";
      this.regmicroalldata.index_image_url = "";
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    indexImageFileChange(files, input) {
      if (input === "icon") {
        this.regmicroalldata.index_image_url = files[0].name;
        this.regmicroalldata.index_image_file = files[0];
        // var reader = new FileReader();
        // reader.readAsArrayBuffer(files[0]);
        // reader.onload = () => {
        //   var data = reader.result;
        //   var wb = XLSX.read(data, { type: "array" });
        //   var sheetName = wb.SheetNames[0];
        //   var ws = wb.Sheets[sheetName];
        //   var options = { header: 1 };
        //   var datas = XLSX.utils.sheet_to_json(ws,options);
        //   var header = datas.shift();
        //   // this.showData(header)
        // };
      }
    },
    // showData(header){
    //   this.regmicroalldata.index_property = {}
    //   header.forEach((item) =>{
    //     console.log(item)
    //     this.indexproperty.column_name = item 
    //     this.indexproperty.data_type = "text"
    //     this.regmicroalldata.index_property.push(this.indexproperty)
    //     this.indexproperty = {}
    //   })
    //   console.log(this.regmicroalldata.index_property)
    // },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event, input) {
      event.preventDefault();
      this.indexImageFileChange(event.dataTransfer.files, input)
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    async getdatatype() {
      var data = {
          owner_id: localStorage.getItem("owner"),
          lang: "EN",
          code: "INPUT_DATA_TYPE",
      };
      let response = await API_Util.URLS.axios
          .post(API_Util.URLS.flexyListValue, data, {
              headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                  "x-api-key": this.apiKey,
              },
          })
          .then((response) => response.data.result)
          .catch((err) => console.log(err));
      this.datatypeitem = response;
      // this.datatypeitem.sort((firstItem, secondItem) => firstItem.order - secondItem.order);
      this.datatypeitem = ["text"]

    },
  },
};
</script>
<style>
.v-dialog {
  box-shadow : none !important;
}
input[type=number]::-webkit-inner-spin-button {
    opacity : 1
}
</style>
<style scoped>
.v-input__slot {
  min-height : 40px !important;
}

.fileimgWidth {
  max-width : fit-content;
  position : relative;
  margin-bottom : 0;
}

.msForm {
  padding : 0 24px 40px;
  background-color: #c1c1c1;
}

.close-iconPos {
  position : absolute;
  right : 15px;
  top : -2px;
}
.mclose-iconPos {
  position : absolute;
  right : -30px;
  top : -2px;
}

.model-img .v-icon {
  font-size: 12px;
  background: #c1c1c1;
  border-radius: 50%;
  padding: 2px;
  color: #fff;
  margin-top: -2px;
  margin-left: 5px;
}

.icon_relates {
  position: relative;
}
.source_icon_position {
  position: absolute;
  top: 538px;
  right: 600px;
}
.port_txt{
  padding-right:10px
}
.api_txt{
  padding-left:10px;
  padding-right:0px;
}
.tool-tip-icon {
  font-size: 15px !important;
  color: var(--v-primarytextcolor-darken1) !important;
  cursor: pointer !important;
}

.gpu_icon_position {
  position: absolute;
  top: 773px;
  right: 230px;
}
.icon-items{
  color: red;
  size: Small;

}

.port_icon_position {
  position: absolute;
  top: 88%;
  right: 85%
}
.active{
  margin-right:0px;
}

/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .msForm {
    padding: 0;
  }
}</style>