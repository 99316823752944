<template>
  <div style="width: 100%" v-if="userQuata.isUserHaveAccess">
    <!-- Email -->
    <v-row>
      <v-col class="col-3">
        <template v-if="labels">
          <p class="s-input-label">{{ labels.ADD_REQU }}</p> 
        </template>
      </v-col>
      <v-col class="col-9">
        <div class ="tags-input"> 
          <v-chip  v-for="(chip, i) of chips" :key="chip.label"
              class="ma-1 tertiary1 "
              close
              dark
              @click:close="deleteChip(i)"
              @dblclick="editchip(chip,i)"
            >
            <span class="overflow">
              {{chip}}
            </span>
          </v-chip>
          <input
            v-model="newUser.email"
            :rules="[rules.email]"
            :placeholder ="chips.length>0?'':'example@email.com'"
            v-on:keyup="checkEmail(newUser.email);checkwidth()"
            :class="[isbreak?'width100':'',chips.length>0?'':'width100']"
            @keypress.enter.space.,.prevent="saveChip"
            @blur="saveChip"
          >
        </div>
        <div style="color:#000000">Use comma or enter key to add multiple email</div><br>
        <div class="v-text-field__details exists-msg" v-if="emailExist">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message">
                {{ labels.EM_VAL_REG }}
              </div>
            </div>
          </div>
        </div>
        <div class="v-text-field__details exists-msg" v-if="userLimitExist">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message">
                Already user limit exceeded
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- Group -->
    <v-row>
      <v-col class="col-3">
        <!-- <template v-if="labels"> -->
          <p class="s-input-label">{{ labels.USR_GROUPS }}</p>
        <!-- </template> -->
      </v-col>
      <v-col class="col-9 menu">
        <!-- Display selected groups in tag style -->
        <div v-show="newUser.groups[0]" class="mb-4">
          <v-chip
            class="ma-1"
            color="tertiary1"
            dark
            small
            v-for="(group, groupKey) in newUser.groups"
            :key="groupKey.group_name"
          >
            {{ group.group_name }}
          </v-chip>
        </div>
        <!-- Dropdown selection of groups -->
        <v-menu offset-y :close-on-content-click="false" class="add-user">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              block
              outlined
              v-bind="attrs"
              v-on="on"
              class="nocap justify-space-between"
            >
              <span class="label-size">{{ labels.SEL_PREDDEF_GRPS }}</span>
              <v-icon style="margin: 0"> mdi-menu-down </v-icon>
            </v-btn>
          </template>

          <!-- Group selection items -->
          <v-list>
            <v-list-item
              v-for="(group, index) in organisation.groups"
              :key="index"
            >
              <v-checkbox
                v-model="newUser.groups"
                color="tertiary1"
                :value="group"
              >
                <template v-slot:label>
                  <span class="black--text">
                    {{ group.group_name }}
                  </span>
                </template>
              </v-checkbox>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <!-- Set Password For User? -->
    <v-row v-if="onprem!='YES'">
      <v-col class="col-12">
        <v-radio-group row v-model="newUser.setPassword">
          <v-radio :value="false" color="tertiary1" class="mt-3" >
            <template v-slot:label>
              <p class="ma-0">{{ labels.USR_EMAIL_CON }}</p>
            </template>
          </v-radio>
          <v-radio :value="true" color="tertiary1" class="mt-3">
            <template v-slot:label>
              <p class="ma-0">{{ labels.USR_EMAIL_DEN }}</p>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <!-- Password -->
    <v-row v-show="newUser.setPassword || onprem=='YES'" class="mt-2" :class="{'mt-4':newUser.groups.legend>0}">
      <v-col class="col-3">
        <template v-if="labels">
          <p class="s-input-label">{{ labels.ADD_PASS }}</p>
        </template>
      </v-col>
      <v-col class="col-9">
        <!-- !!! -->
        <v-text-field
          single-line
          outlined
          dense
          :rules="[rules.required, rules.strongpass]"
          v-model="newUser.password"
          autocomplete="new-password"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPass = !showPass"
          :type="showPass ? 'text' : 'password'"
        />
        </v-col>
        <v-col class="col-12">
          <div class="text-center">
          <v-btn
            class="mt-0"
            small
            text
            color="tertiary1"
            @click="userAction.generatePsw"
          >
            <v-icon>mdi-lock-reset</v-icon>
            <template v-if="labels">
              {{ labels.ADD_AUTOPASS }}
            </template>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Control buttons -->
    <v-row class="mt-4 d-flex justify-center">
      <v-btn
        text
        color="primarytextcolor darken-1"
        class="mx-4 nocap"
        @click="toggleDialog('orgManageUsersAddUser')"
      >
        <template v-if="labels">
          {{ labels.ADD_CANCEL }}
        </template>
      </v-btn>
      <v-btn
        :disabled="
          (chips.length == 0 && !validEmail)|| (!validPassword && newUser.setPassword)
        "
        :dark="
          (!newUser.setPassword && chips.length > 0 ||newUser.email.length >0 && validEmail)||
          (chips.length > 0 && validPassword && newUser.setPassword )
        "
        class="mx-4 nocap"
        @click="
          loader = true;
          createUser();
        "
        color="tertiary2"
      >
        <v-icon>mdi-account-plus-outline</v-icon>
          <span><template v-if="labels">{{ labels.ADD_CREATE }}</template></span>
        <v-progress-circular
          :width="3"
          :size="15"
          color="white"
          indeterminate
          class="ml-1"
          v-if="loader"
        ></v-progress-circular>
      </v-btn>
    </v-row>
  </div>
  <div v-else>
    <div class="v-text-field__details exists-msg">
          <div class="error--text" role="alert">
              <div class="text-xs-h1">
                Already user limit exceeded
              </div>
          </div>
        </div>
        <v-row class="mt-4 d-flex justify-center">
          <v-btn
            text
            color="primarytextcolor darken-1"
            class="mx-4 nocap"
            @click="toggleDialog('orgManageUsersAddUser')"
          >
            <template v-if="labels">
              {{labels.CLS_BTN}}
            </template>
          </v-btn>
    </v-row>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "@vue/composition-api";
import { organisationService } from "../../services/organisationService";
export default defineComponent({
  props: {},
  setup(_, ctx) {
    const session = ctx.root.$session,
    u_session = session.get("user_session").session,
    onprem=ref(u_session.onprem),
    userQuata=ref({});
    
    userQuata.value.isUserHaveAccess=true;
    const {
      organisation,
      toggleDialog,
      organisationCreateUser,
      getGroupList,
      groupsList,
    } = organisationService();
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var strongPasswordRegex =
      /^(?=.*\d)(?=.*[~`!@#$%^&*()-_+={}[]|\/?:;"'<,>.])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const showPass = false;
    const newUser = ref();
    const initNewUserValue = () => {
      newUser.value = {
        email: '',
        emails:[],
        groups: [],
        password: "",
        // If below value is true, user password will be set by admin
        setPassword: false,
      };
      if(onprem.value=='YES'){
        newUser.value["setPassword"]=true;
      }
    };
    initNewUserValue();
    const groupList = ref();
    const labels = ref();
    const loader = ref(false);
    //**GET LABELS */
    // setTimeout(() => {
    //   labels.value = organisation.labels;
    // }, 200);
    labels.value = organisation.labels;
    const getAllgroupList = async () => {
      await getGroupList();
      groupList.value = groupsList.allGroupList;
      userQuata.value = organisation.userQuata;
    };
    
    getAllgroupList();
    // Validation rules for input fields
    const rules = {
      required: (value) => !!value || labels.value.OD_REQUIRED,
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => {
        return emailPattern.test(value) || labels.value.EM_VAL;
      },
      strongpass: (value) => {
        return strongPasswordRegex.test(value) || labels.value.OD_PSW_SMS;
      },
    };
    const userAction = {
      generatePsw: () => {
        // !!! New psw generator function goes here
        newUser.value.password = Math.random().toString(36).slice(-8) + "0$S";
      },      
    };
    // const validEmail = ref(true)
    const createUser = () => {
      // Call a function in organisatioNService.js to make the api call
      // !!! IMPORTANT !!! //
      // Pass newUser.valu instead of just newUser, because ref() is reactive
      // If only pass newUser, it will be resetted
      newUser.value.emails = chips.value;
      organisationCreateUser(newUser.value);
      // Toggle off this popup window
      setTimeout(() => {
        // clean newUser data
        initNewUserValue();
        toggleDialog("orgManageUsersAddUser", false);
      }, 3000);
    };
    const emailExist = ref(false);
    const userLimitExist = ref(false);
    const checkEmail = (val) => {
      emailExist.value = false;
      userLimitExist.value=false;
      if (emailPattern.test(val)) {
        var array = organisation.users;
        array.forEach((usr) => {
          if (usr.user_name == val) {
            emailExist.value = true;
          }
        });
      }
      var usercount = organisation.userQuata.owner_user_count;
      var totallength = usercount + chips.value.length;
      var maxuserlimit = organisation.userQuata.userlimit;
      if (maxuserlimit) {
        if (totallength >= maxuserlimit){
          userLimitExist.value=true;
        }
      }else{
        userLimitExist.value=false;
      }
      
    }; 
    const chips=ref([]);
    const isBreak=ref(false);
    const saveChip= () =>{
      if((chips.value.indexOf(newUser.value.email) === -1)){
        if((newUser.value.email).match(emailPattern)) { 
          if((!userLimitExist.value) && (!emailExist.value)) {
            chips.value.push(newUser.value.email);
            newUser.value.email = '';
          }
        }
      }
    };   
    const deleteChip=(index)=> {
      chips.value.splice(index, 1);
    };
    const editchip=(chip,i) =>{
      newUser.value.email=chip;
      deleteChip(i);
      
    };
    const checkwidth=() =>{
      isBreak.value=false;
      if(chips.value.length>0){
        var lastval=chips.value[chips.value.length - 1];
        if((newUser.value.email.length+lastval.length) >37){
          isBreak.value=true;
        }
      }else{
        isBreak.value=false;
      }
      return isBreak.value;
    };
   
    return {
      onprem,
      userAction,
      organisation,
      newUser,
      rules,
      createUser,
      userQuata,
      toggleDialog,
      getAllgroupList,
      groupList,
      labels,
      showPass,
      loader,
      checkEmail,
      emailExist,
      userLimitExist,
      validEmail: computed(() => {
        return emailPattern.test(newUser.value.email);
      }),
      validPassword: computed(() => {
        // Only check password validaty if user selected "Set passwor now" option
        return strongPasswordRegex.test(newUser.value.password);
      }),
      chips,
      saveChip,
      deleteChip,
      editchip,
      isBreak,
      checkwidth
    };
  },
});
</script>

<style lang="scss" scoped>
.exists-msg {
  padding-left: 10px;
  margin-top: -22px;
  margin-bottom: 10px;

}
.tags-input {
  background-color: #fff;
  border: 1px solid #000000;
  border-radius: 0.25rem;
  text-align: left;
  overflow: auto;
  padding:0px 5px 0px 0px ;
  width:100%;
  max-width:600px;
  
}
.overflow{
  cursor: pointer;
  user-select: none;
  width:100%;
  display:block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
input{
  border:none;
  outline:none;
  padding :10px;
  align-items: stretch;
  overflow: visible;
  
}
@media only screen and (max-width: 435px){
  .label-size{
    font-size: 10px !important;
  }
}
.width100{
  width: 100% !important;
}
</style>
