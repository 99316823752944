<template>
  <div
    class="d-flex flex-column flex-fill page-center Dash-BG px-2 py-3"
    style="height: 100%"
  >
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center class="px-10">
          <v-form class="px-2" ref="form" v-model="valid">
            <v-card class="elevation-12" style="width: 380px">
              <v-card-title
                class="d-flex align-center justify-center pb-0 pt-8"
              >
                <div class="loginLogo pb-6">
                  <v-img
                    width="192"
                    contain
                    alt="sentient logo"
                    class=""
                    src="@/assets/sen.png"
                  ></v-img>
                </div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div class="col-md-12 pt-6">
                  <v-alert dense text type="error" v-if="loginError">
                    Invalid Org ID or Username or Password
                  </v-alert>

                  <v-text-field
                    autocomplete="false"
                    :rules="clientRules"
                    class="py-6"
                    name="clientId"
                    label="Client ID"
                    type="text"
                    outlined
                    v-model.trim="clientid"
                    required
                    @keyup.enter="validate"
                  ></v-text-field>
                  
                  <v-text-field
                    autocomplete="false"
                    :rules="nameRules"
                    class="pb-6"
                    name="login"
                    label="User Name"
                    type="text"
                    outlined
                    v-model.trim="username"
                    required
                    @keyup.enter="validate"
                  ></v-text-field>

                  <v-text-field
                    autocomplete="false"
                    outlined
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                    :rules="passwordRules"
                    :type="show1 ? 'text' : 'password'"
                    name="Password"
                    label="Password"
                    @click:append="show1 = !show1"
                    required
                    @keyup.enter="validate"
                  ></v-text-field>
                </div>
              </v-card-text>
              <v-card-actions class="d-flex justify-center pb-10">
                <div>
                  <v-btn
                    large
                    :disabled="!valid"
                    @click="validate"
                    class="tertiary2 v-btn nocap px-6 white--text"
                    >Login</v-btn
                  >
                </div>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-layout>
      </v-container>
    </v-content>
  </div>
</template> 
<script>
import API_Util from '../services/util/API_Util.js';
export default {
  name: "login",
  data: () => ({
    loginError: "",
    valid: false,
    show1: false,
    password: "",
    username: "",
    clientid: "",
    clientRules: [(v) => !!v || "Client ID is required"],
    nameRules: [(v) => !!v || "Name is required"],
    passwordRules: [(v) => !!v || "Password is required"],
    // rules: {
    //   required: (value) => !!value || "Required.",
    // },
  }),
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$session.destroy();
      window.localStorage.clear();
      //this.$router.push('/login');
    }else{
      this.$router.push('/ownersettings');
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      this.username;
      this.password;
      this.clientid;
      var data = {
        username: this.username,
        password: this.password,
        clientid: this.clientid,
      };
      API_Util.URLS.axios.post(API_Util.URLS.loginAuth, data).then((response) => {
        var loginInfo = response.data;
        if (loginInfo.error) {
          this.loginError = loginInfo.error;
        } else {
          var session_date = new Date().getTime() + (1 * 24 * 60 * 60 * 1000);
          this.$session.start();
          var data = {};
          data['session'] = loginInfo;
          this.$session.set("user_session",data);
          this.$session.set("session_date",session_date);
          localStorage.setItem('owner', response.data.owner);
          localStorage.setItem('ownerName', response.data.ownerName);
          localStorage.setItem('ownerType', response.data.ownerType);
          localStorage.setItem('redisCloud', response.data.redisCloud);
          if (loginInfo.id == 2) {
            localStorage.setItem('SUPER', true);
          } else {
            localStorage.setItem('SUPER', false);
          }
          if (response.data.ownerType === 'PM') {
              localStorage.setItem('ownerDrop', true);
              this.$router.push('/ownerlisting');
          } else {
            localStorage.setItem('ownerDrop', false);
            this.$router.push("/ownersettings");
            setTimeout(() => {
              location.reload();  
            },400)
             
          }
        }
      });
    },
  },
  created() {
    window.localStorage.clear();
  },
};
</script>
<style lang="scss">
.v-image__image,
.v-image__placeholder {
  width: 100% !important;
  height: 100% !important;
}
</style>
