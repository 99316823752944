<template>
  <div class = "msForm">
    <v-row>
      <v-col cols = "12" sm="12">
        <label>{{ labels.MSNAME }}</label>
        <span class = "pl-2 pt-3 tool-tip-icon">
          <v-tooltip top max-width = "200">
            <template v-slot:activator = "{ on, attrs }">
              <v-icon dark v-bind="attrs" v-on = "on">
                fa-question-circle
              </v-icon>
            </template>
            <li>Name should be 63 char maximum.</li>
            <li>Can use only special char space and hyphen in mid.</li>
          </v-tooltip>
        </span>
        <v-text-field class = "mt-0" outlined placeholder = "Microservice Name"
          v-model.trim = "regmicroalldata.name"
          @blur = "githubdetailscheck(regmicroalldata.name,'name');micronamecheck('name')"
        ></v-text-field>
        <span v-if = "!regmicroalldata.name && !validForm" style="color:red;">{{ labels.NAMER }}</span>
        <span v-if = "regmicroalldata.name && validname_msg" style="color:red;">Please enter the valid name</span>
        <span v-if = "regmicroalldata.name && response_message" style="color:red;">Microservice name already exists</span>
      </v-col>
      <v-col cols="12" clas = "pt-0">
        <label>{{ labels.DESC }}</label>
        <mavon-editor v-model = "regmicroalldata.description" class = "mt-0" language = "en" outlined name = "input-7-1"
          rows = "3" :maxlength="1500" :counter="1500" />
        <span v-if="regmicroalldata.description.length" style="float: right; padding-top:10px;">{{regmicroalldata.description.length}}/1500</span>
        <span v-if = "!regmicroalldata.description && !validForm" style = "color:red;">{{ labels.DESCR}}</span>
      </v-col>
      <v-col cols = "12" v-if="regmicroalldata.microservice_type == 'non data microservice'">
        <label class = "model-img">Docker Image</label>
        <span class = "pl-2 pt-3 tool-tip-icon">
          <v-tooltip top max-width="200">
            <template v-slot:activator = "{ on, attrs }">
              <v-icon dark v-bind = "attrs" v-on="on">
                fa-question-circle
              </v-icon>
            </template>
            <span>{{ labels.MODEL_MSG }}</span>
          </v-tooltip>
        </span>
        <v-radio-group v-model = "regmicroalldata.deploy_type" row class = "mt-0">
          <v-radio label = "Model Image" value = "modelimage"></v-radio>
          <span class = "pl-0 pr-2 mr-0 mt-1 tool-tip-icon">
            <v-tooltip top max-width = "200">
              <template v-slot:activator = "{ on, attrs }">
                <v-icon dark v-bind = "attrs" v-on="on">
                  fa-question-circle
                </v-icon>
              </template>
              <span>Upload only tar file</span>
            </v-tooltip>
          </span>
          <v-radio label = "Github Repo" value = "githubrepo" @click="removedetails"></v-radio>
          <v-radio label = "Api Ready" value = "api_ready" @click="removedetails"></v-radio>
        </v-radio-group>
        <v-row v-if="regmicroalldata.deploy_type == 'modelimage'">
          <v-col cols = "12" sm = "12" class="pl-0">
            <div class = "" >
              <div class = "d-flex flex-column" style = "width: 135px">
                <div class = "position-relative pt-3"
                  v-if = "!regmicroalldata.docker_image_file && !regmicroalldata.docker_image_url" @dragover.prevent = "dragOver"
                  @dragleave.prevent = "dragLeave" @drop.prevent = "drop($event, 'icon')">
                  <div class = "text-center d-flex-column justify-center fileBox">
                    <v-icon class = "secondary--text"> mdi-cloud-upload</v-icon>
                    <div>Upload</div>
                  </div>
                  <input type = "file" class = "clicktofileUpload overflow-hidden w-px h-px opacity-0"
                    @change="modelImageFileChange($event.target.files, 'icon')" accept=".zip" />
                </div>
              </div>
              <p class = "shrink rounded  fileimgWidth logoBoxSize mt-1" v-if = "regmicroalldata.docker_image_url" alt = "Model file"
                contain aspect-ratio = "2">{{ regmicroalldata.docker_image_url }}
                <button class = "pos mclose-iconPos" type = "button" @click = "clearIcon" title = "Remove file">
                  <v-icon color = "error">mdi-close-circle-outline </v-icon>
                </button>
              </p>
              <span v-if = "!regmicroalldata.docker_image_url && !validForm" style = "color:red;">{{ labels.MODIMAGER }}</span>
              <!-- <span v-if = "regmicroalldata.docker_image_url && !regmicroalldata.docker_image_url.endsWith('.tar') && !validForm" style = "color:red;">Please upload valid file type</span> -->
            </div>
          </v-col>
        </v-row>
        <v-row v-if="regmicroalldata.deploy_type == 'githubrepo' || regmicroalldata.deploy_type == 'api_ready'">
          <v-col cols = "12" sm = "12"  class="pl-0 pr-0">
            <label>Github Url</label>
            <v-text-field 
              class = "mt-0" 
              outlined 
              v-model.trim = "regmicroalldata.github_repo_detail.githuburl"
              @blur= "gitaccesscheck(regmicroalldata.github_repo_detail.githuburl)"
            >
            </v-text-field>
            <span v-if = "!regmicroalldata.github_repo_detail.githuburl && !validForm" style = "color:red;padding-left: 0px;">Github Url is required</span>
          </v-col>
          <v-col cols="12" sm="12"  v-if="regmicroalldata.deploy_type  == 'api_ready'" class="pl-0 pr-0">
            <label>Main Filename</label>
            <v-text-field class="mt-0" outlined
              v-model.trim = "regmicroalldata.github_repo_detail.filename" 
              @blur="githubdetailscheck(regmicroalldata.github_repo_detail.filename,'filename')" >
            </v-text-field>
            <span v-if="validfilename_msg && regmicroalldata.github_repo_detail.filename"
                style="color:red;padding-left: 0px;">Enter the valid Filename with .py
                extension</span>
                <span v-if = "regmicroalldata.github_repo_detail.filename && !validForm  && validfilename_msg" style = "color:red;padding-left: 0px;">Enter correct file name</span>
                <span v-if = "!regmicroalldata.github_repo_detail.filename && !validForm" style = "color:red;padding-left: 0px;">Filename is required</span>
          </v-col>
          <v-col cols = "12" sm = "6" class="pl-0">
            <label>Requirements File Path</label>
            <span class = "pl-2 pt-3 tool-tip-icon">
              <v-tooltip top max-width = "200">
                <template v-slot:activator = "{ on, attrs }">
                  <v-icon dark v-bind = "attrs" v-on="on">
                    fa-question-circle
                  </v-icon>
                </template>
                <span>This file should contain all the python package</span>
              </v-tooltip>
            </span>
            <v-text-field
              class = "mt-0"
              outlined
              v-model.trim = "regmicroalldata.github_repo_detail.requirementspath"
              @blur = "githubdetailscheck(regmicroalldata.github_repo_detail.requirementspath,'requirement')"
            ></v-text-field>   
            <span v-if = "!regmicroalldata.github_repo_detail.requirementspath && !validForm" style = "color:red;padding-left: 0px;">Requirementspath is required</span>           
            <span v-if = "validpath_msg && regmicroalldata.github_repo_detail.requirementspath" style = "color:red;padding-left: 0px;">Enter the valid requirements with .txt extension</span>
          </v-col>
          <v-col cols = "12" sm="6" class="pr-0">
            <label>Base Version</label>
            <v-select
                outlined
                v-model.trim = "regmicroalldata.github_repo_detail.baseversion"
                :items = "baseversionitem"
                item-text = "value"
                item-value = "value"
                placeholder = "select version"
                @change = "addothersversion(regmicroalldata.github_repo_detail.baseversion)"
              ></v-select>   
              <span v-if = "!regmicroalldata.github_repo_detail.baseversion && !validForm" style = "color:red;padding-left: 0px;">Baseversion is required</span>           
          </v-col>
        </v-row>
      </v-col>
      <v-col cols = "12" class = "pa-0">
        <v-row>
          <v-col cols = "12">
            <h3>{{ labels.RESD }}</h3>
          </v-col>
          <v-col cols = "12" sm = "8" class = "d-flex">
            <div class = "mr-12">
              <label>{{ labels.CPU }}</label>
              <v-text-field class = "mt-0" outlined v-model.trim = "regmicroalldata.resource_cpu"
                type = "number" ref = "input" step = "0.1" min = "0.2">
              </v-text-field>
              <span v-if = "!regmicroalldata.resource_cpu && !validForm" style = "color:red;">{{ labels.CPUR }}</span>
              <span v-if = "datacheck.cpu < regmicroalldata.resource_cpu && !validForm && regmicroalldata.resource_cpu"
                style = "color:red;">{{ labels.ISCPU }}</span>
              <span v-if = " regmicroalldata.resource_cpu < 0.2 && !validForm && regmicroalldata.resource_cpu"
                style = "color:red;">CPU value should be minimum 0.2</span>
            </div>
            <div class = "ml-12">
              <label>{{ labels.MEM }}</label>
              <v-text-field class = "mt-0" outlined v-model.trim = "regmicroalldata.resource_memory"
                type = "number" ref = "input" step = "0.1" min = "0.5">
              </v-text-field>
              <span v-if = "!regmicroalldata.resource_memory && !validForm" style = "color:red;">{{ labels.MEMR }}</span>
              <span v-if = "datacheck.memory < regmicroalldata.resource_memory && !validForm && regmicroalldata.resource_memory"
                style = "color:red;">{{ labels.ISMEM }}</span>
              <span v-if = "regmicroalldata.resource_memory < 0.5 && !validForm && regmicroalldata.resource_memory"
                style = "color:red;">Memory value should be minimum 0.5</span>
            </div>
          </v-col>
          <v-col class = "pl-8" cols = "12" sm = "4">
            <label class = "ml-1 model-img">{{ labels.GPU }}</label>
            <span class = "pt-3 pl-2 tool-tip-icon">
              <v-tooltip top max-width="200">
                <template v-slot:activator = "{ on, attrs }">
                  <v-icon dark v-bind = "attrs" v-on = "on">
                    fa-question-circle
                  </v-icon>
                </template>
                <span>{{ labels.GPU_MSG }}</span>
              </v-tooltip>
            </span>
            <v-radio-group v-model = "regmicroalldata.enable_gpu" row class = "mt-0">
              <v-radio label = "Yes" value = "yes"></v-radio>
              <v-radio label = "No" value = "no"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols = "12" sm = "6" class = "pl-3" v-if="regmicroalldata.deploy_type != 'githubrepo' && regmicroalldata.microservice_type == 'non data microservice'">
        <label class = "model-img">{{ labels.PORT }}</label>
        <span class = "pl-2 pt-3 tool-tip-icon">
          <v-tooltip top max-width = "200">
            <template v-slot:activator = "{ on, attrs }">
              <v-icon dark v-bind = "attrs" v-on = "on">
                fa-question-circle
              </v-icon>
            </template>
            <span>{{ labels.PORT_MSG }}</span>
          </v-tooltip>
        </span>
        <v-text-field class = "0 port_txt" v-model = "regmicroalldata.port" outlined
          oninput = "this.value=this.value.replace(/[^0-9]/g,'');"></v-text-field>
        <span v-if = "!regmicroalldata.port && !validForm" style = "color:red;">{{ labels.PORTR }}</span>
        <span v-if = "(regmicroalldata.port <= 0 || regmicroalldata.port > 65535)  && regmicroalldata.port" style = "color:red;">Port value should be 1 to 65535</span>
      </v-col>
      <v-col cols = "12"  v-if="regmicroalldata.microservice_type == 'non data microservice'">
        <v-row>
          <v-col cols = "12" class = "pl-0 pb-0">
            <label>{{ labels.EV }}</label>
          </v-col>
          <v-col cols = "12" sm = "4" class = "px-5 pb-5 secondary lighten-3">
            {{ labels.EVK }}
            <v-text-field outlined  v-model = "requestEnvObject.key"></v-text-field>
          </v-col>
          <v-col cols = "12" sm = "4" class = "px-5 pb-5 secondary lighten-3">
            {{ labels.EVV }}
            <div>
              <v-text-field outlined v-model = "requestEnvObject.value"></v-text-field>
            </div>
          </v-col>
          <v-col cols = "12" sm = "2" class = "px-5 pb-0 secondary lighten-3">
            Secret Value
            <v-checkbox
              class="mt-0"
              v-model="requestEnvObject.secret"
            ></v-checkbox>
          </v-col>
          
          <v-col cols = "12" xs = "12" sm = "2" md = "2" lg = "2" class = "px-5 pb-5 secondary lighten-3">
            <div class = "d-flex flex-column px-2">
              <span class = "ml-2">
                <v-btn class = "nocap primary white--text" style = "height: 41px; margin-top:21px;"
                  :disabled = "!requestEnvObject.key || !requestEnvObject.value" @click.prevent = "addEnVariable">
                  <span>
                    <v-tooltip top max-width = "200">
                      <template v-slot:activator = "{ on, attrs }">
                        <v-icon dark v-bind = "attrs" v-on = "on">
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>Add Environment Variable</span>
                    </v-tooltip>
                  </span>
                </v-btn>
              </span>
            </div>
          </v-col>
          <v-col cols = "12" class = "px-0 lighten-3 my-4" v-if = "regmicroalldata.env_variables.length">
            <v-card :elevation="hover ? 24 : 2" style = "width: 100%">
              <v-simple-table >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class = "text-left">
                        <span class = "font-weight-boald subtitle-1">{{ labels.EVK }}</span>
                      </th>
                      <th class = "text-left">
                        <span class = "font-weight-boald subtitle-1">{{ labels.EVV }}</span>
                      </th>
                      <th class = "text-left">
                        <span class = "font-weight-boald subtitle-1">{{ labels.DEL }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for = "(req, index) in regmicroalldata.env_variables" :key = "req.index">
                      <td>{{ req.key }}</td>
                      <td>
                        <span v-if="req.secret">
                          <span v-if="!req.show">{{ req.maskvalue }}
                            <v-btn icon @click = showValue(index) ><v-icon>mdi-eye-off-outline</v-icon></v-btn>
                          </span>
                          <span v-else>{{req.value}}
                            <v-btn icon @click = hideValue(index)><v-icon>mdi-eye-outline</v-icon></v-btn>
                          </span>
                        </span>
                        <span v-else>{{ req.value }}</span>
                      </td>
                      <td>
                        <v-icon color = "error" @click = "removeRequestVariable(index)">mdi-trash-can
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols = "12"  v-if="regmicroalldata.microservice_type == 'non data microservice'">
        <v-row>
          <v-col cols = "12" sm = "12" class = "pl-0 pr-0" >
            <label>Volumes Upload</label>
            <v-radio-group v-model = "regmicroalldata.model_available" row class = "mt-0">
              <v-radio label = "Yes" value = "yes"></v-radio>
              <v-radio label = "No" value = "no"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols = "12" sm = "6" class = "pl-0 pr-0" v-if = "regmicroalldata.model_available=='yes'" >
            <label>Volume File Upload</label>
            <div>
              <div class = "d-flex flex-column" style = "width: 135px">
                <div class = "position-relative pt-3"
                  v-if = "!regmicroalldata.model_image_file && !regmicroalldata.model_image_url" @dragover.prevent = "dragOver"
                  @dragleave.prevent = "dragLeave" @drop.prevent = "uploaddrop($event, 'icon')">
                  <div class = "text-center d-flex-column justify-center fileBox">
                    <v-icon class = "secondary--text"> mdi-cloud-upload</v-icon>
                    <div>Upload</div>
                  </div>
                  <input type = "file" class = "clicktofileUpload overflow-hidden w-px h-px opacity-0" v-if = "regmicroalldata.storage !=''"
                    @change="modelUploadFileChange($event.target.files, 'icon')" accept=".zip" />
                </div>
              </div>
              <p class = "shrink rounded  fileimgWidth logoBoxSize mt-1 pr-10" v-if = "regmicroalldata.model_image_url" alt = "Model update file"
                contain aspect-ratio = "2" style="word-break: break-all">{{ regmicroalldata.model_image_url }}
                <button class = "pos close-iconPos pl-0" type = "button" @click = "clearIcons" title = "Remove file">
                  <v-icon color = "error">mdi-close-circle-outline </v-icon>
                </button>
              </p>
            </div>
            <span v-if = "!regmicroalldata.model_image_url && !validForm" style = "color:red;">Volumes file is required</span>
          </v-col>
          <v-col cols = "12" sm = "6" class = "pl-2 pr-0" v-if = "regmicroalldata.model_available=='yes'">
            <div>
              <label>Volumes Path</label>
              <span class = "pl-2 pt-3 tool-tip-icon">
                <v-tooltip top max-width = "200">
                  <template v-slot:activator = "{ on, attrs }">
                    <v-icon dark v-bind="attrs" v-on = "on">
                      fa-question-circle
                    </v-icon>
                  </template>
                  <span>Path for volumes uploaded</span>
                </v-tooltip>
              </span>
              <v-text-field outlined 
                v-model.trim = "regmicroalldata.volumes_path"
              ></v-text-field>
            </div>
            <span v-if = "!regmicroalldata.volumes_path && !validForm" style = "color:red;">Volumes path is required</span>
            <div class="mt-5">
              <label>Volumes Path Variable(Optional)</label>
              <span class = "pl-2 pt-3 tool-tip-icon">
                <v-tooltip top max-width = "200">
                  <template v-slot:activator = "{ on, attrs }">
                    <v-icon dark v-bind="attrs" v-on = "on">
                      fa-question-circle
                    </v-icon>
                  </template>
                  <span>Variable containing path location</span>
                </v-tooltip>
              </span>
              <v-text-field class = "mb-5" outlined
                v-model.trim = "regmicroalldata.volumes_path_variable"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import API_Util from "../../services/util/API_Util.js";
export default {
  name : "regMsDetails",
  props : ['regmicroalldata', 'validForm', 'labels', 'removedetails', 'response_message', 'datacheck', 'githubdetailscheck', 'validname_msg', 'validpath_msg', 'baseversionitem', 'addothersversion','micronamecheck','gitaccesscheck','github_access','datatypeitem','validfilename_msg'],
  data: () => ({
    apiKey : "",
    requestEnvObject : {
      key:"",
      value:"",
      secret:false,
      maskvalue:"",
      show:false,
    },
    index_property :{},
    requirement_fields : "",
    package_name : "",
    package_version : "",
    upload_count : 0,
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
    if (this.regmicroalldata.id == 0){
      this.regmicroalldata.deploy_type = "modelimage";
    }
    this.requirement_fields = "no";
    this.microservicetype=["data microservice","non data microservice"];
  },
  methods: {
    addEnVariable() {
      this.requestEnvObject.maskvalue = this.requestEnvObject.value.replace(/./g, "*");
      this.regmicroalldata.env_variables.push(this.requestEnvObject);
      this.requestEnvObject = { key:"", value:"", secret:false, maskvalue:"", show:false};
    },
    removeRequestVariable(index) {
      this.regmicroalldata.env_variables.splice(index, 1);
    },
    removeitem(array,index){
      if (index !== -1) {
        array.splice(index, 1);
      }
    },
    showValue(index){
      this.regmicroalldata.env_variables[index].show = true;
    },
    hideValue(index){
      this.regmicroalldata.env_variables[index].show = false;
    },
    clearIcon() {
      this.regmicroalldata.docker_image_file = "";
      this.regmicroalldata.docker_image_url = "";
      this.regmicroalldata.docker_image_source ="";
      this.regmicroalldata.docker_image_status ="";
    },
    clearIcons() {
      this.regmicroalldata.model_image_url = "";
      this.regmicroalldata.model_image_file = "";
      this.regmicroalldata.model_image_source ="";
      this.regmicroalldata.model_image_status ="";
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    modelImageFileChange(files, input) {
      if (input === "icon") {
        this.regmicroalldata.docker_image_url = files[0].name;
        this.regmicroalldata.docker_image_file = files[0];
      }
    },
    modelUploadFileChange(files, input) {
      if (input === "icon") {
        this.regmicroalldata.model_image_file = files[0];
        this.regmicroalldata.model_image_url = files[0].name;
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event, input) {
      event.preventDefault();
      this.modelImageFileChange(event.dataTransfer.files, input)
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    uploaddrop(event, input) {
      event.preventDefault();
      this.modelUploadFileChange(event.dataTransfer.files, input)
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
};
</script>
<style>
.v-dialog {
  box-shadow : none !important;
}
input[type=number]::-webkit-inner-spin-button {
    opacity : 1
}
</style>
<style scoped>
.v-input__slot {
  min-height : 40px !important;
}

.fileimgWidth {
  max-width : fit-content;
  position : relative;
  margin-bottom : 0;
}

.msForm {
  padding : 0 24px 40px;
}

.close-iconPos {
  position : absolute;
  right : 15px;
  top : -2px;
}
.mclose-iconPos {
  position : absolute;
  right : -30px;
  top : -2px;
}

.model-img .v-icon {
  font-size: 12px;
  background: #c1c1c1;
  border-radius: 50%;
  padding: 2px;
  color: #fff;
  margin-top: -2px;
  margin-left: 5px;
}

.icon_relates {
  position: relative;
}
.source_icon_position {
  position: absolute;
  top: 538px;
  right: 600px;
}
.port_txt{
  padding-right:10px
}
.api_txt{
  padding-left:10px;
  padding-right:0px;
}
.tool-tip-icon {
  font-size: 15px !important;
  color: var(--v-primarytextcolor-darken1) !important;
  cursor: pointer !important;
}

.gpu_icon_position {
  position: absolute;
  top: 773px;
  right: 230px;
}
.icon-items{
  color: red;
  size: Small;

}

.port_icon_position {
  position: absolute;
  top: 88%;
  right: 85%
}
.active{
  margin-right:0px;
}

/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .msForm {
    padding: 0;
  }
}</style>
