<template>
  <v-card class = "ms-title">
    <v-card-title class = "text-center">
      <div class = "py-2">
        <h3>{{ labels.EDITMS }}</h3>
      </div>
    </v-card-title>
    <v-divider class = "my-4"></v-divider>
    <v-card-text class = "px-6 pb-0">
      <!-- EXPANSION Basic Info  PANEL -->
      <v-col cols = "12" class = "panelBorder pa-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class = "pl-0 secondary--text text--darken-4">{{ labels.MSD }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="">
              <regMsDetails
                :regmicroalldata = "regmicroalldata" :validForm = "validForm" :labels = "labels"
                :githubdetailscheck = "githubdetailscheck"
                :validname_msg = "validname_msg" :validfilename_msg = "validfilename_msg"
                :validpath_msg = "validpath_msg"
                :baseversionitem = "baseversionitem"
                :addothersversion = "addothersversion"
                :gitaccesscheck = "gitaccesscheck" :removedetails="removedetails"
                :datacheck = "datacheck" :response_message = "response_message" :github_access="github_access" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols = "12" class = "panelBorder pa-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class = "pl-0 secondary--text text--darken-4">{{ labels.APID }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <regApiDetails :regmicroalldata = "regmicroalldata"
                :validForm = "validForm" :labels = "labels" :githubdetailscheck="githubdetailscheck" 
                :validfilename_msg = "validfilename_msg"
                :response_message = "response_message" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols = "12" class = "panelBorder pa-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class = "pl-0 secondary--text text--darken-4">{{ labels.DOCD }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <regDocsDetails :regmicroalldata = "regmicroalldata" :validForm = "validForm" 
              :labels = "labels" :datatypeitem = "datatypeitem" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-card-text>
    <v-divider></v-divider>
    <!------------------- Version update popup start------------------>
    <v-row justify="center">
        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent"
            v-model="description" persistent max-width="300px" content-class="vDialog">
            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                style="background-color: transparent">
                <v-card-text class="text-center white rounded-lg" style="height: 180px">
                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                        <div class="mt-0 mb-0" text-color="error">Alert</div>
                    </v-card-title>
                    <p class="mt-0" text-color="error">Please describe the version changes</p>
                    <v-card-actions>
                        <div class="text-center mt-0 pb-0">
                            <v-btn class="nocap" color="error" @click="closepopup">{{ labels.OK }}
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
    <!----------------------------- end ------------------------->

    <!------------------- describe popup start------------------>
    <v-row justify="center">
        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent"
            v-model="versiondetailupdate" persistent max-width="300px" content-class="vDialog">
            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                style="background-color: transparent">
                <v-card-text class="text-center white rounded-lg" style="height: 180px">
                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                        <div class="mt-0 mb-0" text-color="error">Alert</div>
                    </v-card-title>
                    <p class="mt-0" text-color="error"> Please update the version details</p>
                    <v-card-actions>
                        <div class="text-center mt-0 pb-0">
                            <v-btn class="nocap" color="error" @click="closepopup">{{ labels.OK }}
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
    <!----------------------------- end ------------------------->
    <v-card-actions>
      <div class = "pl-10 justify-center" style = "display: flex !important;">
        <v-btn class = "nocap" @click = "closeRegMsEdit" text>
          <span>{{ labels.CANCEL }}</span>
        </v-btn>
        <v-btn class = "nocap primary" text @click = "regMsEditSave()">
          <span>{{ labels.SAVE }}</span>
        </v-btn>
        <v-checkbox
          v-if = "regmicroalldata.approval_status == 'Approved' || regmicroalldata.approval_status == 'Pending'"
          class = "ml-4  mr-4"
          style = "margin-top:-5px;"
          v-model = "regmicroalldata.auto_deploy"
          label = "Autodeploy"
          value = "yes"
        ></v-checkbox>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import regMsDetails from "./regMsPopUp/regMsDetails.vue";
import regApiDetails from "./regMsPopUp/regApiDetails.vue";
import regDocsDetails from "./regMsPopUp/regDocsDetails.vue";
export default {
  components: {
    regMsDetails,
    regApiDetails,
    regDocsDetails
  },
  name: "registerMsEditPage",
  props: ['regmicroalldata', 'validForm', 'labels', 'datacheck', "response_message", 'githubdetailscheck', 'validname_msg', 'validfilename_msg', 'validpath_msg', 'baseversionitem', 'datatypeitem', 'addothersversion','gitaccesscheck','github_access','removedetails'],
  data: () => ({
    checkbox: true,
    versiondetailupdate:false,
    description:false,
  }),
  created() {
    this.validForm = true;
  },
  methods: {
    closeRegMsEdit() {
      this.$emit('closeRegMsEdit', false);
    },
    closepopup(){
      this.versiondetailupdate = false;
      this.description = false;
    },
    regMsEditSave() {
      this.regmicroalldata.update_resource_memory = this.regmicroalldata.resource_memory-this.regmicroalldata.update_resource_memory;
      this.regmicroalldata.update_resource_cpu = this.regmicroalldata.resource_cpu-this.regmicroalldata.update_resource_cpu;
      this.regmicroalldata.update_resource_gpu = this.regmicroalldata.resource_gpu-this.regmicroalldata.update_resource_gpu;
      this.regmicroalldata.newversion = Number(this.regmicroalldata.major+this.regmicroalldata.minor+this.regmicroalldata.patch);
      
      if (this.regmicroalldata.name == "" || this.validname_msg) {
        this.validForm = false;
      }else if(this.regmicroalldata.description == ""){
        this.validForm = false;
      } else if (this.regmicroalldata.microservice_type =='non data microservice' && this.regmicroalldata.deploy_type == "modelimage" && this.regmicroalldata.docker_image_url == "") {  
        this.validForm = false;
      } else if (this.regmicroalldata.microservice_type =='non data microservice' && this.regmicroalldata.deploy_type == "githubrepo" && ((this.regmicroalldata.github_repo_detail.githuburl == "")||this.regmicroalldata.github_repo_detail.baseversion == "" || (this.regmicroalldata.github_repo_detail.requirementspath == "" || this.validpath_msg))) { 
        this.validForm = false;
      } else if (this.regmicroalldata.microservice_type =='non data microservice' && this.regmicroalldata.deploy_type == "api_ready" && ((this.regmicroalldata.github_repo_detail.githuburl == "")||this.regmicroalldata.github_repo_detail.baseversion == "" || (this.regmicroalldata.github_repo_detail.filename == "" || this.validfilename_msg) || (this.regmicroalldata.github_repo_detail.requirementspath == "" || this.validpath_msg))) {  
        this.validForm = false;
      } else if (this.regmicroalldata.resource_cpu == "" || this.regmicroalldata.resource_cpu < 0.2)  { 
        this.validForm = false;
      } else if (this.regmicroalldata.resource_memory == "" || this.regmicroalldata.resource_memory < 0.5 ) { 
        this.validForm = false;
      } else if (this.regmicroalldata.enable_gpu == "") {
        this.validForm = false;
      } else if(this.regmicroalldata.microservice_type =='data microservice' && this.regmicroalldata.endpoint.length == 0){ 
        this.validForm = false;
      }else if (this.regmicroalldata.microservice_type =='non data microservice' && this.regmicroalldata.api_details[this.regmicroalldata.deploy_type].length == 0) {  
        this.validForm = false;
      }else if(this.datacheck.memory < this.regmicroalldata.update_resource_memory || this.datacheck.cpu < this.regmicroalldata.update_resource_cpu){
        this.validForm = false;
      }else if(this.regmicroalldata.model_available == "yes" && (this.regmicroalldata.model_image_url !="" && this.regmicroalldata.model_image_source =="" || this.regmicroalldata.volumes_path =="")){  
        this.validForm = false;
      }else if (this.regmicroalldata.approval_status == "Approved"  && (this.regmicroalldata.newversion <= this.regmicroalldata.concat_version)){ 
        this.validForm = false;
        this.versiondetailupdate = true;
      }else if(this.regmicroalldata.approval_status=="Approved" && !(this.regmicroalldata.newversion <= this.regmicroalldata.concat_version) && this.regmicroalldata.apidetails_desc ==""){ 
        this.description = true;
        this.validForm = false;
      } else {
        this.$emit("regMsEditSave");
      }
    },
  },
};
</script>

<style>
</style>
