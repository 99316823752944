<template>
    <div class="msForm">
        <v-row>
            <v-col cols="12" >
                <v-row >
                    <v-col cols="12" :sm="regmicroalldata.microservice_type =='non data microservice' ?'12':'6'" class="textcolor">
                        <label>Function Code</label>
                        <v-text-field outlined :disabled="regmicroalldata.id == 0 ||(regmicroalldata.id > 0 && (regmicroalldata.deployment_type == 'new'|| regmicroalldata.approval_status !='approved'))? false : true"
                            v-model.trim="regmicroalldata.microservice_code" @blur = "micronamecheck('code')"
                            placeholder="Enter the function code..." :prefix="regmicroalldata.teamspace_code"
                            class="mt-0 prefix_input"></v-text-field>
                        <span style="color:red" v-if="regmicroalldata.microservice_code =='' && !validForm">Microservice code is required</span>
                        <span v-if = "regmicroalldata.microservice_code && response_message" style="color:red;">Microservice code already exists</span>
                    </v-col>
                    <v-col cols="12" sm="6" v-if ="regmicroalldata.microservice_type=='data microservice'">
                        <label>{{ labels.RT }}</label>
                        <v-text-field outlined :disabled="regmicroalldata.id == 0 ||(regmicroalldata.id > 0 && regmicroalldata.deployment_type == 'new') || (regmicroalldata.id > 0 && regmicroalldata.deployment_type == 'upgrade' && !enableedit) ? false : true"
                            v-model.trim = "regmicroalldata.result_type"
                            class="mt-0"></v-text-field>
                    </v-col>
                    <v-col cols="12" :sm="regmicroalldata.microservice_type =='non data microservice' ?'4':'3'" >
                        <label>{{ labels.MAJVER }}</label>
                        <span class="pl-2 pt-6 tool-tip-icon">
                            <v-tooltip top max-width="300px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon dark v-bind="attrs" v-on="on">
                                        fa-question-circle
                                    </v-icon>
                                </template>
                                <span>{{ labels.MAJVER_MSG }}</span>
                            </v-tooltip>
                        </span>
                        <v-text-field outlined v-model.trim="regmicroalldata.major" type="number" ref="input"
                            step="1" :min="regmicroalldata.id == 0 ? '1' : regmicroalldata.oldmajor"
                            :disabled="regmicroalldata.id == 0 ||(regmicroalldata.id > 0 && (regmicroalldata.deployment_type == 'new'|| regmicroalldata.approval_status !='approved')) ? false : true"
                            oninput="this.value = this.value.replace(/[^1-9]/g,'');"
                            class="mt-0"></v-text-field>
                        <span style="color:red"
                            v-if="regmicroalldata.major !='' && regmicroalldata.major < regmicroalldata.oldmajor && !validForm">New version
                            must be greater than old version</span>
                        <span style="color:red" v-if="regmicroalldata.major =='' && !validForm">Major version is required</span>
                    </v-col>
                    <v-col cols="12" :sm="regmicroalldata.microservice_type =='non data microservice' ?'4':'3'">
                        <label>{{ labels.MINVER }}</label>
                        <span class="pl-2 pt-3 tool-tip-icon">
                            <v-tooltip top max-width="300px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon dark v-bind="attrs" v-on="on">
                                        fa-question-circle
                                    </v-icon>
                                </template>
                                <span>{{ labels.MINVER_MSG }}</span>
                            </v-tooltip>
                        </span>
                        <v-text-field outlined v-model.trim="regmicroalldata.minor" type="number" ref="input"
                            step="1" :min="regmicroalldata.id == 0 ? '0' : regmicroalldata.oldminor"
                            oninput="this.value = this.value.replace(/[^0-9]/g,'');"
                            class="mt-0"></v-text-field>
                        <span style="color:red"
                            v-if="regmicroalldata.minor !='' && regmicroalldata.minor < regmicroalldata.oldminor && !validForm">New version
                            must be greater than old version</span>
                        <span style="color:red" v-if="regmicroalldata.minor =='' && !validForm">Minor version is required</span>
                    </v-col>
                    <v-col cols="12" :sm="regmicroalldata.microservice_type =='non data microservice' ?'4':'3'">
                        <label>{{ labels.PATVER }}</label>
                        <span class="pl-2 pt-3 tool-tip-icon">
                            <v-tooltip top max-width="300px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon dark v-bind="attrs" v-on="on">
                                        fa-question-circle
                                    </v-icon>
                                </template>
                                <span>{{ labels.PATVER_MSG }}</span>
                            </v-tooltip>
                        </span>
                        <v-text-field outlined v-model.trim="regmicroalldata.patch" type="number" ref="input"
                            step="1" :min="regmicroalldata.id == 0 ? '0' : regmicroalldata.oldpatch"
                            oninput="this.value = this.value.replace(/[^0-9]/g,'');"
                            class="mt-0"></v-text-field>
                        <span style="color:red"
                            v-if="regmicroalldata.patch !='' && regmicroalldata.patch < regmicroalldata.oldpatch && !validForm">New version
                            must be greater than old version</span>
                        <span style="color:red" v-if="regmicroalldata.patch =='' && !validForm">Patch version is required</span>
                    </v-col>
                    <v-col cols="12" sm="3" v-if ="regmicroalldata.microservice_type=='data microservice'">
                        <div class="d-flex flex-column px-2 ">
                            <span class="ml-10">
                                <v-btn class="nocap primary white--text" style="height: 40px; margin-top:25px;"
                                    :disabled="!regmicroalldata.teamspace_code ||
                                        !regmicroalldata.microservice_code ||
                                        !regmicroalldata.result_type||
                                        !regmicroalldata.major ||
                                        !regmicroalldata.patch ||
                                        !regmicroalldata.patch
                                        " @click.prevent="addapidetails">
                                    <span>
                                        <v-tooltip top max-width="200">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    mdi-link-plus
                                                </v-icon>
                                            </template>
                                            <span>Add End point</span>
                                        </v-tooltip>
                                    </span>
                                </v-btn>
                            </span>
                        </div>
                    </v-col>
                    <v-col cols = "12"  v-if="regmicroalldata.microservice_type == 'data microservice' && this.regmicroalldata.endpoint !=''">
                        <v-row>
                            <v-col cols = "12" class = "pl-0 pb-0">
                                <label>End Point</label>
                            </v-col>
                            <v-col cols = "12" sm = "8" class = "px-5 pb-5 secondary lighten-3">
                                Endpoint
                                <div class = "pt-2">
                                    {{ this.regmicroalldata.endpoint }}
                                </div>
                            </v-col>
                            <v-col cols = "12" sm = "2" class = "px-5 pb-5 secondary lighten-3">
                                Edit
                                <div>
                                    <v-icon class = "pt-2" @click="editendpoint()">
                                        mdi-square-edit-outline
                                    </v-icon>
                                </div>
                            </v-col>
                            <v-col cols = "12" sm = "2" class = "px-5 pb-5 secondary lighten-3">
                                Delete
                                <div>
                                    <v-icon class = "pt-2" color="error" @click="removeendpoint()">
                                        mdi-trash-can
                                    </v-icon>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" v-if=" regmicroalldata.id > 0 && regmicroalldata.approval_status=='Approved'">
                        <label class="ml-1">Description</label>
                        <v-textarea style="line-height: 0.25rem;" outlined
                            v-model.trim="regmicroalldata.apidetails_desc"
                            :maxlength="150" :counter="150"
                            placeholder="Describe the changes...." class="mt-0 lheight"></v-textarea>
                        <span v-if ="regmicroalldata.apidetails_desc.length" style="float: right; padding-top:10px;">{{regmicroalldata.apidetails_desc.length}}/150</span>
                    </v-col>
                </v-row>
                <v-row class="px-3 pt-8" v-if ="regmicroalldata.microservice_type=='non data microservice'">
                    <v-col cols="12" class="px-6 secondary lighten-3">
                        <v-row class="text-fields-row secondary lighten-3" >
                            <v-col cols="12" sm="6"  v-if="regmicroalldata.deploy_type  == 'githubrepo'">
                                <label>Main Class Name</label>
                                <span class="pl-2 pt-3 tool-tip-icon">
                                    <v-tooltip top max-width="200">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark v-bind="attrs" v-on="on">
                                                fa-question-circle
                                            </v-icon>
                                        </template>
                                        <span>File name with folder</span>
                                    </v-tooltip>
                                </span>
                                <v-text-field class="mt-0" outlined
                                    @blur="githubdetailscheck(regmicroalldata.apidetails.filename,'filename')" 
                                    v-model.trim="regmicroalldata.apidetails.filename">
                                </v-text-field>
                                <span v-if="validfilename_msg && regmicroalldata.apidetails.filename"
                                    style="color:red;padding-left: 0px;">Enter the valid Filename with .py
                                    extension</span>
                            </v-col>
                            <v-col cols="12" sm="6"   v-if="regmicroalldata.deploy_type == 'githubrepo'">
                                <label>Entry Point</label>
                                <span class="pl-2 pt-3 tool-tip-icon">
                                    <v-tooltip top max-width="300">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark v-bind="attrs" v-on="on">
                                                fa-question-circle
                                            </v-icon>
                                        </template>
                                        <li>Input & Output should be JSON format</li>
                                        <li>Enter classname and function name separated by "."
                                            <pre>Ex: "handler.main"</pre>
                                        </li>
                                        <li>If classname not available enter only function name
                                            <pre>Ex: "main"</pre>
                                        </li>
                                    </v-tooltip>
                                </span>
                                <v-text-field class="mt-0" outlined
                                    v-model.trim = "regmicroalldata.apidetails.entrypoint">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <label>{{ labels.RT }}</label>
                                <v-text-field outlined :disabled="regmicroalldata.id == 0 ||(regmicroalldata.id > 0 && regmicroalldata.deployment_type == 'new') || (regmicroalldata.id > 0 && regmicroalldata.deployment_type == 'upgrade' && !enableedit) ? false : true"
                                    v-model.trim = "regmicroalldata.apidetails.result_type"
                                    class="mt-0"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" >
                                <label>API Route</label>
                                <v-text-field outlined @blur="duproutecheck" v-model.trim="regmicroalldata.apidetails.apiroute"
                                :disabled="regmicroalldata.id == 0 ||(regmicroalldata.id > 0 && regmicroalldata.deployment_type == 'new') || (regmicroalldata.id > 0 && regmicroalldata.deployment_type == 'upgrade' && !enableedit) ? false : true"
                                    class="mt-0"></v-text-field>
                                <span v-if="routecheck"
                                    style="color:red;padding-left: 0px;">Api Route Already Exists</span>
                            </v-col>
                            <v-col cols="12" sm="10">
                            <label class="ml-1">Description</label>
                            <v-textarea style="line-height: 0.25rem;" outlined v-model.trim="regmicroalldata.apidetails.description" :maxlength="150" :counter="150"
                                class="mt-0 lheight"></v-textarea>
                            <span v-if ="regmicroalldata.apidetails.description.length" style="float: right; padding-top:10px;" >{{regmicroalldata.apidetails.description.length}}/150</span>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <div class="d-flex flex-column px-2 pr-0">
                                    <span class="ml-2">
                                        <v-btn class="nocap primary white--text" style="height: 40px; margin-top:33px;"
                                            :disabled="!regmicroalldata.major ||
                                                !regmicroalldata.apidetails.result_type ||
                                                !regmicroalldata.apidetails.apiroute||
                                                !regmicroalldata.apidetails.description ||
                                                (regmicroalldata.deploy_type == 'githubrepo' && !regmicroalldata.apidetails.filename ||( regmicroalldata.apidetails.filename && validfilename_msg))||
                                                (regmicroalldata.deploy_type == 'githubrepo' && !regmicroalldata.apidetails.entrypoint)
                                                " @click.prevent="addapidetails">
                                            <span>
                                                <v-tooltip top max-width="200">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon dark v-bind="attrs" v-on="on">
                                                            mdi-link-plus
                                                        </v-icon>
                                                    </template>
                                                    <span>Add End point</span>
                                                </v-tooltip>
                                            </span>
                                        </v-btn>
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-col cols="12" class="px-3 lighten-5 my-4" v-if="regmicroalldata.microservice_type == 'non data microservice' && regmicroalldata.api_details[regmicroalldata.deploy_type].length">
                    <v-card style="width: 100%">
                        <v-simple-table class="mstable">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" style="width: 20%">
                                            <span class="font-weight-boald subtitle-1">Api Route</span>
                                        </th>
                                        <th class="text-left" style="width: 60%">
                                            <span class="font-weight-boald subtitle-1">Endpoint</span>
                                        </th>
                                        <th class="text-left" style="width: 10%">
                                            <span class="font-weight-boald subtitle-1">Edit</span>
                                        </th>
                                        <th class="text-left" style="width: 10%">
                                            <span class="font-weight-boald subtitle-1">{{ labels.DEL }}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(req, index) in regmicroalldata.api_details[regmicroalldata.deploy_type]" :key="index">
                                        <td style="width: 20%">{{ req.apiroute }}</td>
                                        <td style="width: 60%">{{ req.endpoint }}</td>
                                        <td style="width: 10%">
                                            <v-icon @click="editapidetails(req,index)">mdi-square-edit-outline
                                            </v-icon>
                                        </td>
                                        <td style="width: 10%">
                                            <v-icon color="error" @click="removeapidetails(req, index)">mdi-trash-can
                                            </v-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-col>
            <div class="ml-5">
                <span style="color:red" v-if="regmicroalldata.microservice_type == 'non data microservice' && !regmicroalldata.api_details[regmicroalldata.deploy_type].length && !validForm">
                    Api details is required
                </span>
                <span style="color:red" v-if="regmicroalldata.microservice_type == 'data microservice' && regmicroalldata.endpoint =='' && !validForm "> Endpoint is required </span>
            </div>
        </v-row>
    </div>
</template>
  
<script>
export default {
    name: "regApiDetails",
    props: ["regmicroalldata", "validForm", "labels", "response_message",'githubdetailscheck','validfilename_msg','micronamecheck'],
    data: () => ({
        owner: "",
        date: "",
        new_inputdetails: [],
        enableedit:false,
        routecheck: false
    }),
    created() {
        var sessionObj = this.$session.get("user_session");
        this.apiKey = sessionObj.session.userApiKey;
        this.owner = localStorage.getItem("owner");
    },
    methods: {
        addapidetails() {
            if (this.regmicroalldata.id == 0) {
                if ((this.regmicroalldata.major < 1 || this.regmicroalldata.major == "") || (this.regmicroalldata.minor < 0 || this.regmicroalldata.minor == "") || (this.regmicroalldata.patch < 0 || this.regmicroalldata.patch =="")|| this.regmicroalldata.microservice_code=="" || this.routecheck) {
                    this.validForm = false;
                } else {
                    this.adddetails();
                }
            } else {
                this.enableedit = false;
                if ((this.regmicroalldata.major < 1 || this.regmicroalldata.major == "") || (this.regmicroalldata.minor < 0 || this.regmicroalldata.minor == "") || (this.regmicroalldata.patch < 0 || this.regmicroalldata.patch =="")|| this.regmicroalldata.microservice_code=="" || this.routecheck) {
                    this.validForm = false;
                } else if (this.regmicroalldata.major < this.regmicroalldata.oldmajor || this.regmicroalldata.minor < this.regmicroalldata.oldminor || this.regmicroalldata.patch < this.regmicroalldata.oldpatch || this.regmicroalldata.microservice_code=="" || this.routecheck) {
                    this.validForm = false;
                } else{
                    this.adddetails();
                }
            }
        },
        editendpoint(){
            this.regmicroalldata.result_type = this.regmicroalldata.endpoint.split("/").pop()
            this.regmicroalldata.endpoint ="";
        },
        removeendpoint(){
            this.regmicroalldata.endpoint ="";
        },
        adddetails(){
            this.validForm = true;
            if (this.regmicroalldata.microservice_type=="data microservice"){
                this.regmicroalldata.endpoint = "https://apisa.sentient.io/microservices/custommodels/" + this.regmicroalldata.teamspace_code + this.regmicroalldata.microservice_code + "/" + "v" + this.regmicroalldata.major + "/" + this.regmicroalldata.result_type;
                this.regmicroalldata.result_type ="";
            }else{
                this.regmicroalldata.apidetails.endpoint = "https://apisa.sentient.io/microservices/custommodels/" + this.regmicroalldata.teamspace_code + this.regmicroalldata.microservice_code + "/" + "v" + this.regmicroalldata.major + "/" + this.regmicroalldata.apidetails.result_type;
                this.regmicroalldata.api_details[this.regmicroalldata.deploy_type].push(this.regmicroalldata.apidetails)
                this.regmicroalldata.api_route[this.regmicroalldata.deploy_type].push(this.regmicroalldata.apidetails.apiroute)
                this.regmicroalldata.apidetails = { filename: "", entrypoint: "", apiroute: "/", result_type:"", description:"", endpoint :""}
            }
            
        },
        editapidetails(req,index) {
            this.enableedit = true;
            this.regmicroalldata.apidetails.result_type = this.regmicroalldata.api_details[this.regmicroalldata.deploy_type][index].result_type;
            this.regmicroalldata.apidetails.filename = this.regmicroalldata.api_details[this.regmicroalldata.deploy_type][index].filename;
            this.regmicroalldata.apidetails.entrypoint = this.regmicroalldata.api_details[this.regmicroalldata.deploy_type][index].entrypoint;
            this.regmicroalldata.apidetails.apiroute = this.regmicroalldata.api_details[this.regmicroalldata.deploy_type][index].apiroute;
            this.regmicroalldata.apidetails.description = this.regmicroalldata.api_details[this.regmicroalldata.deploy_type][index].description;
            this.regmicroalldata.api_details[this.regmicroalldata.deploy_type].splice(index, 1);
            var indexs = this.regmicroalldata.api_route[this.regmicroalldata.deploy_type].indexOf(req.apiroute)
            if (indexs !== -1) {
                this.regmicroalldata.api_route[this.regmicroalldata.deploy_type].splice(indexs, 1);
            }
          
        },
        removeapidetails(req, index) {
            this.regmicroalldata.api_details[this.regmicroalldata.deploy_type].splice(index, 1);
            const indexs = this.regmicroalldata.api_route[this.regmicroalldata.deploy_type].indexOf(req.apiroute);
            if (indexs !== -1) {
                this.regmicroalldata.api_route[this.regmicroalldata.deploy_type].splice(indexs, 1);
            }
            if (this.regmicroalldata.input_details.length > 0) {
                this.regmicroalldata.input_details.forEach((item) => {
                    if (item.name != req.apiroute) {
                        this.new_inputdetails.push(item);
                    }
                });
                this.regmicroalldata.input_details = this.new_inputdetails;
                this.new_inputdetails= [] 
            }
            
        },
        duproutecheck() {
            this.routecheck = false;
            if (this.regmicroalldata.api_route[this.regmicroalldata.deploy_type].includes(this.regmicroalldata.apidetails.apiroute)) {
                this.routecheck = true;
            }
        }
    },
};
</script>
  
<style scoped>
.v-input__slot {
    min-height: 40px !important;
}

.endpoint-sec label {
    display: inline-block;
    width: 87%;
}

.width {
    width: 180px;
}

.type {
    padding: 12px 0;
}

.main-version .v-badge {
    left: 8px;
    top: -2px;
}

.mstable .v-data-table__wrapper::-webkit-scrollbar {

    height: 7px;
}

.mstable .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background-color: #CBAC81;
    border-radius: 4px;
}

.icon_relates {
    position: relative;
}
.msForm {
  padding: 0px 40px;
}
.tool-tip-icon {
    font-size: 20px !important;
    color: var(--v-primarytextcolor-darken1) !important;
    cursor: pointer !important;
}

textcolor {
    color: var(--v-primarytextcolor-darken1) !important;
}

::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 0 !important;
}

.table {
    background-color: #CBAC81;
    margin: auto;
    width: 90%;
    max-width: 100%;
    border-collapse: separate;
    display: block;
    overflow-x: scroll;
}

thead {
    position: sticky;
    top: 1px;
    z-index: 5;
}

th {
    background-color: #f1f1f1;
}

td,
th {
    min-width: 100px;
    max-width: 100px;
    word-wrap: break-word;
}
</style>
