<template>
  <div>
    <v-content class="white maincontent">
      <div class="pb-4">
          <div>
            <h2 class="text-center font-weigt-bold">Microservice Index</h2>
          </div>
          <div class="text-right">
            <v-spacer></v-spacer>
          </div>
          <div class="d-flex justify-end mb-1 mr-4 bg-surface-variant">
            <v-btn text @click="newindex()" :disabled="!selectedMicro.length"
                class="tertiary2--text nocap py-5">
                <v-icon class="pr-1 add" style="font-size: 26px">
                    mdi-check-circle
                </v-icon>
                Index
            </v-btn>
        </div>
        <div>
          <v-data-table :mobile-breakpoint="0" v-model="selectedMicro" :headers="header" :items="regmslisting['mslist']"
            item-key="function_id" item-value="function_id" show-select
            class="elevation-1 listingTable">
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-checkbox v-model="selectedMicro" :value="item" style="margin: 0px; padding: 0px" hide-details @click="selected_micro(item)"
                    multiple color="dark-grey" />
                </td>
                <td>{{ item.fun_name }}</td>
                <td>{{ item.index_type }}</td>
                <td>{{ item.fun_desc }}</td>
                <td>{{ item.index_status }} - {{ item.index_message }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>

        <!----------------------------- index popup start------------------------->

        <v-row justify="center">
            <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="indexms"
                persistent max-width="388px" content-class="vDialog">
                <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                    style="background-color: transparent">
                    <v-card-text class="text-center white rounded-lg" style="height: 200px">
                        <v-card-title class="text-h5 text-bold d-flex justify-center">
                            <div class="mt-4">Index</div>
                        </v-card-title>
                        <p v-if="response_msg">{{ response_msg }}</p>
                        <v-card-actions>
                            <div class="text-center">
                                <v-btn class="nocap" color="primary" @click="successPopupClose">Ok
                                </v-btn>
                            </div>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
        <!---------------------------end ----------------------------------->

        <!---------------------------------Upload Ms Start--------------------------------------->
        <v-row justify="center">
            <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="uploadms"
                persistent max-width="388px" content-class="vDialog">
                <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                    style="background-color: transparent">
                    <v-card-text class="text-center white rounded-lg" style="height: 200px">
                        <v-card-title class="text-h5 text-bold d-flex justify-center">
                            <div class="mt-4">File Upload</div>
                        </v-card-title>
                        <p v-if="response_msg">{{ response_msg }}</p>
                        <v-img style="z-index: 1; height: 40px;" alt="sentient logo" 
                          class="mt-2 d-flex align-center justify-center" src="@/assets/loading.gif"
                          contain></v-img>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
        <!------------------------------End ---------------------------------->

        <!---------------------------------successs Start------------------------------------>

        <v-row justify="center">
          <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="successms"
              persistent max-width="388px" content-class="vDialog">
              <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                  style="background-color: transparent">
                  <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                      style="margin-bottom: -19px">
                      <v-img style="z-index: 1;height: 40px;" alt="sentient logo"
                          class="d-flex align-center justify-center" src="@/assets/tick-mark1.svg"
                          contain></v-img>
                  </v-card-title>
                  <v-card-text class="text-center white rounded-lg" style="height: 200px">
                      <v-card-title class="text-h5 text-bold d-flex justify-center">
                          <div class="mt-4">Success</div>
                      </v-card-title>
                      <p v-if="response_msg">{{ response_msg }}</p>
                      <v-card-actions>
                          <div class="text-center">
                              <v-btn class="nocap" color="primary" @click="successPopupClose">Done
                              </v-btn>
                          </div>
                      </v-card-actions>
                  </v-card-text>
              </v-card>
          </v-dialog>
        </v-row>
        <!-----------------------------------end----------------------------------------->

        <!-------------------------------  Error pop up  ------------------------------>
        <v-row justify="center">
          <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="errorms"
              persistent max-width="388" content-class="vDialog">
              <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                  style="background-color: transparent">
                  <v-card-text class="text-center white rounded-lg" style="height: 200px">
                      <v-card-title class="text-h5 text-bold d-flex justify-center">
                          <div class="mt-2">Invalid</div>
                      </v-card-title>
                      <p v-if="response_msg">{{ response_msg }}</p>
                      <v-card-actions>
                          <div class="text-center mt-4">
                              <v-btn class="nocap" color="error" @click="successPopupClose">Ok
                              </v-btn>
                          </div>
                      </v-card-actions>
                  </v-card-text>
              </v-card>
          </v-dialog>
        </v-row>
        <!----------------------------- End ---------------------------->

        <v-dialog v-model="indexdialog" width="1000" scrollable>
            <msindex :regmicroalldata="regmicroalldata" :labels="labels"
                @closemsindex ="closeIndex" :validForm="validForm"
                @closeMsIndexDialog="closeIndexDialog" @indexSave="saveIndex" />
        </v-dialog>
      </div>
    </v-content>
  </div>
</template>
<script>
import API_Util from "../services/util/API_Util.js";
import msindex from "./msindex.vue";
import AWS from 'aws-sdk';
export default {
  components:{
    msindex,
  },
  name: "microserviceIndex",
  data: () => ({
    regmicroalldata:{},
    regmslisting: {},
    selectedMicro: [],
    indexdialog: false,
    selectedmicroitem:[],
    apiKey: "",
    owner:"",
    labels:{},
    response_msg:"",
    uploadms :false,
    indexms:false,
    errorms:false,
    successms:false,
    validForm:true,
    header : [
      {
        text: "NAME",
        align: "start",
        sortable: true,
        value: "fun_name",
      },
      { text: "INDEX TYPE", value: "index_type" },
      { text: "DESCRIPTION", value: "fun_desc" },
      { text: "INDEX STATUS", value: "index_status" },
      
    ],
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
    this.getRegMicroserviceList()
  },
  methods: {
    closeIndex(status){
        this.indexdialog = status;
    },
    closeIndexDialog(status){
      this.indexdialog = status;
    },
    successPopupClose(){
      this.indexms = false;
      this.uploadms = false;
      this.errorms = false;
      this.successms =false;
    },
    async saveIndex(){
      if(!this.regmicroalldata.index_image_url){
        this.validForm = false;
      }else if(this.regmicroalldata.index_property.length == 0){
        this.validForm = false;
      }else{
        this.validForm = true;
        this.indexupload(this.regmicroalldata)
        this.selectedMicro = []
      }
    },
    async indexupload(item){
      this.indexdialog = false;
      this.response_msg = " File is Uploading...."
      this.uploadms = true;
      var file = item.index_image_file
      const s3 = new AWS.S3({
          accessKeyId: "AG26PV05G4CIPMTXJ9K1",
          secretAccessKey: "aRtwZwDq3YOBKAUkzwPhO2Kgzg63enQXfkQU6g7a",
          endpoint: "https://s3.ap-southeast-1.wasabisys.com",
          s3ForcePathStyle: true,
      });
      const bucketName = "register-microservice-models";
      const objectKey = "ts_indexing_files"+"/"+item.microservice_code+".csv";
      try {
          const initiateResponse = await s3.createMultipartUpload({
              Bucket: bucketName,
              Key: objectKey,
          }).promise();

          const uploadId = initiateResponse.UploadId;
          const chunkSize = 250 * 1024 * 1024;
          let start = 0;
          let partNumber = 1;
          while (start < file.size) {
              const end = Math.min(start + chunkSize, file.size);
              const chunk = file.slice(start, end);

              const uploadPartResponse = await s3.uploadPart({
                  Bucket: bucketName,
                  Key: objectKey,
                  PartNumber: partNumber,
                  Body: chunk,
                  UploadId: uploadId,
              }).promise();
              console.log(`Uploaded part ${partNumber}: ${uploadPartResponse.ETag}`);
              start = end;
              partNumber++;
          }
          const listPartsResponse = await s3.listParts({
              Bucket: bucketName,
              Key: objectKey,
              UploadId: uploadId,
          }).promise();
          const parts = listPartsResponse.Parts.map(part => ({
              PartNumber: part.PartNumber,
              ETag: part.ETag,
          }));
          const responsedata = await s3.completeMultipartUpload({
              Bucket: bucketName,
              Key: objectKey,
              UploadId: uploadId,
              MultipartUpload: { Parts: parts },
          }).promise();
          console.log('Multipart upload completed:', responsedata);
          this.uploadms = false;
          this.response_msg = "Successfully Uploaded"
          this.successms = true ;
          var data = { "id": item.id, "index_image_url": item.index_image_url, "index_property":item.index_property,"index_type":"new"}
          const headers = {
              headers: { "x-api-key": this.apiKey },
          }
          let response = await API_Util.URLS.axios
            .post(API_Util.URLS.updatedetails, data,
                headers
            )
            .then((response) => response)
            .catch((err) => console.log(err));
          if(item.index_type == "new"){
            if (response.data.status == "Success"){
              data ={"id":item.id,"microservice_code":item.microservice_code, teamspace_code: item.teamspace_code}
              let response = await API_Util.URLS.axios
                .post(API_Util.URLS.microserviceindex, data,
                    headers
                )
                .then((response) => response)
                .catch((err) => console.log(err));
                if (response.data.status == "Success"){
                  this.response_msg = response.data.message;
                  this.indexms = true;
                }else{
                  this.response_msg = response.data.message
                  this.errorms = true;
                }
            }
          }
          setTimeout(() => {
              this.getRegMicroserviceList();
          }, 1000);
      } catch (error) {
          console.error('Error uploading file:', error);
      }
    },
    async getRegMicroserviceList() {
      var data = { owner_id: localStorage.getItem("owner") }
      let response = await API_Util.URLS.axios
          .post(API_Util.URLS.getregmicrolist, data,
              {
                  headers: { "x-api-key": this.apiKey }
              }
          )
          .then((response) => response.data.results)
          .catch((err) => console.log(err));
          this.regmslisting = response.listdata;
          // setTimeout(() => {
          //     this.getMicrolist();
          // }, 2000);
    },
    // getMicrolist(){
    //   if(this.regmicroalldata.index_status !="Success"){
    //     setTimeout(() => {
    //       this.getRegMicroserviceList();
    //   }, 2000);
    //   }
      
    // },
    async newindex(){
      const headers = {
          headers: { "x-api-key": this.apiKey },
      }
      var self = this;
      this.selectedmicroitem.forEach(async function (item) {
        var data = { id: item.fun_id }
        let response = await API_Util.URLS.axios
            .post(API_Util.URLS.viewdetails, data, headers
            )
            .then((response) => response.data.details)
            .catch((err) => console.log(err));
            self.regmicroalldata = response;
            self.regmicroalldata = Object.fromEntries(
                Object.entries(self.regmicroalldata).map(([key, value]) => [key.toLowerCase(), value])
            );
            self.indexdialog = true;
      });
      this.selectedmicroitem = [];
      
    },
    selected_micro(item){
      this.selectedmicroitem.push(item)
    },
  },
};
</script>
<style>
</style>