<template>
  <div>
    <v-breadcrumbs :items="items" v-if="ownerDrop">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <div class="owner-tlt">
      <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="ownerDrop" />
    </div>
    <v-content class="white maincontent">
      <div class="pb-4">
        <div class="d-flex justify-space-between align-center">
          <!-- <div cols="12 px-0" v-if="ownerDrop">
              <a>
                <v-btn text class="nocap px-0" @click="$router.go(-1)">
                  <span class="grey--text">
                    <v-icon style="margin-left: -10px">mdi-arrow-left</v-icon>
                    {{ labels.BK }}
                  </span>
                </v-btn>
              </a>
            </div>  -->
          <div>
            <h2 class="text-center font-weigt-bold">{{ labels.HEADING }}</h2>
          </div>
          <div class="text-right">
            <v-spacer></v-spacer>
          </div>
        </div>
        <div class="
            d-flex
            flex-md-row flex-sm-column
            justify-start
            align-md-end
            justify-md-space-between
          ">
          <div>
            <v-chip outlined @click="makeSelectedEmpty" class="ma-2 white" v-if="selectedMicro.length">
              <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span> {{ selectedMicro.length }} selected
            </v-chip>
          </div>
          <div>
            <v-btn text class="tertiary2--text nocap py-5" @click.stop="addNewMs">
              <!-- <v-icon class="pr-1 add" style="font-size:26px">mdi-plus-circle-multiple-outline </v-icon> -->
              <v-icon class="pr-1 add" style="font-size: 26px">mdi-plus-network-outline
              </v-icon>
              {{ labels.ADDNEWMIC }}
            </v-btn>
            <v-btn v-if="ownerDrop && ownerType == 'TS'" text class="tertiary2--text nocap py-5"
              @click="openAssignFuncDialog">
              <!-- <v-icon class="pr-1 add" style="font-size:26px">mdi-plus-circle-multiple-outline </v-icon> -->
              <v-icon class="pr-1 add" style="font-size: 26px">mdi-check-decagram-outline</v-icon>
              Assign Microservice
            </v-btn>

            <v-btn v-if="ownerDrop" text class="tertiary2--text nocap py-5" @click="openDuplicate">
              <!-- <v-icon class="pr-1 add" style="font-size:26px">mdi-plus-circle-multiple-outline </v-icon> -->
              <v-icon class="pr-1 add" style="font-size: 26px">mdi-content-copy </v-icon>
              Duplicate
            </v-btn>

            <v-btn text @click.stop="deleteMs = true" :disabled="!selectedMicro.length" class="error--text nocap py-5">
              <v-icon class="pr-1 add" style="font-size: 26px">mdi-trash-can
              </v-icon>
              {{ labels.DEL }}
            </v-btn>
          </div>
        </div>
        <div>
          <v-data-table :mobile-breakpoint="0" v-model="selectedMicro" :headers="header" :items="msListing"
            item-key="function_id" item-value="function_id" show-select @click:row="openMsDetailPage"
            class="elevation-1 listingTable">
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-checkbox v-model="selectedMicro" :value="item" style="margin: 0px; padding: 0px" hide-details
                    multiple color="dark-grey" />
                </td>
                <td @click="openMsDetailPage(item)">{{ item.function_name }}</td>
                <td @click="openMsDetailPage(item)">{{ item.code }}</td>
                <td @click="openMsDetailPage(item)" v-if="item.micro_type === 'ASSIGN'">Assigned</td>
                <td @click="openMsDetailPage(item)" v-else-if="item.micro_type === 'DUP'">Duplicated</td>
                <td @click="openMsDetailPage(item)" v-else>Owned</td>
                <td @click="openMsDetailPage(item)">{{ item.access }}</td>
                <td @click="openMsDetailPage(item)">{{ item.function_description }}</td>
                <td @click="openMsDetailPage(item)"><span v-if="item.pod_status == 0">Stopped</span><span
                    v-else>Running</span></td>
                <td @click="openMsDetailPage(item)">{{ item.date_updated }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>

        <!-- ----------------Assign MS--------------------------------- -->
        <v-row justify="center" class="px-8">
          <v-form>
            <v-dialog class="stepBystep" scrollable v-model="assignMS" persistent max-width="700px">
              <v-card class="ms-title">
                <v-card-title>
                  <div class="py-2">
                    <h3 class="text-center">Assign Microservice</h3>
                    <div class="closeicon-right">
                      <v-icon @click="assignMS = false" class="text-right">mdi-close-circle-outline
                      </v-icon>
                    </div>
                  </div>
                </v-card-title>
                <v-divider class="my-4"></v-divider>
                <v-card-text style="height: 300px">
                  <div class="selecColor px-8">
                    <div>
                      <p>
                        Assign microservice will allow you to share the same
                        microservice and stay up to date as Sentient.io public
                        cloud platform. {{ tempSelected }}
                      </p>
                    </div>
                    <v-select solo v-model="selectedItems" append-icon="mdi-menu-down" :items="sortedMsListingTemp"
                      item-text="function_name" item-value="function_id"
                      :placeholder="selectedItems.length < 1 ? 'Select microservices' : selectedItems.length + ' microservice selected'"
                      :persistent-placeholder="selectedItems.length > 0 ? true : false"
                      :menu-props="{ bottom: true, offsetY: true }" return-object chips multiple
                      background-color="teritary1">
                      <template v-slot:selection="{ index }">
                        <!-- <v-chip v-if="index === 0">
                          <span>{{ item.function_name }}</span>
                        </v-chip> -->
                        <span class="white--text" v-if="index === 0">{{ selectedItems.length }} microservice
                          selected</span>
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item style="padding: 0px; position: sticky; top: 0; width: 100%; z-index: 1">
                          <v-list-item-content style="padding: 0px;">
                            <v-text-field append-icon="mdi mdi-magnify" clearable @click:clear="clearSearch()"
                              v-model="searchTerm" placeholder="Input Keyword to search" solo
                              @input="searchMicroservices"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }" two-line
                          style="padding-right: 0px !important; padding-left: 0px !important" class="list-size">
                          <v-col cols="12" class="pa-0">
                            <v-row class="pl-4">
                              <v-list-item-action class="check-size">
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content style="width: 500px;">
                                <v-list-item-title color="teritary1">
                                  {{ item.function_name }}</v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ item.function_description }}</v-list-item-subtitle>

                              </v-list-item-content>
                            </v-row>
                            <v-divider></v-divider>
                          </v-col>
                        </v-list-item>
                      </template>
                    </v-select>
                    <div v-if="selectedItems.length">Selected microservices</div>
                    <div class="" v-for="(fun, idx) in selectedItems" :key="fun.function_id" style="padding-bottom:10px;">
                      <v-chip label class="descChip grey lighten-4 py-4 px-4"
                        @click.prevent="selectedItems.splice(idx, 1)">
                        <v-list class="grey lighten-4">
                          <v-list-item two-line style="padding: 0px;">
                            <v-list-item-content style="width: 500px;">
                              <v-list-item-title>
                                {{ fun.function_name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ fun.function_description }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-icon size="20" style="padding-left:35px;">mdi-close</v-icon>
                      </v-chip>
                    </div>
                  </div>
                </v-card-text>
                <v-divider class="mt-4"></v-divider>
                <v-card-actions class="px-10 py-6 text-center">
                  <v-btn class="nocap" large text @click="assignMS = false">
                    Cancel
                  </v-btn>

                  <v-btn class="nocap mx-5 tertiary1" large text @click="cta1()" :disabled="!(selectedItems.length)">
                    <span class="white--text">Continue</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </v-row>
        <!-- Duplicate Ms -->
        <v-row justify="center" class="px-8">
          <v-form>
            <v-dialog class="stepBystep" scrollable v-model="DuplicateMs" persistent max-width="700px">
              <v-card class="ms-title">
                <v-card-title>
                  <div class="py-2">
                    <h3 class="text-center">Duplicate Microservice</h3>
                    <div class="closeicon-right">
                      <v-icon @click="DuplicateMs = false" class="text-right">mdi-close-circle-outline
                      </v-icon>
                    </div>
                  </div>
                </v-card-title>
                <v-divider class="my-4"></v-divider>
                <v-card-text style="height: 300px">
                  <div class="selecColor px-8">
                    <div>
                      <p>
                        Duplicate a microservice from Sentient.io public cloud, and gain your own control over all the
                        details.
                      </p>
                    </div>
                    <v-select autofocus solo clearable v-model="selDupMicro" append-icon="mdi-menu-down"
                      :items="msListingDupTemp" item-key="function_id" item-value="function_id"
                      placeholder="Select a microservice" :menu-props="{ bottom: true, offsetY: true }" chips
                      background-color="teritary1" return-object @change="getMsDetail" @click:clear="resetDetailView()">
                      <template v-slot:selection="{ item }">
                        <v-chip>
                          {{ item.function_name }}
                        </v-chip>
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item style="padding: 0px; position: sticky; top: 0; width: 100%; z-index: 1">
                          <v-list-item-content style="padding: 0px;">
                            <v-text-field append-icon="mdi mdi-magnify " clearable @click:clear="clearSearch()"
                              v-model="searchTermDup" placeholder="Input Keyword to search" solo
                              @input="searchDupMicro"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs" two-line style="width: 550px">
                          <!-- <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action> -->
                          <v-list-item-content>
                            <v-list-item-title color="teritary1">
                              {{ item.function_name }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.function_description
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>

                    <msDuplicateDetail :microAllData="microAllData" :micro_source="micro_source"
                      :micro_limits="micro_limits" :request_type="request_type" :request_version="request_version"
                      :labels="labels" :micro_type="micro_type" @closeMsDetailDialog="closeMsDetailDialog"
                      @closeDetailDialog="closeDialog" v-if="showDupDetailView" />

                  </div>
                </v-card-text>
                <v-divider class="my-4"></v-divider>
                <v-card-actions class="px-10 py-6 text-center">
                  <v-btn class="nocap" large text @click="DuplicateMs = false">
                    Cancel
                  </v-btn>
                  <v-btn class="nocap mx-5 tertiary1 white--text" large text
                    @click="microAllData.micro_type = 'DUP'; save();" :disabled="!selDupMicro">
                    <span class="white--text">Confirm</span>
                  </v-btn>
                  <v-btn class="edtibtn nocap mx-5 tertiary1 white--text" large text
                    @click="msEdit = true; changeStepper(); microAllData.micro_type = 'DUP'; micro_type = 'DUP'; response_message = ''; response_status = '';"
                    :disabled="!selDupMicro">
                    <span class="white--text">Edit and Confirm</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </v-row>

        <!-- duplicate ms End -->
        <!-- Success pop up assign -->
        <v-row justify="center">
          <v-dialog class="msPop" style="box-shadow: none; background-color: transparent" v-model="successMsAsign"
            persistent max-width="388" content-class="vDialog">
            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
              style="background-color: transparent">
              <v-card-title class="text-h5 text-bold d-flex text-center pa-0" style="margin-bottom: -19px">
                <v-img style="z-index: 1" alt="sentient logo" class="d-flex align-center justify-center"
                  src="@/assets/tick-mark1.svg" contain max-width="192"></v-img>
              </v-card-title>
              <v-card-text class="text-center white rounded-lg" style="height: 200px">
                <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                <v-card-title class="text-h5 text-bold d-flex justify-center">
                  <div class="mt-4">Success</div>
                </v-card-title>
                {{ assignedFunCount }} microservice has been <br />
                successfully assigned.
                <v-card-actions>
                  <div class="text-center mt-4">
                    <v-btn class="nocap" color="primary" @click="successPopupClose">Done </v-btn>
                  </div>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
        <!-- End -->

        <!-- Success pop up assign -->
        <v-row justify="center">
          <v-dialog class="msPop" style="box-shadow: none; background-color: transparent" v-model="successMs" persistent
            max-width="388" content-class="vDialog">
            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
              style="background-color: transparent">
              <v-card-title class="text-h5 text-bold d-flex text-center pa-0" style="margin-bottom: -19px">
                <v-img style="z-index: 1" alt="sentient logo" class="d-flex align-center justify-center"
                  src="@/assets/tick-mark1.svg" contain max-width="192"></v-img>
              </v-card-title>
              <v-card-text class="text-center white rounded-lg" style="height: 200px">
                <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                <v-card-title class="text-h5 text-bold d-flex justify-center">
                  <div class="mt-4">Success</div>
                </v-card-title>
                <p v-if="response_msg.length">{{ response_msg }}</p>
                <v-card-actions>
                  <div class="text-center mt-4">
                    <v-btn class="nocap" color="primary" @click="successPopupClose">Done </v-btn>
                  </div>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
        <!-- End -->

        <!-- delete pop up -->
        <v-row justify="center">
          <v-dialog class="msPop" style="box-shadow: none; background-color: transparent" v-model="deleteMs" persistent
            max-width="388" content-class="vDialog">
            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
              style="background-color: transparent">
              <v-card-title class="text-h5 text-bold d-flex text-center pa-0" style="margin-bottom: -19px">
                <v-avatar color="error" size="62">
                  <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                </v-avatar>
              </v-card-title>
              <v-card-text class="text-center white rounded-lg" style="height: 200px">
                <v-card-title class="text-h5 text-bold d-flex justify-center">
                  <div class="mt-4">Delete Confirmation</div>
                </v-card-title>
                Are you sure want to delete the selected microservice?
                <v-card-actions>
                  <div class="text-center mt-4">
                    <v-btn class="nocap mx-5" text @click="deleteMs = false">Cancel </v-btn>
                    <v-btn class="nocap mx-5" color="error" @click="deleteMicroservices">Delete </v-btn>
                  </div>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
        <!-- End -->

        <!-- Already exitsts pop up -->
        <v-row justify="center">
          <v-dialog class="msPop" style="box-shadow: none; background-color: transparent" v-model="dupMsAssigned"
            persistent max-width="388" content-class="vDialog">
            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
              style="background-color: transparent">
              <v-card-title class="text-h5 text-bold d-flex text-center pa-0" style="margin-bottom: -19px">
                <v-avatar color="error" size="62">
                  <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                </v-avatar>
              </v-card-title>
              <v-card-text class="text-center white rounded-lg">
                <v-card-title class="text-h5 text-bold d-flex justify-center">
                  <div class="mt-4">Alert Message</div>
                </v-card-title>
                This Microservice Title or Microservice Code or Urlcode already exists. Kindly edit the details of this
                microservice and save
                <v-card-actions>
                  <div class="text-center mt-4">
                    <v-btn class="nocap mx-5" color="error" @click="dupMsAssigned = false">Ok</v-btn>
                  </div>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
        <!-- End already exists popup -->
        <!-- -----------Add new microservices--------------------- -->
        <v-row justify="center">
          <v-form>
            <v-dialog class="stepBystep" scrollable v-model="addMicroservices" persistent max-width="1000px">
              <v-card class="ms-title">
                <v-card-title>
                  <div class="py-2">
                    <h3 class="text-center">{{ labels.ADDNEWMIC }}</h3>
                    <div class="closeicon-right">
                      <v-icon @click="clearObjectData" class="text-right">mdi-close-circle-outline
                      </v-icon>
                    </div>
                  </div>
                </v-card-title>
                <v-divider class="my-4"></v-divider>
                <v-card-text style="height: 400px" class="basicIfo-sec">
                  <div>
                    <v-stepper alt-labels v-model="currentStep" flat class="stepper sticky">
                      <v-stepper-header flat>
                        <template v-for="(item, index) in stepperData">
                          <v-stepper-step :key="item.id" :complete="currentStep > index + 1" :step="index + 1">
                            <span class="caption"> {{ item.title }}</span>
                            <!-- <span v-hide="microAllData.clas !== 'CLASS_DATA' && item.title!=='Data Schema'" class="caption"> {{ item.title }}</span> -->
                          </v-stepper-step>

                          <v-divider :key="item.id"
                            v-if="(microAllData.clas === '' || microAllData.clas === 'CLASS_DATA') && index < 7"></v-divider>
                          <v-divider :key="item.id"
                            v-else-if="microAllData.clas != 'CLASS_DATA' && index < 6"></v-divider>
                        </template>
                      </v-stepper-header>

                      <v-stepper-items>
                        <v-stepper-content step="1">
                          <v-col cols="12" class="basicInfo-tlt">
                            <h3>Basic info</h3>
                          </v-col>
                          <msbasicinfo :microAllData="microAllData" :validForm="validForm" :labels="labels"
                            @microDataSubmitted="updateMicroAllData" @changeStepper="changeStepper" />
                        </v-stepper-content>

                        <v-stepper-content step="2">
                          <v-col cols="12" class="basicInfo-tlt">
                            <h3>Description</h3>
                          </v-col>
                          <msDescription :microAllData="microAllData" :labels="labels"
                            @microDataSubmitted="updateMicroAllData" />
                        </v-stepper-content>

                        <v-stepper-content step="3">
                          <v-col cols="12" class="px-10">
                            <h3>Microservices Type</h3>
                          </v-col>
                          <mstype :microAllData="microAllData" :request_type="request_type"
                            :request_version="request_version" :labels="labels" :validForm="validForm"
                            @microDataSubmitted="updateMicroAllData" />
                        </v-stepper-content>

                        <v-stepper-content step="4">
                          <v-col cols="12" class="px-10">
                            <h3>Pricing Plan</h3>
                          </v-col>
                          <mspricingPlan :microAllData="microAllData" :labels="labels"
                            @microDataSubmitted="updateMicroAllData" />
                        </v-stepper-content>

                        <v-stepper-content step="5" v-if="microAllData.clas === 'CLASS_DATA'">
                          <v-col cols="12" class="px-10">
                            <h3>Data Schema</h3>
                          </v-col>
                          <dataSchema :microAllData="microAllData" :labels="labels"
                            @microDataSubmitted="updateMicroAllData" />
                        </v-stepper-content>

                        <v-stepper-content :step="microAllData.clas === 'CLASS_DATA' ? 6 : 5">
                          <v-col cols="12" class="px-10">
                            <h3>Additional Info</h3>
                          </v-col>
                          <msAdditionalInfo :microAllData="microAllData" :micro_source="micro_source" :labels="labels"
                            @microDataSubmitted="updateMicroAllData" />
                        </v-stepper-content>

                        <v-stepper-content :step="microAllData.clas === 'CLASS_DATA' ? 7 : 6">
                          <v-col cols="12" class="px-10">
                            <h3>Access Details</h3>
                          </v-col>
                          <msAccessDetail :microAllData="microAllData" :validForm="validForm" :labels="labels"
                            :micro_type="micro_type" @microDataSubmitted="updateMicroAllData" />
                        </v-stepper-content>
                        <v-stepper-content :step="microAllData.clas === 'CLASS_DATA' ? 8 : 7">
                          <v-col cols="12" class="px-10">
                            <h3>Validation Settings</h3>
                          </v-col>
                          <msValidationSettings :microAllData="microAllData" :micro_limits="micro_limits" :labels="labels"
                            @microDataSubmitted="updateMicroAllData" />
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                  </div>
                  <div class="px-md-16">
                    <v-alert text :type="response_status" v-if="response_message.length">
                      {{ response_message }}
                    </v-alert>
                  </div>
                </v-card-text>
                <v-card-actions class="px-10 py-6 text-center">
                  <v-btn class="nocap" large text v-if="currentStep == 1" @click="clearObjectData">
                    {{ labels.CAN }}
                  </v-btn>

                  <v-btn class="nocap mx-5" large text @click="backStep()" v-if="currentStep > 1">
                    {{ labels.BK }}
                  </v-btn>

                  <v-btn class="nocap mx-5" large v-if="microAllData.clas === 'CLASS_DATA' && currentStep < 8"
                    color="primary" @click="nextStep()">
                    {{ labels.NXT }}
                  </v-btn>
                  <v-btn class="nocap mx-5" large v-if="microAllData.clas != 'CLASS_DATA' && currentStep < 7"
                    color="primary" @click="nextStep()">
                    {{ labels.NXT }}
                  </v-btn>
                  <v-btn class="nocap mx-5" large v-if="microAllData.clas === 'CLASS_DATA' && currentStep == 8"
                    color="primary" @click="save()">
                    {{ labels.MIC_SAVE }}
                  </v-btn>
                  <v-btn class="nocap mx-5" large v-if="microAllData.clas != 'CLASS_DATA' && currentStep == 7"
                    color="primary" @click="save()">
                    {{ labels.MIC_SAVE }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </v-row>
        <!-- ------------+++ Delete microservices +++------------------ -->
        <v-row justify="space-around">
          <v-col cols="auto">
            <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="deletMicroservices">
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-title class="py-6">
                    <v-icon large color="error" class="pr-3">mdi-alert-circle
                    </v-icon>
                    <h3>{{ labels.DEL_MSG }}</h3>
                    <v-spacer></v-spacer>
                    <v-icon @click="dialog.value = false">mdi-close-circle-outline
                    </v-icon>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <div class="mt-4">
                      {{ labels.DEL_CONF_SM }}
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="text-right py-6">
                    <v-btn class="nocap" large text @click="dialog.value = false">{{ labels.CLS }}</v-btn>
                    <v-btn class="nocap" color="error" @click="deleteMicroservices(dialog)">{{ labels.S }}</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>

        <!-- -------++++ Microservice Detail Pages +++++--------->
        <v-dialog v-model="msDetailDialog" width="1000" scrollable>
          <msDetailPage :microAllData="microAllData" :micro_source="micro_source" :micro_limits="micro_limits"
            :request_type="request_type" :request_version="request_version" :labels="labels" :micro_type="micro_type"
            @closeMsDetailDialog="closeMsDetailDialog" @closeDetailDialog="closeDialog" />
        </v-dialog>
        <!-- ----------------------------- -->
        <!-- -------++++ Edit Microservice +++++--------->
        <v-dialog v-model="msEdit" width="1000" scrollable class="d-none">
          <msEditPage :microAllData="microAllData" :micro_source="micro_source" :micro_limits="micro_limits"
            :request_type="request_type" :request_version="request_version" :response_message="response_message"
            :response_status="response_status" :validForm="validForm" :labels="labels" :micro_type="micro_type"
            @closeMsEdit="closeEdit" @msEditSave="save" />
        </v-dialog>
        <!-- ----------------------------- -->
      </div>
    </v-content>
    <loaderimg v-if="loader" />
  </div>
</template> 
<script>
import loaderimg from "./loaderimg.vue";
import API_Util from "../services/util/API_Util.js";
import msDetailPage from "./msDetailPage.vue";
import msDuplicateDetail from "./msDuplicateDetail.vue";
import msEditPage from "./msEditPage.vue";
import msbasicinfo from "./msPopUp/msbasicinfo.vue";
import msDescription from "./msPopUp/msDescription.vue";
import msAdditionalInfo from "./msPopUp/msAdditionalInfo.vue";
import msAccessDetail from "./msPopUp/msAccessDetail.vue";
import msValidationSettings from "./msPopUp/msValidationSettings.vue";
import dataSchema from "./msPopUp/dataSchema.vue";
import mspricingPlan from "./msPopUp/mspricingPlan.vue";
import mstype from "./msPopUp/mstype.vue";
import headline from "./headline.vue";

export default {
  components: {
    msbasicinfo,
    msDescription,
    msAdditionalInfo,
    msAccessDetail,
    msValidationSettings,
    dataSchema,
    mspricingPlan,
    msDetailPage,
    msDuplicateDetail,
    msEditPage,
    mstype,
    loaderimg,
    headline,
  },
  name: "microservice",
  data: () => ({
    searchTerm: "",
    response_msg: "",
    micro_type: "OWN",
    msPop: false,
    ownerDrop: "",
    selectedItems: [],
    assignedFunctions: [],
    assignMS: false,
    DuplicateMs: false,
    language: "EN",
    ownerName: "",
    apiKey: "",
    msDetailDialog: false,
    msEdit: false,
    enableDelete: false,
    currentStep: 1,
    deletMicroservices: false,
    stepperData: [
      {
        title: "Basic Info",
        id: 0
      },
      {
        title: "Description",
        id: 1
      },
      {
        title: "Microservice Type",
        id: 2
      },
      {
        title: "Princing Plan",
        id: 3
      },
      {
        title: "Data Schema",
        id: 4
      },
      {
        title: "Additional Info",
        id: 5
      },
      {
        title: "Access Details",
        id: 6
      },
      {
        title: "Validation Settings",
        id: 7
      },
    ],
    show1: false,
    password: "Password",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
    addMicroservices: false,
    singleSelect: false,
    successMsAsign: false,
    successMs: false,
    deleteMs: false,
    dupMsAssigned: false,
    msListing: [],
    msListingDup: [],
    loading: false,
    search: "",
    selectedInt: [],
    selectedMs: [],
    function_id: 0,
    micro_source: [],
    redisCloud: [],
    microAllData: {
      id: 0,
      type: 102,
      deployType: "",
      apiResid: "",
      connType: "",
      iconUrl: "",
      iconFile: "",
      logoUrl: "",
      termUrl: "",
      updatedBy: 2,
      deltConsistsIds: [],
      ownerId: 1,
      images: [],
      name: "",
      code: "",
      access: "",
      apiendpoint: "",
      clas: "",
      consistsfunctionIds: [],
      dataCollectMethod: "",
      dataSchema: [],
      dateUpdated: "",
      deleteLimitationIds: [],
      deltTagIds: [],
      description1: "",
      description2: "",
      global_desc: "",
      docUrl: "",
      feature: [],
      fnType: "",
      keywords: "",
      limitFields: [],
      mandatoryFields: [],
      microserviceProducer: "",
      modelExport: "",
      pricing: [],
      source: "",
      tags: [],
      urlCode: "",
      useCase: "",
      webLink: "",
      requestTypes: [],
      deleteRequestTypes: [],
    },
    selectedMicro: [],
    owner: "",
    response_message: "",
    response_status: "",
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Content - Microservices",
        disabled: true,
        href: "",
      },
    ],
    ownerList: [],
    user: "",
    validForm: true,
    ownerType: "",
    micro_limits: [],
    request_type: [],
    request_version: [],
    labels: {},
    loader: true,
    sortedMsListing: [],
    sortedMsListingTemp: [],
    msListingDupTemp: [],
    addAssignFuns: {},
    assignedFunCount: 0,
    ownerDesc: "",
    selDupMicro: "",
    searchTermDup: "",
    showDupDetailView: false,
    tempSelected: "",

  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerName = sessionObj.session.ownerName;
    this.user = sessionObj.session.id;
    this.ownerType = localStorage.getItem("ownerType");
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.owner = localStorage.getItem("owner");
    this.ownerDrop = JSON.parse(localStorage.getItem("ownerDrop"));
    this.items[1].text =
      "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getFlexi();
    this.getMicroserviceList();
    this.getMicroserviceListSentient();
    this.getOrgAssignedFunctions();
    this.getFunctionSource("MICROSERVICE_SOURCE");
    this.getFunctionSource("CLOUD_PROVIDER");
    this.getFunctionLimits();
    this.getRequestType();
    this.getRequestVersion();
    this.getOrgList();
  },
  computed: {
    allSelectedInt() {
      return this.selectedInt.length === this.items.length;
    },
    allSelectedMs() {
      return this.selectedMs.length === this.msSelect.length;
    },
    categoriesInternal() {
      const search = this.search.toLowerCase();
      if (!search) return this.items;
      return this.items.filter((item) => {
        const text = item.text.toLowerCase();
        return text.indexOf(search) > -1;
      });
    },
    categoriesMS() {
      const search = this.search.toLowerCase();
      if (!search) return this.msSelect;
      return this.msSelect.filter((item) => {
        const text = item.text.toLowerCase();
        return text.indexOf(search) > -1;
      });
    },
    selectionsInt() {
      const selections = [];
      for (const selection of this.selectedInt) {
        selections.push(selection);
      }
      return selections;
    },
    selectionsMs() {
      const selections = [];
      for (const selection of this.selectedMs) {
        selections.push(selection);
      }
      return selections;
    },
  },
  watch: {
    selectedInt() {
      this.search = "";
    },
    selectedMs() {
      this.search = "";
    },
    selected(val) {
      this.enableDelete = false;
      if (val.length) {
        this.enableDelete = true;
      }
    },
  },
  methods: {
    selectedList(selectedNum) {
      this.tempSelected = selectedNum;
      return this.tempSelected;
    },
    successPopupClose() {
      this.successMs = false;
      this.successMsAsign = false;
    },
    makeSelectedEmpty() {
      this.selectedMicro = [];
    },
    cta() {
      this.assignMS = false;
      this.successMsAsign = true;
    },
    searchMicroservices() {
      if (this.searchTerm == "") {
        this.sortedMsListingTemp = this.sortedMsListing;
      }
      else {
        this.sortedMsListingTemp = this.sortedMsListing.filter((el) =>
          el.function_name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
    },
    clearSearch() {
      this.sortedMsListingTemp = this.sortedMsListing;
      this.msListingDupTemp = this.msListingDup;
    },
    searchDupMicro() {
      if (this.searchTermDup == "") {
        this.msListingDupTemp = this.msListingDup;
      } else {
        this.msListingDupTemp = this.msListingDup.filter((el) => el.function_name.toLowerCase().includes(this.searchTermDup.toLowerCase())
        );
      }
    },
    async cta1() {
      this.addAssignFuns = {};
      this.addAssignFuns.functions = [];
      for (let item in this.selectedItems) {
        this.addAssignFuns.functions.push(this.selectedItems[item].function_id);
      }
      this.addAssignFuns.org_id = localStorage.getItem("owner");
      this.addAssignFuns.user_id = this.user;
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.addOrgAssignedFunctions, this.addAssignFuns, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response)
        .catch((err) => console.log(err));
      if (response.data.status == 'Success') {
        this.assignedFunCount = response.data.insertedFunctions;
        this.getOrgAssignedFunctions();
        this.getMicroserviceList();
        this.assignMS = false;
        this.DuplicateMs = false;
        this.successMsAsign = true;
      }

    },
    changeStepper() {
      if (this.microAllData.clas != 'CLASS_DATA' && this.microAllData.clas != "") {
        this.stepperData = [
          {
            title: "Basic Info",
            id: 0
          },
          {
            title: "Description",
            id: 1
          },
          {
            title: "Microservice Type",
            id: 2
          },
          {
            title: "Princing Plan",
            id: 3
          },
          {
            title: "Additional Info",
            id: 4
          },
          {
            title: "Access Details",
            id: 5
          },
          {
            title: "Validation Settings",
            id: 6
          },
        ];
      } else {
        this.stepperData = [
          {
            title: "Basic Info",
            id: 0
          },
          {
            title: "Description",
            id: 1
          },
          {
            title: "Microservice Type",
            id: 2
          },
          {
            title: "Princing Plan",
            id: 3
          },
          {
            title: "Data Schema",
            id: 4
          },
          {
            title: "Additional Info",
            id: 5
          },
          {
            title: "Access Details",
            id: 6
          },
          {
            title: "Validation Settings",
            id: 7
          },
        ];
      }
    },
    async getRequestType() {
      var data = {
        owner_id: localStorage.getItem("owner"),
        lang: this.language,
        code: "REQUEST_TYPE",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.request_type = response;
    },
    async getRequestVersion() {
      var data = {
        owner_id: localStorage.getItem("owner"),
        lang: this.language,
        code: "REQUEST_VERSION",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.request_version = response;
    },
    async getFunctionLimits() {
      var data = {
        owner_id: localStorage.getItem("owner"),
        lang: this.language,
        code: "LIMIT_DATA_TYPE",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.micro_limits = response;
    },
    getFlexi() {
      API_Util.URLS.axios
        .post(
          API_Util.URLS.getlblbyscr,
          {
            owner_id: localStorage.getItem("owner"),
            scrId: "MIC_SET",
            lang: this.language,
          },
          { headers: { "x-api-key": this.apiKey } }
        )
        .then((response) => {
          this.labels = response.data.labels;
          this.header = [
            {
              text: this.labels.NAME,
              align: "start",
              sortable: true,
              value: "function_name",
            },
            { text: this.labels.COD, value: "code", cellClass: "breakAll" },
            { text: this.labels.TYPE, value: "micro_type" },
            { text: "ACCESS", value: "access" },
            { text: this.labels.DESC, value: "function_description" },
            { text: "POD Status", value: "pod_status" },
            { text: this.labels.CREAT_DAT, value: "date_created" },
          ];
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    allOwners() {
      this.$router.push("/ownerlisting", () => { window.location.reload() });
    },
    changeOwner(obj) {
      this.loader = true;
      location.reload();
      localStorage.setItem("owner", obj.ownerId);
      localStorage.setItem("ownerName", obj.ownerName);
      localStorage.setItem("ownerType", obj.ownerType);
      localStorage.setItem("redisCloud", obj.redisCloud);
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit('ownerchange', obj.ownerType);
      this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.getOrgAssignedFunctions();
      this.getMicroserviceList();
      this.getFunctionSource("MICROSERVICE_SOURCE");
      this.getFunctionSource("CLOUD_PROVIDER");
    },
    async getOrgList() {
      var empt = {}
      await API_Util.URLS.axios
        .post(API_Util.URLS.listOrg, empt, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.ownerList = response.data.results;
        });
    },
    clearObjectData() {
      this.addMicroservices = false;
      this.response_message = "";
      this.clearMicroAllData();
      this.changeStepper();
    },
    clearMicroAllData() {
      this.microAllData = {
        id: 0,
        type: 102,
        deployType: "",
        apiResid: "",
        connType: "",
        iconUrl: "",
        iconFile: "",
        logoUrl: "",
        termUrl: "",
        updatedBy: 2,
        deltConsistsIds: [],
        ownerId: 1,
        images: [],
        name: "",
        code: "",
        access: "",
        apiendpoint: "",
        clas: "",
        consistsfunctionIds: [],
        dataCollectMethod: "",
        dataSchema: [],
        dateUpdated: "",
        deleteLimitationIds: [],
        deltTagIds: [],
        description1: "",
        description2: "",
        global_desc: "",
        docUrl: "",
        feature: [],
        fnType: "",
        keywords: "",
        limitFields: [],
        mandatoryFields: [],
        microserviceProducer: "",
        modelExport: "",
        pricing: [],
        source: "",
        tags: [],
        urlCode: "",
        useCase: "",
        webLink: "",
        requestTypes: [],
        deleteRequestTypes: [],
      };
      this.addAssignFuns = {},
        this.assignedFunCount = 0;
      this.response_message = "";
      this.response_status = "";
      this.currentStep = 1;
      this.validForm = true;
    },
    addNewMs() {
      this.addMicroservices = true;
      this.micro_type = "OWN";
      this.clearMicroAllData();
    },
    closeEdit(status) {
      this.msEdit = status;
    },
    async getFunctionSource(code) {
      var data = {
        owner_id: localStorage.getItem('owner'),
        lang: this.language,
        code: code,
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      if (code == 'MICROSERVICE_SOURCE') {
        this.micro_source = response;
      } else if (code == 'CLOUD_PROVIDER') {
        this.redisCloud = response;
      }

    },
    closeMsDetailDialog(value) {
      this.msDetailDialog = value;
    },
    closeDialog(value) {
      this.msDetailDialog = value;
      this.msEdit = true;
    },
    openFun(type) {
      this.$router.push({ name: "popup", params: { type: type } });
    },
    nextStep() {
      if (this.currentStep == 1) {
        if (this.microAllData.name == "") {
          this.validForm = false;
        } else if (this.microAllData.code == "") {
          this.validForm = false;
        } else if (this.microAllData.clas == "") {
          this.validForm = false;
        } else if (this.microAllData.fnType == "") {
          this.validForm = false;
        } else if (this.microAllData.urlCode == "") {
          this.validForm = false;
        } else {
          this.validForm = true;
        }
      }
      if (this.currentStep == 3) {
        this.validForm = false;
        if (this.microAllData.requestTypes.length) {
          this.validForm = true;
        } else {
          this.validForm = false;
        }
      }
      if (this.currentStep < 8 && this.validForm) {
        this.currentStep = this.currentStep + 1;
      }
    },
    backStep() {
      if (this.currentStep > 1) {
        this.currentStep = this.currentStep - 1;
        this.validForm = true;
      }
    },
    next() {
      this.loading = true;
      setTimeout(() => {
        this.search = "";
        this.selectedInt = [];
        this.selectedMs = [];
        this.loading = false;
      }, 2000);
    },
    async openMsDetailPage(item) {
      this.micro_type = item.micro_type;
      var details = { "fId": item.function_id };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.infoMicroservice, details, {
          headers: { "x-api-key": this.apiKey },
          params: {},
        })
        .then((response) => response.data)
        .catch((err) => console.log(err));
      this.microAllData = response;

      if (this.micro_type === 'ASSIGN') {
        var det = { "function_id": item.function_id, "owner_id": localStorage.getItem('owner') }
        let accessResponse = await API_Util.URLS.axios
          .post(API_Util.URLS.getAssignedOrgFunctionAccess, det, {
            headers: { "x-api-key": this.apiKey },
            params: {},
          })
          .then((response) => response.data)
          .catch((err) => console.log(err));
        this.microAllData.accessText = accessResponse.data.assigned_access;
        this.microAllData.access = accessResponse.data.assigned_access;
      }
      this.msDetailDialog = true;
    },
    updateMicroAllData(value) {
      this.microAllData = value;
    },
    async save() {
      if (this.micro_type == 'ASSIGN') {
        var accessData = { "accessType": this.microAllData.access, "owner_id": localStorage.getItem('owner'), "functionId": this.microAllData.id, "updatedBy": this.user }
        let response = await API_Util.URLS.axios
          .post(API_Util.URLS.assignFunctionUpdateAccess, accessData, {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "x-api-key": this.apiKey,
            },
          })
          .then((response) => response)
          .catch((err) => console.log(err));
        if (response.data.status == "Success") {
          this.response_message = "";
          this.response_status = "";
          this.addMicroservices = false;
          this.msEdit = false;
          if (this.microAllData.id > 0) {
            this.response_msg = "Successfully Updated";
            this.successMs = true;
          }
          else {
            this.response_msg = "Successfully Created";
            this.successMs = true;
          }
          setTimeout(() => {
            this.getMicroserviceList();
            this.clearMicroAllData();
            this.changeStepper();
          }, 2000);
        }
      } else {
        if (!this.microAllData.requestTypes || this.microAllData.requestTypes.length == 0) {
          this.validForm = false;
        } else {
          this.validForm = true;
          this.microAllData.type = 102;
          if (!this.microAllData.deltTagIds) {
            this.microAllData.deltTagIds = [];
          }
          if (!this.microAllData.deleteLimitationIds) {
            this.microAllData.deleteLimitationIds = [];
          }
          if (!this.microAllData.deltConsistsIds) {
            this.microAllData.deltConsistsIds = [];
          }
          if (this.microAllData.id > 0) {
            this.microAllData.images = [];
          }
          this.microAllData.updatedBy = this.user;
          this.microAllData.owner_id = localStorage.getItem('owner');
          if (!this.microAllData.micro_type) {
            this.microAllData.micro_type = 'OWN';
          }
          this.microAllData.redisCloud = localStorage.getItem('redisCloud');
          let response = await API_Util.URLS.axios
            .post(API_Util.URLS.addMicroservice, this.microAllData, {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "x-api-key": this.apiKey,
              },
            })
            .then((response) => response)
            .catch((err) => console.log(err));
          if (response.data.message === "NAME_EXIST") {
            if (this.microAllData.micro_type == "DUP") {
              this.dupMsAssigned = true;
            }

            this.response_message = "Title or Microservice Code or Urlcode Already Exists";
            this.response_status = "error";
          } else {
            this.DuplicateMs = false;
            this.msEdit = false;
            if (this.microAllData.id > 0) {
              this.response_msg = "Successfully Updated";
              this.successMs = true;
            }
            else {
              this.response_msg = "Successfully Created";
              this.successMs = true;
            }
            setTimeout(() => {
              this.getMicroserviceList();
              this.addMicroservices = false;
              if (this.microAllData.id > 0) {
                this.msEdit = false;
              }
              this.clearMicroAllData();
              this.changeStepper();
            }, 2000);
          }
        }
      }
    },
    async getMicroserviceList() {
      var data = { owner_id: localStorage.getItem("owner"), type: 'apis', user_id: this.user }
      let response = await API_Util.URLS.axios
        .post(
          API_Util.URLS.listMicroservice, data,
          {
            headers: { "x-api-key": this.apiKey }
          }
        )
        .then((response) => response.data.results)
        .catch((err) => console.log(err));
      this.msListing = response;
    },
    async getMicroserviceListSentient() {
      var data = { owner_id: localStorage.getItem("owner"), type: 'apis', user_id: this.user }
      let response = await API_Util.URLS.axios
        .post(
          API_Util.URLS.listMicroservice, data,
          {
            headers: { "x-api-key": this.apiKey }
          }
        )
        .then((response) => response.data.results)
        .catch((err) => console.log(err));
      this.msListingDup = response;
      this.msListingDupTemp = response.sort((a, b) => a.function_name > b.function_name ? 1 : -1);
    },
    async getOrgAssignedFunctions() {
      this.loader = true;
      var data = { owner_id: localStorage.getItem("owner"), type: 'org_assign', user_id: this.user }
      let response = await API_Util.URLS.axios
        .post(
          API_Util.URLS.listMicroservice, data,
          {
            headers: { "x-api-key": this.apiKey }
          }
        )
        .then((response) => response.data.results)
        .catch((err) => console.log(err));
      this.sortedMsListing = response.sort((a, b) => a.function_name > b.function_name ? 1 : -1);
      this.sortedMsListingTemp = response.sort((a, b) => a.function_name > b.function_name ? 1 : -1);
      this.loader = false;
    },
    deleteMicroservices() {
      const headers = {
        headers: { "x-api-key": this.apiKey },
        params: {},
      }
      this.selectedMicro.forEach(function (arrayItem) {
        var fid = { "fId": arrayItem.function_id, "owner_id": localStorage.getItem("owner") }
        API_Util.URLS.axios
          .post(
            API_Util.URLS.deleteMicroservice, fid,
            headers
          )
          .then()
          .catch((err) => console.log(err));
        fid = {}
      });
      this.selectedMicro = [];
      setTimeout(() => {
        this.getOrgAssignedFunctions();
        this.getMicroserviceList();
        this.deleteMs = false;
      }, 500);
    },
    openAssignFuncDialog() {
      this.assignMS = true;
      this.addAssignFuns = {};
      this.assignedFunCount = 0;
      this.selectedItems = [];
    },
    openDuplicate() {
      this.DuplicateMs = true;
      this.selDupMicro = "";
      this.showDupDetailView = false;
    },
    async getMsDetail(obj) {
      this.loader = true;
      var det1 = { "fId": obj.function_id }
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.infoMicroservice, det1, {
          headers: { "x-api-key": this.apiKey },
          params: {},
        })
        .then((response) => response.data)
        .catch((err) => console.log(err));
      this.microAllData = response;
      this.microAllData.id = 0;
      this.microAllData.pricing.forEach(obj => obj.id = 0);
      this.microAllData.feature.forEach(obj => obj.id = 0);
      this.microAllData.tags.forEach(obj => obj.id = 0);
      this.microAllData.requestTypes.forEach(obj => obj.id = 0);
      this.microAllData.limitFields.forEach(obj => obj.id = 0);
      this.showDupDetailView = true;
      this.loader = false;
    },
    resetDetailView() {
      this.showDupDetailView = false;
    }
  },

};
</script>
<style lang="scss" scoped>
.v-input__append-inner {
  margin-top: 11px !important;
}

.v-text-field--filled>.v-input__control>.v-input__slot,
.v-text-field--full-width>.v-input__control>.v-input__slot,
.v-text-field--outlined>.v-input__control>.v-input__slot {
  min-height: 46px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}

.container {
  max-width: 1224px;
}

.v-menu__content {
  background-color: white;
}

.v-chip .mdi-pencil-box-multiple {
  font-size: 21px !important;
}

.v-btn::before {
  background-color: transparent !important;
}

.v-stepper__step__step {
  height: 12px !important;
  min-width: 12px !important;
  width: 12px !important;
}

.v-stepper {

  // .v-stepper__step__step.primary {
  //     background-color: red !important;
  //     border-color: red !important;
  // }
  .v-divider {
    border-width: thin 0px 3px 0 !important;
  }

  .v-stepper__step {
    padding: 30px 0px 0px;

    hr {
      .v-stepper--alt-labels.v-stepper__header.v-divider {
        margin: 35px -80px 0 !important;
      }
    }
  }
}

//  .v-stepper__step--complete + .v-divider{
//      background-color: red !important;
//   }
.v-stepper__step__step {
  color: transparent !important;

  .v-icon {
    display: none !important;
  }
}

.v-stepper__header {
  box-shadow: none !important;
}

.ms-title {
  .v-card__title {
    text-align: center !important;
    display: block !important;
  }
}

.v-card__actions {
  text-align: center !important;
  display: block !important;
}

.vDialog {
  box-shadow: none !important;
}

.dialog-size {
  width: 500px;
}

.list-size {
  .v-list {
    width: 10px;
  }
}

.check-size {
  margin-right: 10px !important;
  margin-left: 0px;
}

.list-size {
  .v-list {
    width: 10px;
  }
}

.basicInfo-tlt {
  padding: 12px 40px;
}

/* ---------------------------------- mobile view ---------------------------------- */
@media only screen and (min-width: 350px) and (max-width: 767px) {
  .basicInfo-tlt {
    padding: 12px 0px;
  }

  .basicIfo-sec {
    padding: 0 !important;
  }

  .edtibtn {
    margin-top: 15px;
  }

  ::v-deep(.v-menu__content--fixed) {
    max-width: 95%;
    left: 9px !important;
    top: 362px !important;
  }

  .ms-title h3 {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 700px) {
  .dialog-size {
    width: 300px;
  }
}

.v-text-field__slot {
  padding-left: 5px;
}
</style>