<template>
  <div style="width:100%">
    <!-- Name -->
    <v-row>
      <v-col class="col-12 col-md-4">
        <p class="s-input-label text-space">{{labels.GRP_NAME}}</p>
      </v-col>
      <v-col class="col-12 col-md-8">
        <v-text-field
          single-line
          :rules="[rules.required]"
          outlined
          dense
          v-model="newGroup.name"
          :placeholder="labels.GRP_NAME_UNIQ"
        />
      </v-col>
    </v-row>

    <!-- Descriptipn -->
    <v-row>
      <v-col class="col-12 col-md-4">
        <p class="s-input-label text-space">{{labels.GRP_DESC}}</p>
      </v-col>
      <v-col class="col-12 col-md-8">
        <v-textarea
          rows="4"
          no-resize
          single-line
          outlined
          dense
          :placeholder="labels.GRP_DESC_PH"
          v-model="newGroup.description"
        />
      </v-col>
    </v-row>

    <!-- Select Functions -->
    <v-row>
      <v-col class="col-12 col-md-4 text-space">
        <p class="s-input-label">{{labels.GRP_FUN}}</p>
      </v-col>
      <v-col class="col-8 col-md-8 menu btn-width">
        <!-- Display selected functions in tag style -->
        <div class="mb-4" v-show="newGroup.functions[0]">
          <v-chip
            class="ma-1"
            color="tertiary1"
            dark
            small
            close
            
            v-for="fun in newGroup.functions"
            :key="fun.name"
            @click:close="
                removeArrayItem(newGroup.functions, fun)
              "
          >
            {{ fun.name }},,,,
          </v-chip>
        </div>
        <!-- Dropdown selection of functions -->
        <v-menu  class="fun-dd-menu" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block outlined v-bind="attrs" style="width: 250px;" v-on="on" class="nocap justify-space-between">
              <span class="label-size">{{labels.GRP_ADDFUN}}</span>
              <v-icon style="margin:0" class="icon-width">
                mdi-menu-right
              </v-icon>
            </v-btn>
          </template>

          <!-- Group selection items -->
          <div class="row fun-dd-row">
            <div class="col-6 col-md-6 left-padding fun-dd-lefts">
              <v-list style="min-height:60vh">
                <h4 class="addGrptlt">Categories</h4>
                <v-divider></v-divider>
                  <div  style="cursor:pointer">
                    <v-list-item-group
                      v-model="type_filter"
                      active-class="bg-active"
                    >
                    <v-list-item @click="typefilters('')">All</v-list-item> 
                    <v-list-item @click="typefilters('102')">Microservices</v-list-item>              
                    <v-list-item @click="typefilters('140')">Platform Admin Functions</v-list-item>
                    </v-list-item-group>
                  </div>
              </v-list>
            </div>
          
            <div class="col-6 col-md-6 right-padding fun-dd-right">
              <v-list>
                <div class="src-field">
                <input type="search" 
                  v-model="searchQuery"
                  append-icon="mdi-magnify"
                  placeholder="search function"
                  outlined
                  @keyup="filteredList()"
                  @click:clear="resetSearch(true)"
                  class="src-input"/>
                <span type="submit">
                  <i class="fa fa-search"></i>
                </span>
                </div>
                <v-list-item v-for="func in funList" :key="func.function_id" >
                  <v-checkbox
                    v-model="newGroup.functions"
                    color="tertiary1"
                    :value="func"
                  >
                    <template v-slot:label>
                      <span class="black--text">
                        {{ func.name }}
                      </span>
                    </template>
                  </v-checkbox>
                </v-list-item>
              </v-list>
            </div>
          </div>
        </v-menu>
      </v-col>
    </v-row>
    <!-- -*ABOVE*- Select Functions -->
    <!-- ========================== -->

    <!-- Success notification -->
    <v-alert type="success" class="mt-4" dense v-show="success">
      {{labels.OD_SUC_VA_GRP}}
    </v-alert>
    <v-alert
        dense
        outlined
        type="error"
        transition="fade-transition"
        v-if="groupExist"
      >
        Group name exist!
      </v-alert>
 
    <!-- Control buttons -->
    <v-row class="mt-8 d-flex justify-center">
      <v-btn
        text
        color="primarytextcolor darken-1"
        class="mx-8 nocap"
        @click="toggleDialog('manageGroups_createGroup')"
      >
        {{labels.ADD_CANCEL}}
      </v-btn>
      <v-btn
        :disabled="newGroup.name === ''"
        :dark="newGroup.name !== ''"
        class="mx-8 nocap"
        @click="useCreateGroup()"
        color="tertiary2"
      >
        <v-icon>mdi-account-plus-outline</v-icon>
        <span>{{labels.ADD_CREATE}}</span>
        <v-progress-circular
          :width="3"
          :size="15"
          color="white"
          indeterminate
          class="ml-1"
          v-if="loader"
        ></v-progress-circular>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import {
  organisationService,
  manageGroupsService
} from '../../services/organisationService.js'

export default defineComponent({
  props: {},  
  setup(_, ctx) {
    //SESSION OBJECTS
    const session = ctx.root.$session,
    u_session=session.get("user_session").session;

    const { functionsList, toggleDialog, getfunctionList,organisation } = organisationService()
    const { createGroup,getGroupList } = manageGroupsService()

    // Validation rules for input fields
    const rules = {
      required: (value) => !!value || 'Required.',
      counter: (value) => value.length <= 20 || 'Max 20 characters'
    }

    // Display success notification on pop up window
    const success = ref(false);
    const labels=ref({});
    const type_filter=ref();
    const category=ref('');
    const searchQuery =ref('');
    //**GET LABELS */
    setTimeout(() => {
      labels.value = organisation.labels;
    }, 200);
    const newGroup = ref();
    const initNewGroup = () => {
      // Reset success state
      success.value = false

      // Reset newGroup value
      newGroup.value = {
        name: '',
        description: '',
        functions: []
      }
    }

    initNewGroup()
      const funList = ref();
      const callfunctionList = async() => {
          await getfunctionList(u_session.owner);
          funList.value = functionsList.assignOrgFunctions;
      }
    callfunctionList();

    const typefilters = function(category){
        funList.value = functionsList.assignOrgFunctions;
        if (category =="102"){
          funList.value = funList.value.filter(item=>item.type == category);
        }else if(category == ""){
          funList.value = functionsList.assignOrgFunctions;
        }else{
          funList.value = funList.value.filter(item=>item.type != 102);
        }
    };
    const filteredList = () =>{   
      funList.value=functionsList.assignOrgFunctions; 
      if (searchQuery !=""){
        funList.value= funList.value.filter(list => list.name.toUpperCase().indexOf(searchQuery.value.toUpperCase()) !=-1)
      }
    };
    const resetSearch = (searchType)=>{
      if(searchType==true){
        funList.value=functionsList.assignOrgFunctions;
        searchQuery.value='';
      }
    };
    const checkExisting = (name,dataarray) => {
      var isExist=false;
      if(dataarray!=undefined){
        dataarray.forEach((fun)=>{
          if(name==fun.name){
            isExist=true;
          }
        })
      }
      return isExist;
    };
  const removeArrayItem = (array, item) => {
      const index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1)
      }
    }
    const groupExist=ref(false);
    const loader=ref(false);
    // Call a function in organisationService.js and make API call to create new group
    const useCreateGroup = async() => {
      loader.value=true;
      // Call function in organisationService.js to make create group api call
      let response=await createGroup(newGroup.value,'ADD')
        .then((response) => response.data)
        .catch((err) => console.log(err));
        loader.value=false;
          if(response.message=='GROUP_EXIST'){
            groupExist.value=true;
          }else{
          success.value = true;
          toggleDialog('manageGroups_createGroup');
          initNewGroup();
          getGroupList();
          }

          setTimeout(function() {
            groupExist.value=false;
            success.value = false;
          },2000)
        

      // Reset newGroup to empty object, so previous edited data won't cached
    }

    return {
      toggleDialog,
      type_filter,
      functionsList,
      rules,
      success,
      newGroup,
      useCreateGroup,
      getfunctionList,
      callfunctionList,
      funList,
      typefilters,
      groupExist,
      loader,
      organisation,
      labels,
      category,
      searchQuery,
      filteredList,
      checkExisting,
      removeArrayItem,
      resetSearch
    }
  }
})
</script>

<style lang="scss" scoped>
.fun-dd-row {
  background: #fff;
  width:596px;
    /* padding: 20px 10px; */
    
}
.addGrptlt {
  text-align: center;
    color: #000000de;
    margin-bottom: 5px;
}
.fun-dd-lefts .v-list {
  position: relative;
  background-color: #efefef;
}
.fun-dd-lefts .v-list-item {
  padding: 0 5px;
  font-size: 15px;
}
// .fun-dd-lefts .v-list-item:last-child {
//   position: absolute;
//   bottom: 5px;
// }
.fun-dd-right {
  padding-top: 0;
  padding-right: 0;
}

.fun-dd-right .v-list {
  padding-top: 0;
  max-height: 60vh;
    overflow-y: scroll;
}
.fun-dd-right .v-text-field {
  width: 92%;
  margin: auto;
  position: sticky;
    z-index: 33;
    top: 0;
    background: #fff;
    margin-right: 12px;
  
}
.text-space{
  padding-left: 20px !important;
}
.src-input:focus-visible {
  outline: none;
}
.src-input {
  width: 97%;
    height: 40px;
    border: 2px solid #c3c3c3;
    padding: 8px;
    margin: 10px 0 20px;
    border-radius: 4px;
    position: sticky;
    top: 0;
    z-index: 100;
    background: #fff;
}
.src-field {
  position: sticky;
    top: 0;
    z-index: 100;
    background: #fff;
}
.src-field span {
  position: absolute;
    top: 19px;
    right: 18px;
    z-index: 1000;
    color: #C3C3C3;
}
@media only screen and (min-width: 596px){
  .text-space{
    padding-left: 0px !important;
  }
}
@media only screen and (max-width: 573px){
  .label-size{
    visibility: hidden;
    position: relative;
  }
  .label-size:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: "Select Function";
    font-size: 13px;
  }
  .icon-width{
    padding-bottom: 36px;
  }
  
}
.left-padding{
background-color: #efefef;
padding: 10px 10px;
}
.right-padding{
  width: 230px;
    padding: 10px 15px 15px 10px;
}

//---------------------------------- mobile view ---------------------------------- 
@media only screen and (min-width: 350px) and (max-width: 767px) {

.fun-dd-right .v-list-item {
  padding-left: 0;
  padding-right: 0;
}
.fun-dd-row {
    width: auto !important; 
  }
::v-deep(.v-menu__content--fixed) {
  max-width: 95%;
  left: 52px !important;
  top: 528px !important;
}
.icon-width {
  margin: 0px;
    position: relative;
    left: -53px;
    top: 18px;
}
}
.error {
  background-color: tomato;
}
.bg-active {
  font-weight: bold;
  color: #000000de !important;
}

</style>
